.simple-collapsable-container
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: column
  width: 100%

  .simple-collapsable-item
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    width: 100%

    &.open
      .simple-collapsable-body
        display: flex

    .simple-collapsable-header
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      height: 4.8rem
      cursor: pointer

      .simple-collapsable-leading
        display: flex
        align-items: center
        justify-content: center

      .simple-collapsable-title
        display: flex
        align-items: center
        justify-content: flex-start
        width: 100%

      .simple-collapsable-trailing
        display: flex
        align-items: center
        justify-content: flex-start

        .simple-collapsable-arrow
          display: flex
          align-items: center
          justify-content: center

    .simple-collapsable-body
      display: none
      align-items: flex-start
      justify-content: flex-start
      flex-direction: column
