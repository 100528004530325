@import 'src/sass/_variables'

.ui-steps
  display: flex
  flex-direction: row
  width: auto
  height: auto
  margin-top: 2rem
  .step
    position: relative
    display: flex
    align-items: center
    justify-content: flex-start
    flex: 1
    height: 5.6rem
    background-color: $white
    padding: 0 2.4rem
    margin-right: 3.6rem
    border-style: solid
    border-color: $sky-dark
    border-width: 0.1rem
    border-radius: 0.4rem
    font-size: 1.4rem
    line-height: 2rem
    color: $black
    > div
      position: relative
      z-index: 2
      display: flex
      align-items: center
      justify-content: flex-start
      width: 100%
      height: 100%
      background-color: $white
      margin-left: 0.8rem
    &:last-child
      margin-right: 0
    &:hover
      cursor: pointer
    &.selected
      background-color: $rombit-blue-100
      border-color: $rombit-blue-800
      color: $rombit-blue-800
      > div
        background-color: $rombit-blue-100
      &.left,
      &.center,
      &.right
        .graphs-stepper-right,
        .graphs-stepper-left
          path
            fill: $rombit-blue-100
            stroke: $rombit-blue-800
    &.left
      padding-right: 0
      border-right: none
      border-top-right-radius: 0
      border-bottom-right-radius: 0
    &.center
      padding-right: 0
      padding-left: 0
      border-right: none
      border-left: none
      border-radius: 0
    &.right
      padding-left: 0
      border-left: none
      border-top-left-radius: 0
      border-bottom-left-radius: 0
  .graphs-stepper-right
    position: absolute
    z-index: 1
    right: 0
    margin-right: -1.8rem
  .graphs-stepper-left
    position: absolute
    z-index: 1
    left: 0
    margin-left: -2.2rem
