@import 'src/sass/_variables'

.ui-timer
  position: absolute
  z-index: 2
  left: 50%
  display: flex
  transform: translate(-50%, 0)
  > div:first-child
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    > div
      width: 100%
    // title
    > div:first-child
      background-color: $rombit-blue-900
      padding: 0.2rem 1.6rem
      border-top-left-radius: 0.4rem
      border-top-right-radius: 0.4rem
      font-size: 1.4rem
      font-weight: 700
      text-align: center
      color: $sky-lighter
      user-select: none
    // Icons + numbers
    > div:nth-child(2)
      display: flex
      align-items: center
      justify-content: center
      flex-direction: row
      background-color: $sky-lighter
      border-bottom-left-radius: 0.4rem
      border-bottom-right-radius: 0.4rem
      padding: 0.8rem
      user-select: none
      > span
        font-size: 1.6rem
        font-weight: 700
        color: $rombit-blue-900

      > svg
        width: 2.4rem
        height: 2.4rem
        margin-right: 0.8rem
        path
          fill: $rombit-blue-900
  // Button
  > div:nth-child(2)
    display: flex
    align-items: center
    justify-content: center
    align-self: flex-end
    width: 4rem
    height: 4rem
    background-color: $rombit-blue-900
    cursor: pointer
    margin-left: 0.8rem
    border-radius: 0.4rem
    text-align: center
    color: $sky-lighter
    > svg
      path
        fill: $white

  &.alert
    > div:first-child
      > div:first-child
        background-color: $red
        color: $sky-lighter
      > div:nth-child(2)
        background-color: $sky-lighter
        color: $red
        > div:first-child
          path
            fill: $red
    > div:nth-child(2)
      background-color: $red
      color: $sky-lighter
