@import 'src/sass/_variables'

.blank-state-container
  &.trigger,
  &.actions
    .blank-state
      width: 100%
      height: auto
      margin-top: 3.2rem
      margin-bottom: 0
      border: none
      .icon
        display: flex
        align-items: center
        justify-content: center
        width: 12rem
        height: 12rem
        margin-bottom: 1.6rem
        border-radius: 50%
        svg
          width: 4.8rem
          height: 4.8rem
  &.trigger
    .blank-state
      .icon
        background-color: $sky
        .icons-flash-off
          path
            fill: $ink-lighter
  &.actions
    .blank-state
      .icon
        background-color: $green-lighter
        .icons-flash-off
          path
            fill: $green
