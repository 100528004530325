@import 'src/sass/_variables'

.routes-backoffice-devices
  margin-top: 1rem
  .ui-toast
    z-index: 4

  .buttons
    display: flex
    align-items: center
    justify-content: space-between
    &.flex-end
      justify-content: flex-end
      margin-bottom: 0.4rem
      .ui-button
        margin-left: 1.6rem
      .actions
        align-items: center
        .file
          height: 3.2rem
    // Button in toolbar
    .actions
      display: flex
      .ui-button:nth-child(3)
        > div
          font-size: 1.4rem
          font-weight: $fw-regular
          &:hover
            color: $rombit-blue-600
      .file
        .ui-input.upload
          flex-direction: column
          margin-right: 3rem
          .input-container
            display: flex
            align-items: center
            justify-content: center
            label
              display: flex
              align-items: center
              vertical-align: middle
              transition: all 0.3s
              background: $rombit-blue-200
              cursor: pointer
              border: none
              border-radius: 0.4rem
              outline: none
              font-size: 1.2rem
              font-weight: 700
              color: $rombit-blue-600
              span
                display: flex
                align-items: center
                padding-right: 1.2rem
                white-space: nowrap
                .icons-plus
                  margin-right: 0.8rem
                  path
                    fill: $rombit-blue-600
  .maintenance-modal
    .content
      margin-top: 0
    p
      text-align: left
    .file
      .ui-input.upload
        .input-container
          margin-top: 2.4rem
          label
            padding-right: 0.8rem

  .ui-list
    max-height: calc(100vh - 15rem)
    margin-top: 1rem
    margin-right: -4rem
    .field
      cursor: default
      &.name
        &:hover
          text-decoration: none

  // To gain space in the selection column
  .floated-title
    position: absolute
    left: 4rem

  .device-firmware
    display: flex
    align-items: flex-start
    flex-direction: column
  .device-charge
    position: relative
    svg
      position: absolute
      left: -1.8rem
      path
        fill: $ink-lightest
    .very-low
      color: $red-darker
    .low
      color: $orange-darker
    .mid
      color: $orange
    .high
      color: $green
    .charge-good,
    .complete
      color: $green-darker

  .device-activity
    display: flex
    align-items: center
    flex-flow: row nowrap
    > span
      white-space: nowrap
    .active,
    .inactive,
    .reporting
      flex-shrink: 0
      margin-right: 0.4rem
      line-height: 0
    .active
      .icons-dot
        path
          fill: $green
    .reporting
      .icons-dot
        path
          fill: $rombit-blue-700
    .inactive
      .icons-dot
        path
          fill: $ink-lightest
