@import 'src/sass/_variables'

.tmv2-map-mode
  position: absolute
  z-index: 1
  top: $topbar-height + 1.6rem
  left: 1.6rem

  .items
    display: flex
    align-items: center
    justify-content: space-around
    height: 4rem
    background-color: $white
    border-radius: 0.4rem
    box-shadow: 0 0.4rem 0.6rem $sky-dark
    &:empty
      display: none
    > div
      flex-grow: 1
      cursor: pointer
      padding: 0 1.2rem
      font-size: 1.4rem
      font-weight: $fw-regular
      text-align: center
      line-height: 2rem
      color: $ink-light
      &:nth-child(2)
        border-left: 0.1rem solid $ink-lightest
      &.active,
      &:hover
        color: $rombit-blue-600
