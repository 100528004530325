@import 'src/sass/_variables'

.tmv2-journey-player
  position: absolute
  left: 0
  bottom: 0
  width: 100%
  height: 4.4rem
  background-color: rgba($ink, 50%)

  &.compact
    // Minus the filter box width
    width: calc(100% - 38.4rem)

  *
    -webkit-user-select: none
    -ms-user-select: none
    user-select: none

  .slider
    position: absolute
    display: block
    width: 100%
    height: 0.6rem
    background-color: $sky

    .progress
      position: absolute
      z-index: 1
      left: 0
      height: 0.6rem
      background-color: $red

    input
      position: absolute
      z-index: 3
      width: 100%
      height: 0.6rem
      background-color: transparent
      outline: none
      -webkit-appearance: none

      &:focus
        outline: none

      &:hover
        opacity: 1

      &::-moz-range-progress
        background-color: transparent

      &::-webkit-slider-thumb
        z-index: 2
        width: 1.4rem
        height: 1.4rem
        background-color: $red
        cursor: grab
        border-radius: 50%
        -webkit-appearance: none
        appearance: none

        &:hover
          box-shadow: 0 0 0.2rem 0.1rem #333333

        &:active
          cursor: grabbing

      &::-moz-range-thumb
        z-index: 2
        width: 1.4rem
        height: 1.4rem
        background-color: $red
        cursor: grab

        &:hover
          box-shadow: 0 0 0.2rem 0.1rem #333333

        &:active
          cursor: grabbing

  .controls
    display: flex
    align-items: center
    justify-content: space-between
    height: 100%
    padding: 0 2.4rem
    margin-top: 0.4rem
    font-size: 1.2rem
    font-weight: $fw-bold
    color: $white

    .playback
      display: flex
      align-items: center
      justify-content: flex-start

      .play-button
        display: flex
        align-items: center
        justify-content: center
        width: calc(4rem - 0.6rem)
        height: calc(4rem - 0.6rem)
        cursor: pointer
        margin-right: 0.8rem

        &:hover
          background-color: #00000011

        svg
          path
            fill: white

    .trailing
      display: flex
      align-items: center
      justify-content: center

      .position-indicators-toggle
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        margin-right: 1.6rem

        &:before
          content: ""
          display: block
          width: 1.6rem
          height: 2rem
          background-color: $rombit-blue-600
          margin-right: 0.8rem
          border-radius: 0.4rem 0.4rem 0 0

        .control_indicator
          opacity: 0.6

      .speed
        display: flex
        align-items: center
        justify-content: center
        width: calc(4rem - 0.6rem)
        height: calc(4rem - 0.6rem)
        cursor: pointer

        &:hover
          background-color: #00000011

  .position-indicators
    position: absolute
    top: -2rem
    left: 0
    width: 100%
    height: 2rem

    .indicator
      position: absolute
      top: 0
      height: 100%
      cursor: pointer
      border-radius: 0.4rem 0.4rem 0 0
