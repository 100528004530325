@import 'src/sass/_variables'

.tmv2-filters-search
  position: relative
  display: flex
  align-items: center
  justify-content: flex-start
  margin-bottom: 1rem

  svg path
    width: 1.6rem
    height: 1.6rem

  .icons-search
    position: absolute
    z-index: 1
    left: 0.8rem
    width: 1.6rem
    height: 1.6rem
    path
      fill: $ink

  .close
    position: absolute
    right: 0.8rem
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

    .icons-close
      width: 1.6rem
      height: 1.6rem

  input
    width: 100%
    height: 2.4rem
    // filter specific
    padding-right: 3.3rem
    padding-left: 2.8rem
    border: 0.1rem solid $sky-dark
    border-radius: 0.4rem
    &::placeholder
      opacity: 1
    // Firefox
      color: $input-placeholder-color
    &:focus
      border: 0.2rem solid $rombit-blue-600
