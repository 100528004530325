@import 'src/sass/_variables'

.ui-user-settings
  display: flex
  .user
    position: relative
    display: flex
    align-items: center
    &:hover .menu
      display: block
    .ui-dropdown-menu
      .component
        width: 3.2rem
        height: 3.2rem // for safari
        line-height: 1 // fix additional vertical space
      img
        width: 3.2rem
        height: 3.2rem
      .dropdown-content
        right: -1.4rem
        min-width: 20.8rem
        &.arrow:after
          border-bottom-color: $white
          margin-left: 0
        &.arrow:before
          border-bottom-color: $sky
          margin-left: -0.1rem
  .profile-dropdown
    position: relative
    z-index: 4 // over IC panel
    .dropdown-toggle
      .company-algo
        display: flex
        align-items: center
        padding: 0.4rem
        border-radius: 0.8rem
        &:hover
          background-color: $sky
          cursor: pointer
        img
          width: 3.2rem
          height: 3.2rem
          margin-right: 0.8rem
          border-radius: 0.8rem
        .initials
          display: flex
          align-items: center
          justify-content: center
          flex-shrink: 0
          width: 3.2rem
          height: 3.2rem
          background-color: $sky
          margin-right: 0.8rem
          border: 0.1rem solid $white
          border-radius: 0.4rem
          font-size: 1.2rem
          font-weight: $fw-bold
          text-transform: uppercase
          line-height: 1.6rem
          user-select: none
          color: $ink
        .icons-chevron-down
          width: 1.6rem
          height: 1.6rem
    &.open-dropdown
      .dropdown-toggle
        .company-algo
          background-color: $sky
      .profile-dropdown-content
        position: absolute
        top: 4.8rem
        right: 0
        width: 32rem
        background-color: $white
        padding: 1.6rem
        // border: 0.1rem solid $sky
        border-radius: 0.8rem
        box-shadow: 0 0.1rem 0.4rem $ink-lightest
      .dropdown-item
        &.user-profile,
        &.chat-with-us,
        &.log-out
          display: flex
          align-items: center
          flex-flow: row nowrap
          padding: 0.8rem 0.4rem
          svg[class^="icons"]
            margin-right: 0.8rem
            path
              fill: $ink
          span
            font-size: 1.4rem
            color: $ink
          &:hover
            background-color: $sky-light
            cursor: pointer
            svg[class^="icons"]
              path
                fill: $black
            span
              color: $black
        &.log-out
          svg[class^="icons"]
            path
              fill: $red
          span
            color: $red
          &:hover
            svg[class^="icons"]
              path
                fill: $red-darker
            span
              color: $red-darker
        &.user-data
          padding-bottom: 1.6rem
          margin-bottom: 1.6rem
          border-bottom: 0.1rem solid $sky
          .fullname
            max-width: 25rem
            margin-bottom: 0.1rem
            font-size: 1.6rem
            font-weight: $fw-bold
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden
            line-height: 2.4rem
            color: $black
          .email
            font-size: 1.2rem
            font-weight: $fw-regular
            line-height: 2rem
            color: $ink-lightest
        &.places-menu-wrapper
          padding-bottom: 1.6rem
          margin-bottom: 1.6rem
          border-bottom: 0.1rem solid $sky
          .menu-label
            margin-bottom: 0.4rem
            font-size: 1.4rem
            font-weight: $fw-semibold
            line-height: 2.2rem
            color: $ink
          .places-menu
            .dropdown-content
              top: 4.4rem
              width: 28.6rem
              max-height: 32rem
              overflow: hidden scroll
              .menu-row
                .icons-menu:empty
                  display: block
                  width: 1.6rem
            .company-place-selector
              display: flex
              align-items: center
              width: 100%
              padding: 0.8rem
              border: 0.1rem solid $sky
              border-radius: 0.8rem
              &:hover
                border-color: $sky-dark
                .icons-chevron-down
                  path
                    fill: $black
              .logo-img
                display: inline-block
                object-fit: cover
                width: 3.2rem
                height: 3.2rem
                margin-right: 0.8rem
                border: 0.1rem solid $white
                border-radius: 0.2rem
              .initials
                display: flex
                align-items: center
                justify-content: center
                flex-shrink: 0
                width: 3.2rem
                height: 3.2rem
                background-color: $sky
                margin-right: 0.8rem
                border: 0.1rem solid $white
                border-radius: 0.2rem
                font-size: 1.2rem
                font-weight: $fw-bold
                text-transform: uppercase
                line-height: 1.6rem
                color: $ink
              .names-wrapper
                display: flex
                flex-direction: column
                .place-name
                  font-size: 1.4rem
                  font-weight: $fw-semibold
                  line-height: 1.8rem
                  white-space: nowrap
                  color: $black
                .company-name
                  font-size: 1rem
                  font-weight: $fw-regular
                  line-height: 1.4rem
                  white-space: nowrap
                  color: $black
              .icons-chevron-down
                margin-left: auto
