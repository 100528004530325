@import 'src/sass/_variables'

.incompatible-device
  display: flex
  width: 100%
  height: 100vh
  background: $rombit-blue-900
  margin: 0 auto
  overflow: auto
  .container
    display: flex
    align-items: center
    flex-direction: column
    width: 100%
    height: auto
    min-height: 58rem
    background: url("../../../images/worldmap.svg") no-repeat center
    background-size: 70%
    padding: 1.5rem
    +browser-max-zoom-heigth(700px)
      justify-content: center
    .follow-us
      display: flex
      justify-content: center
      flex-direction: column
      margin-top: 6.4rem
      text-align: center
      +browser-max-zoom-heigth(750px)
        margin-top: 7.9rem
      +browser-max-zoom-heigth(700px)
        margin-top: 4.9rem
      p,
      .ui-link
        margin-bottom: 0.8rem
        font-size: 1.6rem
        font-weight: $fw-bold
        line-height: 1.8rem
        color: $white
        +browser-max-zoom-width(580px)
          font-size: 1.4rem
      .ui-link
        border-bottom: none
        font-weight: $fw-bold
        text-decoration: none
    .logo
      display: flex
      justify-content: center
      margin: 11rem 0 6.4rem
      text-align: center
      +browser-max-zoom-heigth(850px)
        margin: 4.5rem 0 2.4rem 0
      +browser-max-zoom-heigth(750px)
        margin: 6.7rem 0 3.2rem 0
      +browser-max-zoom-heigth(700px)
        margin: 0 0 3.2rem 0
      +browser-max-zoom-heigth(600px)
        margin: 0.5rem 0 2rem 0
      svg
        width: 12rem
        height: 10.9rem
        +browser-max-zoom-heigth(800px)
          width: 10rem
          height: 9.1rem
      path
        fill: $white
    .wrap-message
      height: 4.6rem
  .wrap
    display: flex
    align-items: center
    flex-direction: column
    width: 39.2rem
    background: $white
    padding: 4rem 0
    border-radius: 0.8rem
    +browser-max-zoom-width(580px)
      width: 33.4rem
    +browser-max-zoom-width(380px)
      width: 28rem
    h1
      margin-bottom: 1.8rem
      font-size: 2rem
      line-height: 3.6rem
      color: $ink-light
    .text
      width: 31.2rem
      margin-top: 2.6rem
      font-size: 1.6rem
      font-weight: $fw-regular
      text-align: center
      line-height: 2.4rem
      color: $ink-light
      +browser-max-zoom-width(580px)
        width: 28.6rem
      +browser-max-zoom-width(380px)
        width: 23.2rem
      a
        border-bottom: none
        text-decoration: none
        color: $rombit-blue-600
      p:nth-child(1)
        font-weight: $fw-bold
      p
        display: inline
