@import 'src/sass/_variables'

.rule
  .box
    +base-card
    display: flex
    flex-direction: column
    width: 82rem
    padding: 4.8rem
    +browser-only-max-zoom-width(768px)
      width: 40rem
    +browser-only-max-zoom-width(480px)
      width: 30rem
    > .ui-title
      margin-bottom: 2.4rem

    .ui-input
      flex-direction: column
      margin: 0 0 1.6rem
      &:last-child
        margin-bottom: 0
    .post-reminder
      label
        font-size: 1.4rem
        line-height: 3rem
        color: $ink-light

    .buttons
      position: relative
      display: flex
      align-items: center
      justify-content: space-between
      /*
        Force the GPU to re-render this div to avoid visual glitches.
        (look for: "5. Add a Transform Hack")
        https://chatgpt.com/share/67814b6c-40c4-8008-89ab-d3a5a8c4c8e7
       */
      transform: translateZ(0)
      margin: 4.8rem 0 0
      // Add and Save buttons
      > div:nth-child(2)
        display: flex
        > .ui-button:only-child
          margin-right: 0
        > .ui-button:not(:only-child):first-child
          margin-right: 2.4rem
        > .ui-button:nth-child(1)
          margin-right: 2.4rem
          &:only-child
            margin-right: 0
        > .ui-button:not(:last-child)
          margin-right: 0.8rem

    .btn-test-action
      position: absolute
      bottom: -4.8rem
      right: -2.4rem
