@import 'src/sass/_variables'

.ui-resume-bar
  +base-card
  display: flex
  padding: 2.4rem
  margin-bottom: 2.4rem
  .values-box
    display: flex
    flex-direction: column
    &.left
      align-items: flex-start
      justify-self: flex-start

    > div
      display: flex
      align-items: center
    // title
    > .ui-label-info-tooltip,
    h3
      margin-top: 0
      margin-bottom: 0.8rem
      font-size: 1.4rem
      font-weight: $fw-bold
      line-height: 2rem
      color: $ink-lighter
    > .ui-label-info-tooltip
      margin-bottom: 0.4rem
      line-height: 2.4rem
    .quantity
      font-size: 3.2rem
      font-weight: $fw-bold
      line-height: 4rem
    .subtitle
      font-size: 1.4rem
      font-weight: $fw-regular
      text-align: left
      line-height: 2rem
      color: $ink-lighter
    .ui-button.secondary
      height: 2rem
      padding: 0

    .resume-section
      &.with-icon
        > div
          display: flex
          align-items: center
          justify-content: center
          width: 4rem
          height: 4rem
          border-radius: 0.8rem
          [class^="icons"]
            flex-shrink: 0
            width: 2.4rem
            height: 2.4rem
      .ui-docking-slot
        &:hover
          box-shadow: none

    &.last-connection
      justify-content: center
      border-right: none
      > span
        font-size: 1.4rem
        line-height: 1.6rem
        color: $ink

    &.icon
      .icon-wrapper
        display: flex
        align-items: center
        justify-content: center
        width: 8rem
        height: 8rem
        background-color: $rombit-blue-100
        margin: auto 4rem auto 0
        border-radius: 0.8rem
        svg
          width: 4.8rem
          height: 4.8rem
          margin: auto
          path
            fill: $rombit-blue-700
  > .resume-separator
    width: 0.1rem
    background-color: $sky
