@import 'src/sass/_variables'

.send-email
  .container
    .wrap-message
      height: 4.6rem
    .content
      display: flex
      align-items: center
      flex-direction: column

  .wrap
    form
      width: 100%
      font-size: 1.4rem
      color: $ink-light
    .button
      display: flex
      justify-content: center
      margin: 2.4rem 0 2rem
