@import 'src/sass/_variables'

.filters
  .config-box
    display: flex
    justify-content: space-between
    border-top: solid 0.1rem $sky-dark
    > div
      display: flex
      flex-direction: column
      padding: 1.6rem 2.4rem
      &:nth-child(1)
        padding-right: 0.4rem
      &:nth-child(2)
        min-width: 18rem
        padding-left: 0.4rem
      h4
        white-space: nowrap
      > div
        display: flex
        flex-direction: row
        margin-top: 1rem
