@import 'src/sass/_variables'

.routes-backoffice-device-configurations
  margin-top: 1rem
  .ui-row .separator
    margin: 1.6rem 0
  .buttons
    display: flex
    justify-content: space-between
  .ui-list
    max-height: calc(100vh - 15rem)
    margin-top: 1rem
    margin-right: -4rem
    .field
      &.name
        &:hover
          text-decoration: none
      cursor: default
