@import 'src/sass/_variables'

.ui-map-tooltip
  width: fit-content
  padding: 1.6rem
  border-radius: 0.4rem
  box-shadow: 0 0 0.4rem $sky-dark
  h2
    width: 20.8rem
    font-size: 1.4rem
    font-weight: 700
    line-height: 2rem

  .content
    font-size: 1.2rem
    font-weight: $fw-regular
    line-height: 1.6rem
    .ui-pill
      background-color: transparent
      padding-left: 0
    .show-more
      font-size: 1.2rem
      font-weight: 700
      line-height: 1.6rem
    > p
      color: $sky-dark
    .buttons
      display: flex
      align-items: center
      justify-content: flex-start
      width: 100%
      margin-top: 0.8rem
      &:empty
        margin-top: 0
      button:first-child
        margin-right: 0.8rem
    .zone-row
      display: flex
      align-items: center
      justify-content: flex-start
      label
        text-transform: capitalize

  // Themes
  &.light
    background-color: $white
    h2
      color: $ink
    .content
      color: $ink
      .ui-pill
        [class^="icons-"]
          path
            fill: $ink
        > span
          color: $ink
      .timestamp
        color: $ink-lighter

  &.dark
    background-color: $ink-light
    h2
      color: $white
    .content
      color: $white
      .ui-pill
        [class^="icons-"]
          path
            fill: $white
        > span
          color: $white
      .timestamp
        color: $sky-dark
      .ui-button.outline
        border-color: $white
        color: $white
