@import 'src/sass/_variables'

.routes-management-attendances
  .attendance-header
    position: relative
    z-index: 2 // over table header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 1.6rem
    > div:first-child
      display: flex
      align-items: center
      justify-content: flex-start
    h2,
    .spacer
      font-size: 1.8rem
      font-style: normal
      font-weight: $fw-bold
      line-height: 2.4rem
      color: $ink
    .spacer
      padding: 0 0.4rem
  .SingleDatePicker
    div
      border: unset
      border-radius: unset
    .SingleDatePickerInput
      background-color: unset
      padding-left: 0
      .DateInput
        width: 9.5rem
        background: unset
        .DateInput_input
          background-color: $sky-light
          cursor: pointer
          padding: unset
          border-bottom: 0
          font-size: 1.8rem
          font-style: normal
          font-weight: $fw-bold
          line-height: 2.4rem
          color: $ink
        button
          padding: unset
          margin: unset
          line-height: 0
        .DateInput_fang
          margin-top: -1.5rem
        .SingleDatePicker_picker
          margin-top: -1.6rem
      .SingleDatePickerInput_calendarIcon
        height: 2.4rem
        margin-top: 0.4rem
