@import 'src/sass/_variables'
.docking-status
  .ui-status
    &.theme-red
      background-color: $red-lighter
      span
        font-weight: 700
        color: $red-darker
        white-space: nowrap
      svg
        path
          fill: $red-darker

    &.theme-green
      background-color: $green-lighter
      span
        font-weight: 700
        color: $green-darker
        white-space: nowrap
      svg
        path
          fill: $green-darker

    &.theme-sky
      background-color: $sky
      span
        font-weight: 700
        color: $ink-light
        white-space: nowrap
      svg
        width: 1.6rem
        height: 1.6rem
        path
          fill: $ink-light
