@import 'src/sass/_variables'

.ui-alert
  width: 95rem
  div:nth-child(1)
    a
      margin: 0 0.6rem
      font-weight: $fw-bold
      text-decoration: underline
      color: $ink
      &:hover
        cursor: pointer
  .icons-evacuation
    width: 1.6rem
    height: 1.6rem
    margin-right: 1.4rem
    path
      fill: $red
