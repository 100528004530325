@import 'src/sass/_variables'

.certificates-filters
  .checkboxes-container
    > label
      +common-label
      > div:only-child
        line-height: 0
    .icons-circle-filled-information
      cursor: pointer
      margin-left: 0.4rem
      path
        fill: $sky-dark
    .checkboxes-box
      display: flex
      > div
        display: flex
        span
          // padding-top: 0.2rem
          font-size: 1.2rem
          font-weight: 400
          line-height: 1.6rem
          color: $ink
        &:first-child
          margin-right: 2.4rem
