@import 'src/sass/_variables'

.ui-menu
  display: flex
  flex-direction: column
  flex-shrink: 0
  width: 24.8rem
  background-color: $sky-lighter
  padding: 1.6rem 0 2.4rem
  .ui-menu-head
    padding: 0 1.6rem 1.6rem
    &:empty
      display: none
    p
      font-size: 1.4rem
      font-weight: 700
      line-height: 2rem
      color: $ink-light
    label
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 2rem
      color: $ink-lighter
  .ui-menu-options
    .menu-row-button
      align-items: initial
      width: calc(100% - 3.2rem)
      height: 4.8rem
      background-color: transparent
      padding: 0.7rem 1.8rem
      margin: 0 1.6rem
      border-radius: 0.8rem
      &:hover
        background-color: $rombit-blue-100
      > div
        display: flex
        align-items: center
        justify-content: flex-start
        flex: 1
        width: 100%
        font-size: 1.4rem
        font-weight: $fw-regular
        color: $ink-lighter
        [class^="icons-"]
          flex-shrink: 0
          margin-right: 1.6rem
          &:not(.icons-folder):not(.icons-star)
            path
              fill: $ink-lighter
        // If the badge is the last item (no bullets)
        > span:last-child
          margin-right: 3.2rem
      &.selected
        background-color: $rombit-blue-300
        .item-name
          font-weight: $fw-semibold
          color: $ink-light
        > div
          [class^="icons-"]
            &:not(.icons-folder):not(.icons-star)
              path
                fill: $ink-light
      .item-name
        max-width: 10rem
        margin-right: 0.8rem
        text-align: left
        color: $ink-lighter
        &.no-count.no-actions
          min-width: 14rem
          max-width: 14rem
      .count
        &:not(:empty)
          width: 3.6rem
          height: 2.4rem
          background-color: $ink-lightest
          padding: 0 0.8rem
          margin-left: auto
          border-radius: 0.8rem
          font-size: 1.4rem
          font-weight: $fw-semibold
          text-align: center
          color: $white
      .ui-bullets
        margin-left: 1.6rem
        .bullets
          svg
            margin-right: 0
    > .selected
      background-color: $rombit-blue-300
      .item-name
        font-weight: 700
        color: $ink
      &:hover
        background-color: $rombit-blue-300
