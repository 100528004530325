@import 'src/sass/_variables'

.users-permissions-list
  padding-top: 1rem
  margin: 1rem
  > p
    padding: 0 2rem
    font-size: 1.4rem
  .permissions-list
    padding: 0 2rem
    margin: 0 2rem
    font-size: 1.4rem
    li
      margin: 0.5rem 0
      >span
        padding-right: 0.6rem
        font-weight: 700
