@import 'src/sass/_variables'

.table-header-content
  display: flex
  align-items: center
  justify-content: flex-end

#portal
  .bullets-menu-portal
    min-width: 11.5rem
    .menu-row
      .icons-menu
        &:empty
          display: none
