@import 'src/sass/_variables'

.docking-detail.routes-devices
  > .container
    +scrollable-container

// Small table + docking view
.container.resume
  .resume-container
    display: flex
    justify-content: space-between
    width: 100%
    // Smaller version of the table
    .ui-table
      min-width: 27rem
      margin-right: 2rem
      .rdt_TableBody
        max-height: 37.6rem
        +browser-max-zoom-heigth(760px)
          max-height: 32.4rem
        +browser-max-zoom-heigth(720px)
          max-height: 30.2rem
        +browser-max-zoom-heigth(680px)
          max-height: 22rem

    .devices-blank-state
      margin-right: 2rem
      .blank-state
        height: 41rem
        margin-top: 0
        // same as docking container
        +browser-max-zoom-heigth(760px)
          height: calc(100vh - 36rem)
        +browser-max-zoom-heigth(720px)
          min-height: calc(100vh - 40rem)
        +browser-max-zoom-heigth(680px)
          min-height: 25.2rem
