@import 'src/sass/_variables'

.ui-side-panel
  z-index: 3 // to place group-customization panel over the icons in the table
  .update
    height: 100%
    div[class$="-updates"],
    .certificate-view
      height: 100%
      padding: 2.4rem 2.4rem 0
      .title
        margin-right: 1.6rem
        margin-bottom: 2.4rem
        font-size: 1.6rem
        font-weight: $fw-bold
        line-height: 1.6rem
        color: $ink-light
      .ui-translation-label
        > span:nth-child(1)
          font-weight: $fw-bold
    .certificate-view
      .gray
        font-weight: $fw-bold
    .item-updates
      .ui-panel
        cursor: pointer
      .ui-side-panel-row
        &.subtypes
          margin-bottom: 1.2rem
        &.cards
          margin-bottom: 0
          .content
            > div:last-child
              margin-bottom: 0
        &.groups-container
          .create-container
            margin-bottom: 1.6rem
    .group-updates
      > .group-edits
        height: calc(100% - 4rem)
        padding: 0
        form
          height: calc(100% - 2.4rem)
        .buttons
          padding-bottom: 0

    // View only panels
    .item-details,
    .device-details,
    .infrastructure-details
      .ui-side-panel-row
        margin-bottom: 2.4rem
        .ui-label
          font-size: 1.4rem
          line-height: 2rem
          span:first-child
            font-weight: $fw-bold
    .device-details
      // Signal panel
      .ui-side-panel-row
        &.device-signal
          align-items: center
          flex-direction: row
          background-color: $sky
          padding: 1rem
          border-radius: 0.4rem
          .ui-label-info-tooltip
            justify-content: space-between
            width: 100%
            [class^="icons-"]
              margin-right: 1rem
            .icons-question
              path
                fill: $ink-lightest


    form
      display: flex
      flex-direction: column
      flex-shrink: 0
      width: 100%
      height: calc(100% - 4rem) // button height compensation
      .side-panel-select
        margin-bottom: 1.6rem
        > label
          +common-label

      // Group customization
      // ===================
      .customization
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 2.4rem
        > div
          display: flex
          align-items: center
          margin: 0
          > p
            font-weight: $fw-bold
          .icons-question
            display: flex
            width: 1.6rem
            height: 1.6rem
            margin: 0 0.4rem
            path
              fill: $sky-dark

      .content
        .space
          display: flex
          align-items: center
          margin-bottom: 1.6rem
          label
            flex: 1 1
            width: 5rem
            margin: 0.8rem 0
            font-size: 1.4rem
            font-weight: $fw-regular
            line-height: 1.8rem
        > span
          margin: 0 1rem 0 0.8rem
          font-size: 1.4rem
          white-space: nowrap

      .buttons
        display: flex
        align-items: center
        justify-content: space-between
        padding-bottom: 2.4rem
        margin-top: auto
        .ui-button
          flex-shrink: 0
          width: fit-content
