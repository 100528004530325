@import 'src/sass/_variables'

.ui-editable-label
  .group-edit
    position: relative
    display: flex
    justify-content: center
    flex-flow: row nowrap
    height: 3rem
    margin-left: 2.6rem
    label
      +ellipsis(24rem)
      position: relative
      height: 3rem
      cursor: pointer
      font-size: 2rem
      font-weight: 700
      text-align: center
      line-height: 2.8rem // border-width compensation

    .icon-wrapper
      width: 1.6rem
      margin: 0.7rem 0 0 1rem
      line-height: 0

  .ui-input
    height: 3rem
    margin-bottom: 0
    line-height: 2.4rem
    &:first-child
      margin-bottom: 0
      border-bottom: none
    &:last-child
      margin-top: 0
      border-top: none
    input
      height: 3rem
      padding-right: 1rem
