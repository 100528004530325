@import 'src/sass/_variables'

.group-card
  display: flex
  align-items: center
  justify-content: space-around
  width: 100%
  height: 5.8rem
  padding: 0.4rem
  .ui-avatar.big
    margin: 0.8rem
  .icon-container
    display: flex
    align-items: center
    justify-content: center
    width: 4rem
    height: 3.2rem
    background-color: $sky
    margin: 0.8rem
    border-radius: 50%
  .content
    position: relative
    display: flex
    flex-flow: column nowrap
    width: 100%
    margin-left: 0.8rem
    .info
      display: flex
      align-items: flex-start
      flex-direction: column
      max-width: 33rem
      >span:first-child
        display: flex
        align-items: center
        max-width: 26.4rem
        font-weight: $fw-bold
        color: $ink
        .ui-pill
          background-color: transparent
          margin-left: 0.8rem
          border: 0.1rem solid $ink-lighter
      > span
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-lighter
    .actions
      position: absolute
      right: 0.4rem
      display: flex
      justify-content: flex-end
      align-content: center
      width: 100%
      height: 100%
      background: linear-gradient(90deg, transparent 0%, $sky 60%, $sky 100%)
      &.add-group
        > .ui-button
          background: transparent
      .button-controls
        display: flex
        align-self: center
        > .ui-button:last-child
          color: $red
    &.add-group
      .info
        &:hover
          span
            +ellipsis(14rem)
            span
              +ellipsis(14rem)
              display: block
  &:hover
    background: $sky
    .ui-avatar
      >img
        border: 0.1rem solid $sky-dark
    .icon-container
      background-color: $sky-light
    &.add-group
      background: transparent
