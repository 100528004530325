@import src/sass/_variables

.ui-progress-bar
  width: 100%
  .bar
    width: 100%
    height: 1.6rem
    background-color: $sky
    border-radius: 1.2rem
    .ui-progress
      height: 1.6rem
      border-radius: 1.2rem
      &.orange-bg
        background-color: $orange
      &.blue-bg
        background-color: $rombit-blue-700
      &.teal-bg
        background-color: $teal
      &.purple-bg
        background-color: $purple
  > div
    display: flex
    justify-content: space-between
    margin-bottom: 0.8rem
    > span
      font-size: 1.4rem
      font-weight: $fw-bold
      line-height: 2rem
      color: $ink-light
  span,
  .details-container
    display: flex
    font-size: 1.4rem
    text-align: left
    line-height: 2rem
    color: $ink-lighter
  span
    &.bold
      padding-right: 0.4rem
      font-weight: 700
