@import 'src/sass/_variables'

.icon-preview
  display: flex
  align-items: center
  justify-content: center
  height: 3.2rem
  margin-left: 1.6rem
  border-radius: 50%
  filter: drop-shadow(0.2rem 0.1rem 0.2rem $ink-lightest)

.select-icon
  display: flex
  align-items: center
  justify-content: space-around
  width: 6.5rem
  height: 3.6rem
  background: $white
  padding: 0 0.4rem
  margin: 0
  border: 0.1rem solid $sky-dark
  border-radius: 0.4rem
  [class^="icons-"]
    display: flex
    align-items: center
    justify-content: center
    &:not(.icons-chevron-down)
      position: relative
      bottom: 0.4rem

.select-icon-menu
  // TODO: Remove this implementation once svg icons be replaced
  .component
    .select-icon
      > [class^="icons-"]
        position: relative
        top: -0.1rem
        width: 2.8rem
        height: 2.8rem

  &.show
    .component
      .select-icon
        border-color: $rombit-blue-600
        box-shadow: 0 0 0 0.1rem $rombit-blue-600 inset

  .dropdown-content.horizontal
    justify-content: space-around
    min-width: 20rem
    padding: 0 0.4rem
    margin-top: 0.4rem
    border-radius: 0.4rem
    > div
      // override
      height: 2.4rem
      padding: 0
      margin: 0.4rem
    .menu-row
      padding: 0 0.2rem 0.2rem 0
      [class^="icons-"]
        width: 2.8rem
        height: 2.8rem
  & + .ui-select-color
    margin-left: 0.4rem
