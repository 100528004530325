@import 'src/sass/_variables'

.tmv2-zoom-controls
  position: absolute
  z-index: 1
  bottom: 1.6rem
  right: 1.6rem

  &.suspended
    // Factor it Journey Player height
    bottom: calc(4.4rem + 1.6rem)

  &.compact
    // Factor in filters panel width
    right: calc(38.4rem + 1.6rem)

  .controls
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    background-color: $white
    border-radius: 0.4rem
    box-shadow: 0 0.4rem 0.6rem $sky-dark

    .control
      cursor: pointer
      padding: 0.8rem
      text-align: center

      &:hover svg path
        stroke: $rombit-blue-600

      svg path
        stroke: $ink-lightest

    .separator
      opacity: 0.5
      width: 50%
      height: 0.1rem
      background-color: $ink-lightest
