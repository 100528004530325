@import 'src/sass/_variables'

.ui-file-panel
  display: flex
  align-items: center
  justify-content: center
  height: 5rem
  background-color: $sky-light
  padding: 0.8rem
  border: 0.1rem solid $sky-dark
  border-radius: 0.8rem
  .panel-texts
    display: flex
    flex-direction: column
    width: 100%
    max-width: 12rem
    margin-left: 0.4rem
    .ui-url
      span:first-child
        max-width: 8.6rem
    > div
      flex: 1
      font-size: 1.2rem
      font-weight: $fw-bold
      text-align: right
      line-height: 1.6rem
      color: $ink-lightest
      &:first-child
        max-width: 16rem
        text-align: left
        color: $ink
      &:nth-child(2)
        max-width: 12rem
  .panel-options
    display: flex
    align-items: center
    justify-content: center
    margin-left: 1.6rem
    .panel-button
      width: 2.4rem
      height: 2.4rem
      background-color: $white
      padding: 0
      border: 0.1rem solid $sky-dark
      border-radius: 0.4rem
      box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.25)
      &:first-child
        margin-right: 0.8rem
      &:only-child
        margin-right: 0
      & > div
        line-height: 0
        svg
          width: 1.6rem
          height: 1.6rem
          path
            fill: $ink-lighter
        .icons-trash-filled
          path
            fill: transparentize($ink-lighter, 0.25)
  .icons-file
    width: 3.2rem
    height: 3.2rem
    path
      fill: $ink-lightest
