@import 'src/sass/_variables'

.ui-priority
  line-height: 0
  > div
    display: flex
    align-items: center
    justify-content: center
  [class^="icons"]
    width: 2.4rem
    height: 2.4rem
    margin-right: 0.4rem
  .critical
    svg
      fill: $red
  .high
    svg
      fill: $orange
  .medium
    svg
      fill: $rombit-blue-700
  .low
    svg
      fill: $ink-lighter

  .icons-warning
    path
      fill: $red
  .icons-arrow-up
    path
      fill: $orange
  .icons-arrow-right
    path
      fill: $rombit-blue-700
  .icons-arrow-down
    path
      fill: $ink-lighter
