@import 'src/sass/_variables'

.summary
  .actions
    .buttons
      display: flex
      flex-direction: column
      .send-actions
        display: flex
        flex-direction: row
        .light
          margin-right: 1.6rem
    .flex-start
      justify-content: end
