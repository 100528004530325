@import 'src/sass/_variables'

.error-bar.active ~ .dashboard .routes-map > .overlay
  top: 9rem // make filters with overlay grab the space needed for position.

.routes-map
  .time-machine,
  .live-map
    position: relative
  .footer-overlay,
  .container.overlay
    position: absolute
    z-index: 1
  .footer-overlay
    bottom: 1rem
  .container.overlay
    top: 0
    left: 50%
    right: 0
    display: flex
    align-items: center
    justify-content: center
    flex: 1 1 auto
    flex-direction: row
    transform: translateX(-50%)
    margin-top: 1rem
    .time-machine-maps-header
      display: flex
      align-items: center
      justify-content: space-around
      width: 29rem
      height: 4rem
      background-color: $white
      border-radius: 0.4rem
      box-shadow: 0 0.4rem 1.6rem $sky-dark
      &:empty
        display: none
      > div
        flex-grow: 1
        cursor: pointer
        padding: 0 1.2rem
        font-size: 1.4rem
        font-weight: $fw-regular
        text-align: center
        line-height: 2rem
        color: $ink-light
        &:nth-child(2)
          border-right: 0.1rem solid $ink-lightest
          border-left: 0.1rem solid $ink-lightest
        &.active,
        &:hover
          color: $rombit-blue-600

    > button
      margin-right: 1rem
      &:first-child
        width: 4rem
        height: 4rem
      &:last-child
        margin-right: 0
        > div
          display: flex
          align-items: center
          padding: 0 1.2rem
          font-weight: $fw-regular
          color: $ink-lighter
  .ui-side-panel
    &.right
      > div:first-child
        > div:nth-child(2)
          height: 100%

  .shared-headers
    position: absolute
    z-index: 1
    top: 1rem
    right: 1.6rem
    display: flex
    .gmaps
      &:nth-child(1),
      &:nth-child(2)
        padding: 0 0.8rem
      .icons-reset,
      .icons-share
        flex-shrink: 0
        margin-right: 0
    > div
      margin-right: 1rem
    > button
      &:first-child
        width: 4rem
        height: 4rem
      &:last-child
        margin-right: 0

  .clickeable svg
    pointer-events: none
