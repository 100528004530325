@import 'src/sass/_variables'

.certificates-view
  overflow: hidden scroll
  padding: 2.4rem 1.6rem
  .ui-panel
    width: 100%
    .panel-texts
      > div:nth-child(2)
        max-width: 100%
  > div:first-child
    max-width: 90%
    font-size: 1.6rem
    font-weight: 700
    line-height: 1.6rem
  .certificates-panel
    display: flex
    flex-direction: column
    width: 100%
    min-height: 11rem
    background-color: $sky
    padding: 2.4rem 1.6rem
    margin-top: 4rem
    border-radius: 0.4rem
    > label
      margin-bottom: 0.8rem
      font-size: 1.4rem
      font-weight: 700
      line-height: 2rem
    &.expired
      background-color: $red-lighter
      > label
        color: $red
      .ui-panel
        .panel-content
          .subtitle
            color: $red
    &.about-to-expire
      background-color: $orange-lighter
      > label
        color: $orange
      .ui-panel
        .panel-content
          .subtitle
            color: $orange
    &.up-to-date
      background-color: $green-lighter
      > label
        color: $green
      .ui-panel
        .panel-content
          .subtitle
            color: $green
    .ui-button
      background: $white
      color: $rombit-blue-700
      &:hover
        background: $white
        color: $rombit-blue-700
  .item-data
    display: flex
    margin-top: 2.4rem
    > div
      width: 100%
      label
        font-weight: 700
      span
        min-height: 3.4rem
