@import 'src/sass/_variables'

.routes-map
  .title-back-wrapper
    display: flex
    flex-direction: row
    background-color: $white
    > .ui-button
      cursor: pointer
      padding: 0 1.6rem
      margin: auto
      box-shadow: none
    .ui-title
      padding-right: 0
  .historic-blank-state
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    margin: 0 auto
    .blank-state
      align-self: flex-start
      height: 18rem
      background-color: $sky-light
      padding: 0 3.6rem
      margin-top: 20rem
      line-height: 2rem
      .icon
        svg
          width: 4.8rem
          height: 4.8rem
      .title
        font-size: 1.6rem
        font-weight: $fw-bold
        color: $ink-light
      .text
        font-size: 1.4rem
        font-weight: $fw-regular
        color: $ink-light
  .cluster
    display: flex
    align-items: center
    justify-content: center
  .sidebar
    max-width: 25rem
    height: 100vh
    background-color: $sky-lighter

  .toast-wrapper
    position: relative
    display: flex
    justify-content: center
    width: 100%
    .toast-text
      .toast-message
        margin-bottom: 0
    .icons-load-spinner
      margin-right: 0.8rem
      animation: loadingSpinner 3s 0ms linear forwards infinite

  .ui-side-panel.modal-open
    z-index: 2
  .info-tooltip
    > div
      z-index: 1

  // Edit mode
  .ui-title
    > div
      display: flex
      align-items: center
      justify-content: flex-end
    .ui-checkbox
      display: flex
      align-items: center
      justify-content: center
  .routes-map-canvas
    z-index: 0
    display: flex
    flex-direction: row
    height: calc(100vh - 5rem)
    .canvas
      margin-top: 2rem
      margin-left: 2rem
      canvas
        border: 0.1rem solid $rombit-blue-900
      .tooltip
        position: absolute
        top: 50%
        left: 50%
        opacity: 0.5
        width: 20rem
        background-color: $black
        cursor: pointer
        padding: 1rem
        border-radius: 0.3rem
        font-size: 1.1rem
        text-align: center
        color: $white

.shared-headers
  .filters-qty
    position: absolute
    top: -0.4rem
    right: -0.6rem
    width: 1.4rem
    height: 1.4rem
    background-color: $rombit-blue-600
    border-radius: 50%

@keyframes loadingSpinner
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
