@import 'src/sass/_variables'

.ui-info-box
  position: absolute
  z-index: 1
  left: 50%
  opacity: 0.5
  width: 20rem
  background-color: $black
  cursor: pointer
  padding: 1rem
  border-radius: 0.3rem
  font-size: 1.1rem
  text-align: center
  color: $white
