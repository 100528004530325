@import 'src/sass/_variables'

.blank-state
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  background-color: $white
  &.table
    height: calc(100vh - 36.2rem)
  &.table-with-header
    height: calc(100vh - 24.2rem)
  &.table-with-filter
    height: calc(100vh - 24.2rem)

  .icon
    margin-bottom: 0.8rem
    line-height: 0
    svg
      width: 9.6rem
      height: 9.6rem
      &.grahps-flash
        width: 4.8rem
        height: 4.8rem
      path
        fill: $ink-light

  .message-content
    display: flex
    align-items: center
    flex-direction: column
    max-width: 65rem
    margin: 0 3.6rem
    .ui-title
      margin-bottom: 0.8rem
      text-align: center
      h2
        width: 100%
    p
      margin: 0 0 1.6rem
      font-size: 1.6rem
      font-weight: $fw-regular
      text-align: center
      line-height: 2rem
      color: $ink-light

  .footer
    margin-top: 2.4rem
    a
      display: block
