@import 'src/sass/_variables'

.routes-signin
  .container
    .wrap-message
      width: 100%
      min-height: 4.6rem
      .ui-top-notification
        max-width: 36rem
        white-space: normal
        text-align: center

  .wrap
    form
      width: 100%
      font-size: 1.4rem
      color: $ink-light
      .btn-cancel
        margin: auto

.common-login
  display: flex
  width: 100%
  height: 100vh
  background-color: #1E507E // TODO: UPDATE TO VARIABLE EVENTUALLY
  .container
    position: relative
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    width: 100%
    height: 100vh
    .graphs-parcial-r
      position: absolute
      top: 0
      left: 0
      width: auto
      height: 100%
    .graphs-parcial-o
      position: absolute
      top: 0
      right: 0
      width: auto
      height: 100%

  .content
    position: relative
    z-index: 2

  .wrap
    width: 41.4rem
    background: $white
    padding: 4rem
    border-radius: 0.8rem
    font-size: 1.4rem
    .notification-card
      display: flex
      align-items: center
      justify-content: flex-start
      background-color: $white
      padding: 1.6rem 0.8rem
      margin-top: 4rem
      margin-bottom: 2.4rem
      border-radius: 0.8rem
      box-shadow: 0.4rem 0.4rem 0.4rem transparentize($sky, 0.5), -0.4rem 0.4rem 0.4rem transparentize($sky, 0.5)

      svg
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem
      > div
        span
          display: block
      &.error-card
        svg
          path
            fill: $red
      &.sent-card
        svg
          path
            fill: $green
    p
      margin: 0
      font-size: 1.2rem
      text-align: left
      text-decoration: none
      line-height: 2rem
      color: $ink-light
    .graphs-logo-with-text
      display: flex
      align-items: center
      justify-content: center
      svg
        width: 10.6rem
        height: 11.3rem
        margin: 0 auto
        path
          fill: $black
          &:nth-child(1),
          &:nth-child(2)
            fill: $r-orange
    .ui-input
      label
        margin-bottom: 0.4rem
        line-height: 2rem
    .btn-reset,
    .btn-send
      margin: 1.6rem auto
    .btn-back-login
      margin: 1.6rem auto 0
    .btn-resend
      margin: 0 auto
      text-align: center
    .btn-reset-password
      display: block
      margin: 0 auto
      font-size: 1.2rem
      font-weight: $fw-regular
      text-align: right
      text-decoration: none
      line-height: 2rem
      color: $rombit-blue-600
      &:hover
        text-decoration: underline
    .btn-login
      margin: 1.6rem auto
      > div
        width: initial
        white-space: nowrap
