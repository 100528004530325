@import 'src/sass/_variables'

.tmv2-filters-header
  display: flex
  align-items: center
  width: 100%
  padding: 2.4rem 2.4rem 1.2rem
  font-size: 1.2rem

  .tmv2-filters-close-icon
    display: flex
    align-items: center
    justify-content: center
    width: 2rem
    height: 2rem
    cursor: pointer
    margin-right: 1.6rem

    svg
      width: 2rem
      height: 2rem

  h2
    font-size: 1.6rem
    font-weight: $fw-bold
    color: $ink-light

  .multi-selector
    margin-left: 1.6rem
