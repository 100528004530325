@import 'src/sass/_variables'

.places-backoffice
  padding: 1rem 2rem 2rem
  .ui-side-panel
    top: 0
  .places-header
    display: flex
    align-items: center
    flex-direction: row
    .check-wrap
      display: flex
      align-items: center
      flex-direction: row
      flex-basis: 24rem
      margin-right: 2.4rem
      .ui-checkbox
        .control
          margin-bottom: 0
      label
        margin-bottom: 0
        font-size: 1.4rem
        color: $ink

.ui-toast
  // Need 4 because of the sidePanel
  z-index: 4
