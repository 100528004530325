@import 'src/sass/_variables'

.docking-stations
  +dashboard-card
  display: flex
  justify-content: space-between
  flex-direction: column

  .docking-content
    display: block
    padding: 1.6rem
    > div:first-child
      display: flex
      justify-content: space-between
      width: 100%
      margin-bottom: 0.8rem
      span
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 1.6rem
        color: $ink
    .reference
      span
        &:first-child
          margin-right: 0.4rem
        &:last-child
          margin-left: 0.4rem
        &:first-child,
        &:last-child
          font-size: 1.4rem
          font-weight: $fw-regular
          line-height: 1.6rem
          color: $ink-lighter
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5)
          display: inline-block
          width: 1rem
          height: 1rem

        &:nth-child(2)
          background-color: $sky
          margin-right: 0.1rem
        &:nth-child(3)
          background-color: $rombit-blue-300
          margin-right: 0.1rem
        &:nth-child(4)
          background-color: $rombit-blue-800
          margin-right: 0.1rem
        &:nth-child(5)
          background-color: $rombit-blue-900

  // Enum.DockingStates
  .max-state
    display: grid
    grid-template-columns: repeat(10, 1fr)
    grid-template-rows: repeat(6, 1fr)
    grid-gap: 0.2rem
    .docking-box,
    .empty-slot
      width: initial
      height: 2.4rem
  .mid-state
    display: grid
    grid-template-columns: repeat(5, 1fr)
    grid-template-rows: repeat(4, 1fr)
    grid-gap: 0.2rem
    margin-top: 2rem
    .docking-box,
    .empty-slot
      width: initial
      height: 3.4rem
  .low-state
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-template-rows: 1fr
    grid-gap: 0.2rem
    margin: 6.2rem 0
    .docking-box,
    .empty-slot
      width: initial
      height: 3.8rem
  .min-state
    display: flex
    align-items: center
    justify-content: center
    margin: 2.4rem 0 1.6rem

  // Card
  .docking-box
    &:hover
      cursor: pointer
      border: 0.1rem solid $white
    &.full
      background-color: $rombit-blue-900
      border: 0.1rem solid $rombit-blue-900
      &:hover
        outline: 0.1rem solid $rombit-blue-900
    &.high
      background-color: $rombit-blue-800
      border: 0.1rem solid $rombit-blue-800
      &:hover
        outline: 0.1rem solid $rombit-blue-800
    &.low
      background-color: $rombit-blue-300
      border: 0.1rem solid $rombit-blue-300
      &:hover
        outline: 0.1rem solid $rombit-blue-300
    &.empty
      background-color: $sky
    &.is-not-reporting
      display: flex
      align-items: center
      justify-content: center
      background-color: $red
      border: 0.1rem solid $red
      font-size: 1.6rem
      font-weight: $fw-bold
      color: $white
      &:hover
        outline: 0.1rem solid $red-light

    &.is-unistalled
      display: flex
      align-items: center
      justify-content: center
      background-color: $sky
      border: 0.1rem solid $sky
      font-size: 1.6rem
      font-weight: $fw-bold
      color: $white
      &:hover
        outline: 0.1rem solid $sky-light

  .empty-slot
    width: initial
    background-color: $sky-light

  footer
    display: flex
    align-items: center
    justify-content: space-between
    border-top: 0.1rem solid $sky-light
    > div
      flex-grow: 1
      transition: background-color 200ms ease-in 0s
      padding: 1.2rem 0

      &:hover
        background-color: $sky-lighter
        cursor: pointer
      &.disabled
        color: $sky-dark
        svg
          path
            fill: $sky-dark
      &:first-child
        border-right: 0.1rem solid $sky-light
        border-bottom-left-radius: 0.8rem
      &:last-child
        border-bottom-right-radius: 0.8rem
      // text
      > span
        display: block
        text-align: center
        &:first-of-type
          margin-bottom: 0.2rem
          font-size: 1.2rem
          font-weight: $fw-regular
          line-height: 1.4rem
        &:last-child
          font-size: 1.6rem
          font-weight: $fw-bold
          line-height: 2rem
