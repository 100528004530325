@import 'src/sass/_variables'

.routes-requests
  .container
    margin: 2rem 4.4rem 4.8rem 3.2rem
    .requests-header
      display: flex
      justify-content: space-between
      > div
        margin-bottom: 1.6rem
        margin-left: auto
