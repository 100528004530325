@import 'src/sass/_variables'

.routes-backoffice-places
  .buttons
    display: flex
    justify-content: space-between
  .ui-list
    max-height: calc(100vh - 12rem)
    margin-top: 1rem
    margin-right: -4rem
    .field
      &.nombre
        &:hover
          text-decoration: underline
      cursor: pointer

  .place-cell-actions
    .ui-button
      width: 4rem
      padding: 0 0.8rem
      svg
        width: 1.6rem
        height: 1.6rem
        path
          fill: $white

.company
  padding: 2.4rem 3rem
  .title
    font-size: 1.8rem
