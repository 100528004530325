@import 'src/sass/_variables'

.routes-events

  .container
    margin: 2rem 4.4rem 4.8rem 3.2rem
    .ui-module-header
      .ui-table-toolbar
        margin-left: auto
      .btn-create-manual-case
        margin-left: 0.8rem

  .status-buttons
    display: flex
    align-items: center
    justify-content: flex-start
    margin-bottom: 2.4rem
    .ui-button
      margin-right: 1.2rem
      &.btn-badge
        height: 3rem
        background-color: $sky-lighter
        padding: 0.4rem 0.8rem
        border-radius: 0.6rem
        box-shadow: 0 0.1rem 0.4rem 0 transparentize($black, 0.6)
        &.active
          background: $rombit-blue-300
        > div
          display: flex
          align-items: center
          justify-content: space-between
          font-size: 1.4rem
          font-weight: $fw-medium
          line-height: 0
          color: $ink-light
        .ui-badge
          margin-left: 0.4rem

  // Events table
  .cases-table,
  .events-table
    .event-attached
      > div
        line-height: 0
      .icons-clipboard
        width: 1.6rem
        height: 1.6rem

  // Events table
  .events-table
    .ui-table
      .event-trigger-type
        display: flex
        align-items: center
        width: 100%
        .ui-icon-box
          width: 3.6rem
          height: 3.6rem
          > div
            width: 3.6rem
            height: 3.6rem

      // column priority
      .ui-priority
        width: 0.6rem
        max-width: unset
        height: 4.7rem
        margin-right: 0.8rem
        margin-left: -1.5rem // to be aligned with table start
        &.critical
          background-color: $red
        &.high
          background-color: $orange
        &.medium
          background-color: $rombit-blue-700
        &.low
          background-color: $ink-lighter
      // column type
      .events-type
        > div
          display: flex
        div:nth-child(1)
          font-size: 1.4rem
        div:nth-child(2)
          display: flex
          font-size: 1.2rem
          font-weight: $fw-regular
          line-height: 1.6rem
          color: $ink-light
          .wrapped-text
            +browser-only-max-zoom-width(1366px)
              max-width: 18rem
      // column thread
      .events-threads
        display: flex
        align-items: center
        margin-left: 0.8rem
        > span
          margin-left: 0.8rem
          color: $ink-light
        > div
          svg
            path
              fill: $ink

  .event-profile
    display: flex
    align-items: center
    width: 100%
    margin-right: 0.8rem
    > div:nth-child(2)
      > span
        display: block
        margin-right: 1.6rem
        +ellipsis
    > div:nth-child(1)
      display: flex
      align-items: center
      justify-content: center
      flex-shrink: 0
      width: 2.4rem
      height: 2.4rem
      background-color: $sky
      margin-right: 0.5rem
      border: 0.1rem solid $white
      border-radius: 50%
      svg
        width: 1.6rem
        height: 1.6rem
    &.with-tooltip
      > div:nth-child(2)
        width: 100%

  .create-case-modal,
  .create-accident-modal
    .select-rule-assignees
      width: 100%
      label
        margin-bottom: 0
    .tip
      width: 100%
      min-height: 1.8rem
      font-size: 1.2rem
      font-style: italic
      text-align: left
      line-height: 1.8rem
      color: $ink-lightest

    textarea
      height: 4.8rem
      padding: 0.8rem
      border-color: $input-border-color
      border-radius: $input-border-radius
      font-family: $source-sans-pro
      font-size: 1.4rem
      line-height: 1.6rem
      color: $input-font-color
      &:hover
        border-color: $input-border-color-hover

  .events-blank-state
    &.cases
      .blank-state
        height: calc(100vh - 23.2rem)
        .ui-title
          h2
            white-space: normal


  // COMMON
  // ======

  .case-detail,
  .event-detail
    .ui-priority
      display: flex
      align-items: center
      justify-content: flex-start
      flex-flow: row nowrap
      > div
        height: 2.4rem
        padding: 0 1rem 0 0.8rem
        border-radius: 1.2rem
        &:empty
          display: none
        [class^="icons-"]
          flex-shrink: 0
          width: 1.6rem
          height: 1.6rem
          margin-right: 0.4rem
          line-height: 0
        > span
          font-size: 1.2rem
          line-height: 1.6rem
          color: currentColor
      .critical
        +ui-priority-colors($red-lighter, $red-darker, $red)
      .high
        +ui-priority-colors($orange-lighter, $orange-darker, $orange)
      .medium
        +ui-priority-colors($rombit-blue-100, $rombit-blue-800, $rombit-blue-700)
      .low
        +ui-priority-colors($sky, $ink-lighter, $ink-lighter)

    .button-bar
      display: flex
      .btn-icon
        width: 3.2rem
        height: 3.2rem
        background-color: transparent
        padding: 0
        margin: 0 0 0 0.4rem
        &:hover
          background-color: $sky
        &:active
          background-color: $sky-dark

        &.disabled
          [class^="icons-"]
            path
              fill: $sky-dark
        > div
          line-height: 0
          [class^="icons-"]
            width: 2.4rem
            height: 2.4rem

          .icons-close
            width: 2rem
            height: 2rem

    .activity-container
      display: flex
      flex-flow: row nowrap
      width: 100%
      margin-bottom: 1.6rem
      > div:first-child
        margin-top: 0.8rem
        margin-right: 0.8rem
        [class^="icons-"]
          width: 2.4rem
          height: 2.4rem
        &.filled
          .icons-lightning
            path
              fill: $orange
          .icons-mail,
          .icons-phone,
          .icons-sms,
          .icons-whatsapp
            path
              fill: $green
        .icons-reports
          path
            fill: $rombit-blue-600

        .icons-ms-teams
          path
            fill: #464EB8
      img
        width: 2.4rem
        height: 2.4rem
      .file
        display: flex
        align-items: center
        flex-grow: 1
        max-width: 24rem
        margin-right: 0.8rem
        font-weight: $fw-bold
        color: $ink-light
        .icons-file
          width: 1.6rem
          height: 1.6rem
        .ui-url
          margin-left: 0.4rem
          span
            max-width: 16rem
      .text
        display: flex
        flex-flow: column nowrap
        > .activity-action
          display: inline-block
          width: fit-content
          min-height: 3.8rem
          padding: 0.8rem 1.6rem
          margin-bottom: 0.4rem
          border: 0.1rem solid $sky-dark
          border-radius: 0.8rem
          font-size: 1.4rem
          line-height: 2rem
          color: $ink
          > svg
            margin-left: 0.8rem
          .icons-circle-filled-check
            path:first-child
              fill: $green
            path:nth-child(2)
              fill: $white
          .icons-circle-filled-cross
            path:first-child
              fill: $red
            path:nth-child(2)
              fill: $white
          .status-pill
            background-color: $ink-light
            padding: 0.4rem 1.2rem
            margin-left: 0.4rem
            border-radius: 1.6rem
            color: $white
            &.open
              background: $rombit-blue-700
            &.progress
              background: $orange
            &.resolved
              background: $green
          &:empty
            height: 3.8rem
        > span
          margin-left: 0.8rem
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-lighter

    .container-files
      display: flex
      align-items: center
      justify-content: left
      flex-direction: column
      width: 100%
      .files-wrapper
        position: relative
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-gap: 0.8rem
        width: 100%
        margin-top: 1rem
        .ui-file-panel:nth-child(3n)
          .panel-options
            >.ui-confirm-dialog
              right: 2rem

      // Dropzone styles => move to UI
      .ui-input.upload
        display: flex
        flex-direction: column
        width: 100%
        cursor: pointer
        border: 0.1rem dashed $sky-dark
        .errors
          > span
            text-align: center
        .dropzone
          display: flex
          align-items: center
          justify-content: center
          flex-direction: column
          padding: 3rem
          .input-container
            order: 2
            label
              background: transparent
              span
                display: flex
                align-items: center
                padding-right: 1.2rem
                white-space: nowrap
                .icons-plus
                  margin-right: 0.8rem
                  path
                    fill: $rombit-blue-600
          .blank-state
            margin-bottom: 1.6rem
            .icon
              margin-bottom: 0
              svg
                width: 5.6rem
                height: 5.6rem
          .helper-text
            order: 3
            font-size: 1.2rem
            line-height: 1.6rem
            color: $ink-lighter

    .collapsable-box
      display: grid
      grid-template-columns: 40% 36% 14% 10%
      height: 3rem
      padding: 0 2.4rem 0 0
      margin-bottom: 0.8rem
      // icon + user name
      > div
        display: flex
        align-items: center
        justify-content: flex-start
        > span
          +ellipsis
          margin: 0 1.6rem 0 0.8rem
          font-weight: $fw-regular
          line-height: 1.6rem
          color: $ink
        .event-profile
          span
            max-width: 24.8rem
        &:not(:first-child)
          color: $ink-lighter
      // group
      > div:nth-child(2)
        display: block
        line-height: 3rem
        +ellipsis
      // subtype
      > div:nth-child(3)
        display: block
        line-height: 3rem
        +ellipsis(10rem)
      // entrance
      > div:nth-child(4)
        margin-left: auto
    .pills-block
      &:last-child
        margin-right: 0
      &.with-button
        .block-label
          display: flex
          align-items: center
          span
            margin-left: 0.3rem
          .ui-button
            height: 1.6rem
            padding: 0 0.4rem
      .block-content
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 2rem
        color: $ink
        &.with-pills
          display: flex
          align-items: flex-start
          flex-flow: row wrap
          .ui-pill
            margin: 0 0.8rem 0.8rem 0

  .event-case
    display: flex
    align-items: center
    width: 100%
    .ui-icon-box
      width: 3.6rem
      height: 3.6rem
      > div
        width: 3.6rem
        height: 3.6rem
        background: $rombit-blue-100
        border-radius: 50%
    .icons-reports
      path
        fill: $rombit-blue-600
