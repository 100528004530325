@import 'src/sass/_variables'

.routes-items
  .ui-side-panel
    > div
      overflow: hidden scroll
  .ui-input
    flex-direction: column
    input
      width: 100%
