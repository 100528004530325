@import 'src/sass/_variables'

.routes-home
  height: calc(100vh - #{$topbar-height})

  .container
    display: grid
    grid-template-columns: 1fr 38.4rem
    height: 100%
    max-height: calc(100vh - #{$topbar-height})
    overflow: auto
    > div
      // Real time overview
      &:first-child
        background-color: $sky-light
        padding: 3.2rem 4.8rem 4.8rem

      // Events and review
      &:last-child
        background-color: $sky-lighter
        padding: 3.2rem 2.4rem
      > .ui-title
        margin-bottom: 2.4rem
        h2
          color: $ink

      .top-row
        display: grid
        grid-template-columns: 1fr 17.7rem
        grid-gap: 2.4rem
        margin-bottom: 2.4rem

      .bottom-row
        display: grid
        grid-template-columns: repeat(3, minmax(0, 1fr))
        grid-column-gap: 2.4rem

      .week-bar
        position: relative
        display: flex
        justify-content: flex-end
        flex-direction: column
        min-height: 3rem
        padding: 0 0.4rem
        .bar
          position: absolute
          top: 50%
          width: 100%
          border-bottom: 0.1rem dashed $sky
        .days
          display: flex
          align-content: flex-end
          justify-content: space-between
          max-width: 13.8rem
          margin: 0 0.4rem
          > div
            align-self: flex-end
            width: 1.2rem
            background-color: $sky
            border-top-left-radius: 0.2rem
            border-top-right-radius: 0.2rem
            &:last-child
              height: 2rem
              background-color: $ink-lightest
            &:hover
              background-color: $green
              cursor: pointer

      .box-icon
        display: flex
        align-items: center
        justify-content: center
        flex-shrink: 0
        width: 4rem
        height: 4rem
        border-radius: 0.4rem
        svg
          width: 2.4rem
          height: 2.4rem

      .historic
        color: $ink
        > div
          padding: 1.6rem
          > p
            margin-bottom: 1.6rem
            font-size: 1.2rem
            font-weight: $fw-regular
            line-height: 1.6rem
          &:first-child
            border-bottom-left-radius: 0.8rem
          &:last-child
            border-bottom-right-radius: 0.8rem

    .aside
      > .ui-title
        justify-content: flex-start
        margin-bottom: 1.4rem

        .ui-badge
          margin-left: 0.4rem
        .ui-button
          margin-left: auto

    .tags-chart,
    .infrastructure-status,
    .docking-stations
      > section
        min-height: 19.4rem
      .empty-state
        padding: 3.4rem 1.6rem
        div
          display: flex
          align-items: center
          justify-content: center
          flex-direction: column
          height: 16.2rem
          background-color: $sky-lighter
          padding: 2.4rem 1.6rem
          border-radius: 0.8rem
          .icons-excellent-signal
            width: 3.2rem
            height: 3.2rem
            path
              fill: $sky-dark
        p
          font-size: 1.2rem
          font-weight: $fw-regular
          text-align: center
          line-height: 1.6rem
          color: $ink
          &:first-of-type
            margin-top: 1.6rem
            font-weight: $fw-bold
    .last-updated
      display: flex
      margin-top: 1.6rem
      font-size: 1.2rem
      color: $ink
      .ui-button.link
        height: 1.8rem
        padding: 0 0 0 0.4rem

    .helper-text
      font-size: 1rem
      color: $ink-lightest
      font-style: italic
