@import src/sass/_variables

.routes-worker-licenses
  .ui-resume-bar
    justify-content: space-between
    .values-box
      +browser-min-zoom-width(1260px)
        min-width: 16rem
      +browser-min-zoom-width(1440px)
        min-width: 18rem
      +browser-min-zoom-width(1500px)
        min-width: 22rem
      // icon or image
      &:nth-child(1)
        min-width: 12rem
        max-width: 12rem
        margin-left: 0
      // just before the pie chart
      &:nth-child(5)
        min-width: 18rem
        margin-right: 0
        border-right: 0
      &:nth-child(6)
        border-right: 0
    .quantity
      margin-bottom: 0.8rem

    .pie-section
      .items-pie-chart
        display: flex
        align-items: center
        flex-direction: row
        &:not(.blank-state)
          > div:nth-child(2),
          > div:nth-child(3)
            display: flex
            justify-content: space-between
            flex-direction: column
            height: 6.4rem
            margin-left: 1.6rem
            > div
              display: flex
              align-items: center
              width: 10.4rem
              > div:first-child
                flex-shrink: 0
                width: 1.6rem
                height: 1.6rem
              > span
                opacity: 0.6
                margin-left: 0.8rem
                font-size: 1.2rem
                color: $ink-light

            > div:first-child
              > div:first-child
                background-color: $teal
            > div:nth-child(2)
              > div:first-child
                background-color: $magenta
            > div:nth-child(3)
              > div:first-child
                background-color: $rombit-blue-900

    .assigned-licenses
      .ui-button
        height: 2rem
        padding: 0
        > div
          width: 100%
          text-align: left
