@import 'src/sass/_variables'

.contractors-view
  .blank-state
    height: calc(100vh - 35.4rem)
  .ui-resume-bar
    justify-content: space-between
    & + .ui-table
      .ui-table-component
        .rdt_TableBody
          max-height: calc(100vh - 45rem)

    .values-box
      .resume-section
        &.with-icon > div
          background-color: $rombit-blue-100
          svg path
            fill: $rombit-blue-700
        .quantity
          margin-left: 1.6rem
      &:nth-child(1)
        flex-grow: 0
        min-width: 12rem
        max-width: 12rem
        margin-left: 0
        .icon-wrapper
          background-color: transparent
      &:nth-child(3)
        flex-grow: 0.4
      &:nth-child(5)
        flex-grow: 0.1

      &.legals
        > div
          display: flex
          align-items: flex-start
          flex-direction: column
          margin-bottom: 0.8rem
          h3
            margin-bottom: 0
          p
            font-size: 1.2rem
            line-height: 2rem
            color: $ink

    // To mimic placeholder styles
    .icon-wrapper
      &.uses-placeholder
        .ui-avatar
          display: flex
          align-items: center
          justify-content: center
          width: 8rem
          height: 8rem
          background-color: $rombit-blue-300
          border-radius: 0.8rem
        img[src="/contractor.svg"]
          width: 4.8rem
          height: 4.8rem
          border-radius: 0

    // Edit button
    .action
      position: relative
      top: -0.4rem
      right: -0.4rem
      display: flex
      align-items: center
      justify-content: center
      width: 3.2rem
      height: 3.2rem
      &:hover
        background-color: $sky
        cursor: pointer
        border-radius: 0.8rem
