@import 'src/sass/_variables'

.events-priorities
  display: flex
  flex-direction: column
  margin-bottom: 2.4rem

  .priority-card
    +dashboard-card
    display: flex
    justify-content: flex-start
    padding: 1.5rem
    margin-bottom: 0.8rem
    border: 0.1rem solid $white
    box-shadow: 0 0 0.4rem 0 transparentize($black, 0.75)
    &:hover
      cursor: pointer
      .ui-title
        text-decoration: underline
        color: $ink
    &:last-child
      margin-bottom: 0
    > div:nth-child(2)
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      .ui-title
        h2
          color: $ink
      p
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 2rem
        color: $ink

    span:last-child
      display: flex
      align-items: center
      justify-content: center
      margin-left: auto
      font-size: 1.6rem
      font-weight: $fw-bold
      line-height: 2rem
    .box-icon
      margin: 0 0.8rem 0 0

    &.critical
      +event-card($red, $red-lighter)
    &.high
      +event-card($orange, $orange-lighter)
    &.medium
      +event-card($rombit-blue-600, $rombit-blue-100)
    &.low
      +event-card($ink-lighter, $sky-light)
