@import 'src/sass/_variables'

.dashboard
  display: flex
  flex-direction: row
  // NOT SIDEBAR
  > div:nth-child(2)
    height: 100vh
    background-color: $sky-light
  .page
    width: 100%
    max-width: calc(100% - 5.6rem) // sidebar width
    margin-left: 5.6rem
    // To set Toast over Filter button in MapV2
    + .ui-toast
      z-index: 6
  .ui-sidebar
    position: fixed
    .icons-exit-full-screen,
    .icons-map,
    .icons-device-ok,
    .icons-warning
      width: 2.4rem
      height: 2.4rem
    .icon-notification
      position: relative
      .notification
        position: absolute
        z-index: 1
        top: 1.5rem
        left: 1.5rem
        min-width: 1.5rem
        min-height: 1rem
        background-color: $teal
        padding: 0.2rem
        border-radius: 0.4rem
        font-size: 0.7rem
        text-align: center
        line-height: 0.7rem

  // Socket warning notification active
  &.with-bar
    .ui-sidebar
      .places
        margin-bottom: 7rem
    .ui-side-panel.right
      top: $topbar-height + 3rem
      > div:first-child
        height: calc(100vh - 9.4rem)
      &.requests-resolve
        top: 3rem
        > div:first-child
          height: calc(100vh)

    .toast-wrapper
      .ui-toast
        margin-top: 3rem
    .routes-events
      .events-resolve,
      .backdrop
        top: 3rem
    .routes-map
      .live-map.evacuation
        .map-satellite
          .google-maps
            height: calc(100% - 7.6rem)
      .ui-accordion
        bottom: 3rem
      .time-machine
        .statistics-satellite
          .statistics-map-controls
            top: -7.4rem
    .map-v2-main-wrapper
      .ui-accordion
        bottom: 9.4rem
    .ui-table
      .rdt_TableBody
        max-height: calc(100vh - 30.4rem)
  .toast
    a
      font-weight: $fw-regular
      text-decoration: underline
    &:hover
      cursor: pointer
    p
      margin-bottom: 0.8rem
  .suspended-account
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    width: 100%
    height: 100vh
    background-color: $sky-light
    padding: 0.8rem 0 0
    text-align: center
    .graphs-error-fence
      margin-bottom: 2.4rem
    .ui-title
      width: initial
      margin-bottom: 2.4rem
    p
      max-width: 45rem
      margin-bottom: 2.4rem
      font-size: 1.6rem
      font-weight: $fw-regular
      color: $ink-light
  &.is-modal-open
    .map-topbar
      z-index: 2
  .notification-modal
    .modal
      border-top: 0.8rem solid $red

.rule-notification
  > header
    display: flex
    align-items: center
    justify-content: flex-start
    margin-bottom: 1.6rem
    > svg
      flex-shrink: 0
      margin-right: 1.6rem
      path
        fill: $white
    > span
      font-size: 2rem
      font-weight: $fw-semibold
      line-height: 2rem
      color: $white
  p
    margin-bottom: 1.6rem
    font-weight: $fw-regular
    strong
      font-weight: $fw-semibold

.click-view-more
  display: inline-flex
  align-items: center
  justify-content: flex-start
  &:hover
    cursor: pointer
    > span
      text-decoration: underline
  > span
    margin-right: 0.8rem
    font-size: 1.6rem
    font-weight: $fw-semibold
    line-height: 2.4rem
  > svg
    path
      fill: $white

.full-page
  transition: margin 0.6s
  width: 100%
  max-width: 100%
  margin-left: 0

.sticky-evacuation
  .ui-button
    width: 4rem
    height: 4rem
    padding: 0
    border-radius: 0.8rem
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    box-shadow: 0 0.1rem 0.4rem 0 transparentize($black, 0.75)
    &:hover
      right: 0
    > div
      display: flex
      align-items: center
    svg
      margin: 0

.notification-export
  background-color: $rombit-blue-700
  color: $white
  &.top
    top: 3rem
  .notification-text
    display: flex
    .icons-load-spinner
      animation: spin 1s linear infinite
      margin-right: 1rem

@keyframes spin
  100%
    transform: rotate(360deg)

// Make sure that the abort export modal covers the sidebar so buttons aren't clickable
.modal-export
  .overlay
    z-index: 6
  .modal
    z-index: 7

.ui-navigation-search-wrapper
  z-index: 7 // over modal

.ui-navigation-search
  width: 30vw
  min-width: 35rem
  min-height: 10vh
  background: $white
  overflow: auto
  border-radius: 1rem
  box-shadow: 0.1rem 0.2rem 1.4rem 0.3rem rgba(0, 0, 0, 0.2)
  input
    box-sizing: border-box
    width: 100%
    padding: 1.2rem 1.6rem
    border: none
    outline: none
    font-size: 1.6rem

.ui-navigation-search-item
  display: flex
  align-items: center
  justify-content: flex-start
  height: 3rem
  background: transparent
  cursor: pointer
  padding: 0 1.6rem
  > div
    line-height: 0
  svg
    width: 1.6rem
    height: 1.6rem
    margin-right: 1.4rem
  p
    margin: 0
    font-size: 1.4rem
  &.active
    background: $sky-light

.mapv2-migration-banner
  .ui-notification-bar
    .custom-message
      margin-right: 1rem

    .custom-link
      margin-left: 0

    .icons-map
      path
        fill: $white
