@import 'src/sass/_variables'

.ui-button
  position: relative
  display: flex
  justify-content: center
  transition: background 0.1s linear
  cursor: pointer
  padding: 0.7rem 1.8rem
  border: none
  border-radius: 0.4rem
  font-size: 1.4rem
  font-weight: $fw-regular
  text-align: center
  > div
    width: max-content
    font-family: $source-sans-pro
  &.default
    background: $rombit-blue-600
    color: $white
    &:hover
      background-color: $rombit-blue-700
      color: $white
    &:active
      background-color: $rombit-blue-700
      color: $white
    &.secondary
      background: $rombit-blue-200
      color: $rombit-blue-600
      &:hover
        background-color: $rombit-blue-200

  &.smaller
    align-items: center
    height: 2.8rem
    padding: 0 1.2rem
    > div
      font-size: 1.2rem
      font-weight: $fw-semibold
      line-height: 1.6rem
  &.small
    align-items: center
    height: 3.2rem
    padding: 0 1.2rem
    > div
      font-size: 1.4rem
      font-weight: $fw-regular
      line-height: 2rem

  &.medium
    align-items: center
    height: 4rem
    padding: 0 2.4rem
    > div
      font-size: 1.4rem
      font-weight: $fw-regular
      line-height: 2rem
  &.large
    align-items: center
    height: 4.8rem
    padding: 0 2.4rem
    > div
      font-size: 1.6rem
      font-weight: $fw-regular
      line-height: 2.4rem

  &.gmaps
    background-color: $white
    padding: 0 1.2rem
    box-shadow: 0 0 0.8rem transparentize($ink, 0.7 )
    > div
      display: flex
      align-items: center
      font-weight: $fw-regular
      line-height: 0
      color: $ink-lighter
      svg
        margin-right: 0.4rem
        path
          fill: $ink-lighter
    &:hover
      background-color: $sky-lighter
      > div
        color: $ink-light
      svg
        path
          fill: $ink-light
    &:active
      > div
        color: $ink
      svg
        path
          fill: $ink
  &.table
    height: 3.2rem
    background-color: inherit
    padding: 0 1.2rem
    border-radius: 0.8rem
    .icons-go-to
      display: none
    > div
      display: flex
      align-items: center
      font-size: 1.4rem
      font-weight: $fw-regular
      color: $black
    &:hover
      background-color: $sky
      margin-left: -0.1rem
      border: 0.1rem solid $ink-lightest
      .icons-go-to
        display: block
        width: 1.6rem
        height: 1.6rem
        margin-left: 1rem
        path
          fill: $ink
    &:active
      background-color: $ink-lightest

  &.light
    background-color: transparent
    outline: 0.1rem solid $rombit-blue-600
    color: $rombit-blue-600
    > div
      display: flex
      align-items: center
      svg
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem
        path
          fill: $rombit-blue-600
    &:disabled,
    &.disabled
      outline: none
    &:hover
      color: $rombit-blue-700
      outline-color: $rombit-blue-700
    &.disabled
      outline: none

  &.outline
    background-color: transparent
    border: 0.1rem solid $ink
    color: $ink
  &.approve
    background-color: $green
    color: $white
    svg
      margin-right: 0.4rem
      path
        fill: $white
    &:hover
      background-color: $green-darker
  &.danger
    background-color: $red
    color: $white
    svg
      margin-right: 0.4rem
      path
        fill: $white
    &:hover
      background-color: $red-darker
    > div
      display: flex
      align-items: center

  &.inline
    background-color: $white
    border: none
    color: $rombit-blue-600
  &.white
    background-color: $white
    padding: 0 1.2rem
    border: 0.1rem solid $sky-dark
    border-radius: 0.4rem
    > div
      display: flex
      align-items: center
      font-weight: $fw-regular
      color: $ink-lighter
      svg
        margin-right: 0.4rem
        path
          fill: $ink-lighter
    &:hover
      border: 0.1rem solid $ink-lightest
    &.disabled
      background-color: $sky-dark
      border: 0.1rem solid transparent
      font-weight: $fw-regular
      > div
        color: $white

  &.back
    background-color: transparent
    padding: 0.8rem 1.6rem
    border: 0.1rem solid transparent
    border-radius: 0.4rem
    > div
      display: flex
      align-items: center
      font-weight: $fw-regular
      color: $rombit-blue-600
      svg
        margin-right: 0.8rem
        path
          fill: $rombit-blue-600
    &:hover
      > div
        color: $rombit-blue-700
      .icons-arrow-left
        path
          fill: $rombit-blue-700
  &.link
    background-color: transparent
    padding: 0.8rem 1.6rem
    border: 0.1rem solid transparent
    border-radius: 0.4rem
    > div
      display: flex
      align-items: center
      font-weight: $fw-regular
      color: $rombit-blue-600

    &.disabled
      background-color: transparent
      border: none
      font-weight: $fw-regular
      user-select: none
      > div
        color: $ink-lightest
    &:hover
      > div
        color: $rombit-blue-700
  &.icon
    height: initial
    background: transparent
    padding: 0
    border-radius: 0
    &:hover
      svg
        path
          fill: $ink
    > div
      line-height: 0
    &.disabled
      background: transparent
      cursor: not-allowed
      pointer-events: all

  &.spinner
    > div:first-child
      visibility: hidden
  &.disabled
    background-color: $sky-dark
    cursor: default
    border: none
    color: $white
    pointer-events: none
  &.secondary
    background-color: transparent
    font-weight: $fw-regular
    color: $rombit-blue-600
    &.disabled
      color: $sky-dark

  @keyframes spinner
    to
      transform: rotate(360deg)

  .spinner
    &:before
      content: ""
      position: absolute
      top: 50%
      left: 50%
      box-sizing: border-box
      width: 2.5rem
      height: 2.5rem
      margin-top: -1.2rem
      margin-left: -1.5rem
      border: 0.4rem solid $sky
      border-radius: 50%
      border-top-color: $rombit-blue-600
      animation: spinner 0.6s linear infinite
