@import 'src/sass/_variables'

.infrastructure-status
  +dashboard-card

  // Layout
  .one-element
    display: flex
    justify-content: center
    width: 100%
    height: calc(100% - 3.8rem)
    min-height: 100%
    padding: 6.4rem 1.6rem
    > div
      display: flex
      align-items: center
      justify-content: center
      width: 100%
      .ui-title
        justify-content: center
  .two-elements
    display: flex
    justify-content: space-around
    flex-flow: row nowrap
    height: calc(100% - 3.8rem)
    min-height: 100%
    padding: 6.4rem 1.6rem
    > div
      flex: 1 1 50%
      &:first-child
        margin-right: 0.8rem
      &:last-child
        margin-bottom: 0.8rem
        margin-left: 0.8rem
      &.infra-device
        .ui-title
          max-width: 8rem
          h2
            white-space: wrap

  .three-elements
    display: flex
    justify-content: space-around
    flex-flow: column nowrap
    height: calc(100% - 3.8rem)
    min-height: 100%
    padding: 1.6rem
    .infra-device
      position: relative
      align-items: center
      justify-content: flex-start
      flex-direction: row
      padding: 1.6rem 0.8rem
      .ui-title
        position: absolute
        top: 1rem
        flex-basis: 0
        margin-right: 0.8rem
        order: 1
        margin-bottom: 0
      p:nth-child(3),
      p:nth-child(4)
        position: relative
        top: 0.8rem
      p:nth-child(3)
        white-space: nowrap
        margin-right: 0.4rem
        order: 2
      p:nth-child(4)
        white-space: nowrap
        order: 3
      svg
        margin-bottom: 0
        margin-left: auto
        order: 4

  // Card
  .infra-device
    display: flex
    align-items: flex-start
    justify-content: center
    flex-direction: column
    padding: 1.6rem
    margin-bottom: 0.8rem
    border: 0.1rem solid $sky
    border-radius: 0.8rem
    &:hover
      cursor: pointer
      border-color: $ink
      .ui-title h2
        text-decoration: underline
    &:last-child
      margin-bottom: 0

    svg
      flex-shrink: 0
      margin-bottom: 0.8rem
    .icons-circle-filled-check
      path:first-child
        fill: $green
      path:nth-child(2)
        fill: $white
    .icons-warning
      width: 2.4rem
      height: 2.4rem
      path
        fill: $red
    .ui-title
      margin-bottom: 1.6rem
    p
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 1.6rem
      color: $ink-lighter
      &.units
        text-decoration: underline
        &.stable
          color: $green
        &.issues,
        &.outrage
          color: $red
