@import 'src/sass/_variables'

.select-common
  > label
    +common-label

// Select single
// =============
.ui-select-single
  min-width: 100%
  .select-single
    &__placeholder
      font-size: 1.4rem
      color: $input-placeholder-color
    &__control
      min-height: 3.6rem
      border-color: $input-border-color
      &--is-focused
        border-color: $input-outline-color
        box-shadow: 0 0 0 0.1rem $input-outline-color inset
        &:hover
          border-color: $input-outline-color
      &:focus
        box-shadow: 0 0 0 0.1rem $input-outline-color inset
      &:hover:not(&--is-focused)
        border-color: $input-border-color-hover

    &__value-container
      height: 2.4rem
      padding-top: 0
      padding-bottom: 0
    &__single-value
      font-size: 1.4rem
      color: $input-font-color
    &__single-value--is-disabled
      color: $ink-lightest
    &__indicator-separator
      display: none
    &__indicator
      height: 3.2rem
      padding-top: 0.6rem
      border-radius: 0.3rem
      svg
        fill: $ink

    // List
    &__menu
      margin-top: 0.1rem
      text-align: left

      // Vitualized list
      // ===========
      .react-virtualized-list-wrapper li
        list-style: none
        & > div
          height: 4rem
        &:hover
          background-color: $sky-light
          cursor: pointer
          &:active
            background-color: $sky
            color: $ink
        .select-options
          &.asset-text-check
            > span:nth-child(2)
              line-height: 2.4rem
              +ellipsis(24rem)

    &__option--is-focused
      background-color: $sky-light
      color: $ink-light
      &:active
        background-color: $sky
    &__option--is-selected
      background-color: $sky
      color: $ink-light

    &__option
      font-size: 1.4rem
      color: $ink
      &:hover
        cursor: pointer
      .select-options
        [class^="icons-"]
          width: 2.4rem
          height: 2.4rem
          text-align: left
          line-height: 0
          &.icons-warning
            width: 2.4rem
            height: 2.4rem
            path
              fill: $red
          &.icons-arrow-up
            path
              fill: $orange
          &.icons-arrow-right
            path
              fill: $rombit-blue-700
          &.icons-arrow-down
            path
              fill: $ink-lighter
        // Priority-icon, text, check
        // Avatar, text, check
        &.asset-text-check
          display: flex
          align-items: center
          justify-content: flex-start
          width: 100%
          > span:first-child
            width: 2.4rem
            margin-right: 0.8rem
            line-height: 0
          > span:nth-child(2)
            display: flex
            align-items: center
            width: fit-content
            min-height: 2.4rem
            margin-left: 0
            text-align: left
            line-height: 1.6rem
          > span:last-child
            width: 2.4rem
            height: 2.4rem
            margin-left: auto
          &.single-line
            > span:nth-child(2)
              line-height: 2.4rem

        // span with text, check
        &.text-with-check
          display: flex
          align-items: center
          justify-content: flex-start
          width: 100%
          height: 2.4rem
          > span:first-child
            width: fit-content
            text-align: left
            line-height: 1.6rem
          > span:last-child
            width: 2.4rem
            margin-left: auto

        &.status-item-options
          display: flex
          align-items: center
          justify-content: flex-start
          width: 100%
          > span:first-child
            width: 1rem
            height: 1rem
            margin-right: 1.4rem
            border-radius: 0.4rem
            &.open
              background: $rombit-blue-700
            &.progress
              background: $orange
            &.resolved
              background: $green
          > span:nth-child(2)
            width: fit-content
            text-align: left
            line-height: 2.4rem
          > span:last-child
            width: 2.4rem
            margin-right: 0
            margin-left: auto
  &.small
    .select-single
      &__control
        height: 3.2rem
        min-height: 3.2rem
        font-size: 1.4rem
        line-height: 1.6rem
      &__value-container
          height: 3rem
      &__indicator
        align-items: center
        height: 3rem
        padding: 0 0.8rem

// Multi select
// =============
.ui-select-multi
  font-size: 1.4rem
  .select-multi
    &__control
      min-height: 3.6rem
      border-color: $input-border-color
      &--is-focused
        border-color: $input-outline-color
        box-shadow: 0 0 0 0.1rem $input-outline-color inset
        &:hover
          border-color: $input-outline-color
      &:focus
        box-shadow: 0 0 0 0.1rem $input-outline-color inset
      &:hover:not(&--is-focused)
        border-color: $input-border-color-hover

    &__multi-value,
    &__multi-value__label,
    &__multi-value__remove
      width: auto
      height: 2.4rem
    &__multi-value
      align-items: center
      width: fit-content
      &__label
        display: flex
        align-items: center
        padding: 0 0.8rem
        font-size: 1.2rem
        line-height: 1.2rem
        color: $ink-light
      &__remove
        cursor: pointer
        svg
          path
            fill: $ink-light

    &__input
      height: 3.4rem
      line-height: 3.4rem
      color: $input-placeholder-color
    &__placeholder
      height: 3.4rem
      line-height: 3.4rem
      color: $input-placeholder-color

    &__menu,
    &__menu-list
      min-height: 5rem
      margin-top: 0.1rem
      .assignee
        display: flex
        align-items: center
        img
          width: 2.4rem
          height: 2.4rem
          max-height: 2.4rem
          margin-right: 0.8rem
          border-radius: 50%
          overflow: hidden
        span
          line-height: 1.6rem
          color: $ink

    &__value-container
      &--is-multi
        padding: 0 0.8rem
        // cursor
        > div:only-child,
        > div:last-child
          height: 3.4rem
          padding-top: 0
          padding-bottom: 0
          margin-top: 0
          margin-bottom: 0
        .ui-select-multi__input
          height: 3.4rem
          > input
            height: 3.6rem
            background-color: transparentize($sky, 0.2)

    // Buttons
    &__indicator
      height: 3.4rem
      svg
        path
          fill: $ink-light
    &__indicators
      width: auto

.react-virtualized-menu-placeholder
  margin-top: 1.2rem
  text-align: center
