@import 'src/sass/_variables'

.certificate-updates
  .attachment
    > div
      display: flex
      align-items: center
      justify-content: space-between
      margin: 1rem 0
      [class^="icons-"]
        svg
          path
            fill: $red

  .ui-side-panel-row
    // side panel does not allow overflow content
    // so I added extra space for the picker
    &.timestamp
      margin-top: 3.6rem
    .ui-single-date-picker
      .SingleDatePicker
        .SingleDatePicker_picker,
        .DateInput_fang
          margin-top: 0
          margin-bottom: -1.6rem
