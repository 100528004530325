@import 'src/sass/_variables'

.rules
  .rules-triggers,
  .trigger-actions-fields
    padding: 0
    > div[class$="-trigger"]
      margin-bottom: 0.8rem

    .trigger-helper
      margin-bottom: 1.6rem
      .helper-text
        font-size: 1.2rem
        font-weight: $fw-regular
        text-align: left
        line-height: 1.4rem
        color: $ink-lighter
      .ui-link
        display: inline
        margin-left: 0.2rem
        border: none
        font-size: 1.2rem
        font-weight: 700
        text-align: left
        line-height: 1.4rem
        color: $rombit-blue-600
        &:hover
          cursor: pointer
          text-decoration: underline

    .ui-select-items
      margin-bottom: 1.6rem
    .ui-select-frequencies
      > .ui-select-single
        width: 25rem

    .trigger-description
      display: block
      margin-bottom: 1.6rem
      font-size: 1.4rem
      line-height: 2rem
      color: $ink-light

    .devices-not-charging-trigger
      .count-container
        display: flex
        align-items: center
        margin-bottom: 1.6rem
        .ui-input
          margin-bottom: 0
          label
            margin-bottom: 0
          input
            width: 12.4rem
        .untrve-checkbox
          display: flex
          align-items: center
          margin-top: 3rem
          margin-left: 1.6rem
          label
            margin-bottom: 0

    .certificate-expiration-trigger
      .ui-select-reminder
        margin-bottom: 1.6rem
      .ui-input:nth-child(2)
        width: 18rem

    .evacuation-trigger
      .ui-row
        margin-bottom: 1.6rem
        &:last-child
          margin-bottom: 0
      .radio-box
        font-size: 1.4rem
        > div:not(:first-child)
          margin-top: 0.8rem
        .radio
          width: 1.4rem
          height: 1.4rem
        .drill-helper
          padding-left: 2.2rem
          font-size: 1.4rem

      .initial-trigger,
      .end-trigger
        .trigger-helper
          margin: 0.8rem 0 0
        .select-multi__value-container
          .select-multi__multi-value:first-child
            pointer-events: none
            .select-multi__multi-value__remove
              display: none

      .ui-select-items
        margin-bottom: 0.4rem
      .evacuation-helper
        margin-bottom: 0.8rem
        font-size: 1.2rem
        line-height: 1.6rem
        color: $red-darker

    .ui-input-combo
      label
        line-height: 3rem

    .still-man-trigger,
    .altitude-trigger
      .ui-select-zones
        margin-top: 0
      p
        margin-top: 1.6rem
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 2rem
        color: $ink

      .ui-input-combo
        .row
          .ui-input
            margin-bottom: 0
            input[class]
              width: 17.5rem

      .helper
        display: flex
        align-items: center
        margin-top: -0.8rem
        svg
          width: 1.6rem
          height: 1.6rem
          margin-right: 0.4rem
        label
          font-size: 1.2rem
          font-weight: $fw-regular
          line-height: 1.6rem
          color: $ink

    .altitude-trigger
      .ui-input-number-row
        margin-bottom: 1.6rem
        input
          width: 28rem

    .zone-trigger,
    .stay-zone-trigger
      .interval-time
        font-size: 1.4rem
        color: $input-label-color
      .reset-counter
        label
          color: $ink-lighter

    .ui-select-zones
      + .trigger-helper
        margin-top: - 1.2rem


    .request-trigger
      .select-rule-members
        margin-bottom: 0.4rem
      .checkbox
        .ui-checkbox
          label
            > div
              line-height: 0

    .battery-trigger
      font-size: 1.4rem
      line-height: 1.6rem
      > .trigger-type
        margin: 2.4rem auto
        > .ui-radio-button
          margin-bottom: 1.4rem
          span
            color: $ink-light
      > span,
      > span strong
        color: $ink-light
    .spotter-detection-trigger
      .select-common
        margin-bottom: 1.6rem

  .ui-input
    .react-tel-input
      .form-control
        width: 100%
