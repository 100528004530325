@import 'src/sass/_variables'

.upload-place-avatar
  label
    font-weight: 700
  > div:nth-child(2)
    display: inline-flex
    align-items: center
    flex-direction: row
    width: 40rem
    margin-top: 1.6rem
    margin-bottom: 2.4rem
    .image
      width: 3.5rem
      height: 3.5rem
      img
        display: inline-block
        object-fit: cover
        width: 3.5rem
        height: 3.5rem
        border-radius: 50%
      .initials
        +initials
        margin-right: 0
    .delete
      cursor: pointer
      margin-left: 1rem
    .add-logo
      cursor: pointer
      margin-left: 2rem
      line-height: 1.6rem
      .input-container
        line-height: 1.6rem
        label
          padding-right: 1rem
        .file-value
          margin: 0
  .error
    margin: 1rem 0 0
    font-size: 1.2rem
    color: $red
