.indoor-region-locator
  .indoor-map-locator
    position: relative
    z-index: 4
    width: calc(100% - 36.8rem)
    height: 82vh
    margin: 1.6rem 0
    overflow: hidden

    .google-maps
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

    > img
      position: relative
      transform-origin: center
      z-index: 5
      top: 50%
      translate: 0% -50%
      display: block
      opacity: 0.75
      margin: auto

  .button
    position: absolute
    bottom: 0
