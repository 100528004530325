@import 'src/sass/_variables'
.ui-side-panel.edit-group
  .update
    .group-updates
      .title
        display: flex
        align-items: center
        > span
          min-height: 1.6rem
        > .ui-button
          padding: 0 2rem
      .header
        display: flex
        margin-bottom: 1.6rem
        .helper-text
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-lighter
        .count
          display: flex
          align-items: center
          justify-content: left
          flex-direction: column
          margin-right: 2.4rem
          > span:first-child
            font-size: 3.2rem
            font-weight: $fw-bold
            line-height: 4rem
        .group-icons
          display: flex
          align-items: center
          justify-content: left
          // temporary fix for icons
          min-height: 3.9rem
          padding-top: 0.4rem
          > div:first-child
            z-index: 1
          > div:last-child
            position: absolute
            left: 12rem
          > div:only-child
            position: relative
            left: 1.6rem
          svg
            circle
              stroke-width: 0.1rem
              stroke: $white
      .cards-container
        .selects-wrapper
          position: relative
          display: flex
          align-items: center
          justify-content: left
          margin-bottom: 1.6rem
        // TODO Update component and fix this code
        .ui-search-input
          left: -2.4rem
          min-width: 24.8rem
          max-width: 24.0rem
          margin-right: -1.6rem
        .ui-select-single
          width: 9.8rem
      .ui-toast
        z-index: 2
      .group
        height: fit-content
        padding: 0
        .buttons
          justify-content: flex-end
