@import 'src/sass/_variables'

.ui-bubbles
  display: flex
  flex-direction: row
  .bubble-profile
    display: flex
    align-items: center
    flex-direction: row
    width: 2.4rem
    height: 2.4rem
    margin-left: -1.2rem
    color: $ink-lighter
    &:first-child
      margin-left: 0
      .former-tip
        border: none
    &.last
      position: relative
      left: 1.6rem
      .former-tip
        background-color: transparent
        border: none
    .former-tip
      display: flex
      align-items: center
      justify-content: center
      width: 2.4rem
      height: 2.4rem
      background-color: $sky
      border: 0.1rem solid $white
      border-radius: 50%
      // icon container
      > div
        line-height: 0
        svg
          width: 1.6rem
          height: 1.6rem
      img
        object-fit: cover
        width: 2.4rem
        height: 2.4rem
        border-radius: 50%
      .extra-bubble
        width: 2.4rem
        background-color: transparent
        font-size: 1.4rem
        line-height: 1.6rem
        white-space: nowrap
        color: $ink
