@import 'src/sass/_variables'

.evacuation-modal
  .modal
    border: unset
    border-top: 0.8rem solid $red
    .icon.countdown
      span
        display: flex
        align-items: center
        justify-content: center
        width: 4.8rem
        height: 4.8rem
        font-size: 4rem
        font-weight: $fw-bold
        color: $red

  &.activate-drill
    .modal
      padding: 2rem 0 0
      > div
        padding: 0 2rem
      .actions
        justify-content: center
        margin-bottom: 2rem
      .extension
        display: flex
        justify-content: center
        padding: 0.8rem 0
        border-top: 0.1rem solid $sky-dark

  &.end
    .content
      > span
        margin-bottom: 1rem
      :nth-child(4)
        font-size: 1.2rem
        color: $ink-lighter
        > b
          margin-left: 0.5rem
      input
        width: 28rem

  &.waiting
    .modal
      width: 40rem
      .title
        display: flex
        justify-content: center
        font-size: 1.6rem
        font-weight: $fw-bold
        color: $red
