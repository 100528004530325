@import 'src/sass/_variables'

.routes-backoffice-items
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: calc(100vh - 15rem)
  padding: 1rem 2rem 2rem 1rem
  font-size: 1.8rem
  h4
    margin-bottom: 3rem
  .ui-button
    > div
      font-size: 1.4rem
      font-weight: $fw-regular
      color: $rombit-blue-600
      &:hover
        color: $rombit-blue-600
  .ui-input.upload
    flex-direction: column
    margin-bottom: 3rem
    .input-container
      display: flex
      align-items: center
      justify-content: center
      label
        display: flex
        align-items: center
        vertical-align: middle
        transition: all 0.3s
        background: $rombit-blue-200
        cursor: pointer
        border: none
        border-radius: 0.4rem
        outline: none
        font-size: 1.4rem
        font-weight: 700
        color: $rombit-blue-600
        span
          display: flex
          align-items: center
          padding-right: 1.2rem
          white-space: nowrap
          .icons-plus
            margin-right: 0.8rem
            path
              fill: $rombit-blue-600
