@import 'src/sass/_variables'

.routes-settings
  .settings-time-attendance
    .select-container
      display: flex
      align-items: flex-start
      margin: 1.6rem 0 4.8rem
      label
        display: block
        margin-bottom: 0.4rem
        font-size: 1.4rem
        line-height: 2rem
      > div
        width: 30rem
      > div:first-child
        width: 54rem
        margin-right: 2rem
