@import 'src/sass/_variables'

.ui-confirm-dialog
  position: absolute
  top: 4.8rem
  display: flex
  align-items: center
  justify-content: center
  width: 40rem
  min-height: 15rem

  .dialog
    z-index: 1
    display: flex
    flex-direction: column
    min-width: 40rem
    min-height: 10rem
    background-color: $white
    padding: 2.4rem
    border-radius: 0.8rem
    box-shadow: 0 0 0.4rem transparentize($black, 0.75)
    .content
      flex: 1 1 auto
      margin-bottom: 1.6rem
      .title
        margin-bottom: 0.8rem
        font-size: 1.6rem
        font-weight: $fw-bold
      p
        font-size: 1.4rem
    .actions
      display: flex
      align-items: center
      justify-content: right
      flex: 0 0 auto
      width: 100%
      .ui-button
        &:first-child
          margin-right: 0.8rem
