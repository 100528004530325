@import 'src/sass/_variables'

// Items table
.items-table
  .ui-table
    .item-name
      display: flex
      align-items: center
      width: 100%
    .rdt_TableBody
      .rdt_TableRow
        > div:last-child
          display: flex
          justify-content: flex-end
  &.outside-meeting-points
    .ui-table
      .rdt_TableBody
          max-height: calc(100vh - 34.6rem)
