@import 'src/sass/_variables'

.routes-management-time-tracking
  .blank-state
    .message-content:last-child
      font-size: 1.6rem
      line-height: 2rem
  .topbar-controls
    cursor: pointer
    .ui-select-single
      z-index: 2
      width: 16rem
  .container
    .back-row
      display: flex
      align-items: center
      justify-content: flex-start
      flex-direction: row
      width: fit-content
      cursor: pointer
      margin: 0 0 0.4rem
      .icons-arrow-left
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem
        path
          fill: $ink
      span
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-light
  .ui-module-header
    .item-with-time-selector
      display: flex
      align-items: center
      .heading
        display: flex
        align-items: center
        justify-content: flex-start
        .ui-title
          width: fit-content
          h2
            +ellipsis(28rem)
        .badge
          display: flex
          align-items: center
          padding: 0.2rem 1.2rem
          margin: 0 1.6rem
          border: 0.1rem solid $rombit-blue-800
          border-radius: 2rem
          font-size: 1.2rem
          text-align: center
          color: $rombit-blue-800
    .time-range-selector
      display: flex
      align-items: center
      .arrows-container
        display: flex
        margin-right: 2.4rem
        .btn-icon
          width: 3.2rem
          height: 3.2rem
          background-color: transparent
          padding: 0
          &:first-child
            margin-right: 0.4rem
          &:hover
            background-color: $sky
          &:active
            background-color: $sky-dark

          &.disabled
            color: red
            [class^="icons-"]
              path
                fill: $sky-dark
          > div
            line-height: 0
      .date
        font-size: 2rem
        font-weight: $fw-bold
        line-height: 2.4rem
        color: $ink
