@import 'src/sass/_variables'

.ui-title
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  font-family: $source-sans-pro

  &.small
    > h2
      font-size: 1.4rem
      line-height: 1.8rem
  &.medium
    > h2
      font-size: 1.6rem
      line-height: 2.2rem
  &.large
    > h2
      font-size: 2rem
      line-height: 2.8rem
  &.larger
    > h2
      font-size: 2.4rem
      line-height: 3.2rem

  > h2
    white-space: nowrap
    font-weight: $fw-bold
    color: $ink-light

  .icons
    display: flex
    align-items: center
    justify-content: flex-end
    height: 100%
    cursor: pointer
    .icons-pdf
      width: 2.7rem
      margin-right: 3rem
    .icons-csv
      width: 2.7rem
      margin-right: 1rem
