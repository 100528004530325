@import 'src/sass/_variables'

.tmv2-tooltip-mount
  position: absolute
  width: 100%

  .tooltip
    position: absolute
    display: flex
    align-items: flex-start
    justify-content: center
    transform: translate(-50%, -115%)
    background: $white
    padding: 0.8rem
    border-radius: 0.4rem
    box-shadow: 0 0.2rem 0.7rem 0.1rem rgba(0, 0, 0, 0.3)
    font-size: 1.4rem
    color: $ink
    user-select: none

    & > div
      width: 100%

    &:after
      content: ""
      position: absolute
      z-index: 1
      bottom: -1.4rem
      transform: translate(0%,-50%) rotate(-45deg)
      width: 1.4rem
      height: 1.4rem
      background: $white

    .tooltip-event-priority
      display: flex
      align-items: center
      font-size: 1.4rem
      font-weight: $fw-regular

      svg
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.4rem

      &.critical
        color: $red
        path
          fill: $red
      &.high
        color: $orange
        path
          fill: $orange
      &.medium
        color: $blue
        path
          fill: $blue
      &.low
        color: $ink-lighter
        path
          fill: $ink-lighter

    .tooltip-title
      width: 100%
      font-size: 1.4rem
      font-weight: $fw-bold
      color: $ink
      text-overflow: ellipsis
      white-space: nowrap

    .tooltip-body
      width: 100%
      max-height: 15rem
      padding-top: 0.6rem

      span
        display: block
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-light

      .tooltip-id
        display: flex
        align-items: center
        justify-content: flex-start

        .tooltip-id-icon
          display: flex
          align-items: center
          justify-content: center
          width: 1.6rem
          height: 1.6rem
          cursor: pointer
          margin-left: 0.4rem

          &:hover path
            fill: $ink-light
          &:active path
            fill: $ink

          path
            width: 1.6rem
            height: 1.6rem
            fill: $ink-lighter

      .tooltip-event-involved
        font-weight: $fw-bold

      .tooltip-time
        color: $ink-lighter

      .tooltip-altitude
        display: flex
        align-items: center
        justify-content: flex-start

        span
          display: block
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-light

        .altitude-icon
          width: 1.6rem
          height: 1.6rem
          margin: 0 0.4rem

          svg
            width: 1.6rem
            height: 1.6rem

      .cluster-devices
        display: flex
        flex-direction: column
        max-height: 14rem
        background: $sky-light
        padding: 0.2rem 0.2rem 0.2rem 0.8rem
        margin-top: 0.2rem
        border: 0.1rem solid $sky
        border-radius: 0.3rem
        overflow-y: scroll

        .device
          display: flex
          align-items: center
          justify-content: flex-start
          background: $sky
          padding: 0.4rem 0.8rem
          margin: 0.2rem 0
          border-radius: 1.2rem

          svg
            width: 1.6rem
            min-width: 1.6rem
            height: 1.6rem
            margin-right: 0.2rem

            path
              fill: $ink-light

          span
            font-size: 1.2rem
            line-height: 1.6rem
            color: $ink-light

    .tooltip-footer
      position: relative
      z-index: 2
      margin-top: 1rem
      .ui-button
        height: 2rem
        background-color: $rombit-blue-600
        cursor: pointer
        padding: 0.6rem
        border-radius: 0.2rem
        font-weight: $fw-regular
        color: $white
        div
          font-size: 1.2rem

    .close
      display: flex
      align-items: center
      justify-content: center
      width: 1.6rem
      height: 1.6rem
      cursor: pointer
      margin-left: 0.8rem
      path
        width: 1.6rem
        height: 1.6rem
        fill: $ink-lighter
      &:hover path
        fill: $ink-light
      &:active path
        fill: $ink
