@import 'src/sass/_variables'

.owners
  .owners-container
    > div
      display: flex
      align-items: center
      margin-bottom: 0.8rem
      > div
        &:first-child
          width: 100%
        &:last-child
          &:empty
            width: 2.5rem
      & .icons-trash
        &:hover
          cursor: pointer
          svg
            path:first-child
              fill: $red
