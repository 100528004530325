@import 'src/sass/_variables'

.ui-editor
  position: relative
  &.viewer-mode
    padding: 0.8rem 1.6rem 1.6rem
    border: 0.2rem solid $sky
    border-radius: 0.4rem
  .toastui-editor-defaultUI
    .toastui-editor-contents
      z-index: 6
    .toastui-editor-defaultUI-toolbar
      // Overrite inline styles
      /* stylelint-disable-next-line declaration-no-important */
      border-top-left-radius: 0.4rem !important
    .toastui-editor-popup-body
      .toastui-editor-file-name
        height: 3.2rem
      #toastuiAltTextInput:focus
        border: 0.1rem solid $input-border-color-hover
    .toastui-editor-contents h2
      border-bottom: none
  .editor-error
    position: absolute
    z-index: 2
    left: 1.6rem
    bottom: 1.6rem
    p
      font-weight: $fw-bold
      color: $red
      &:nth-child(1)
        font-size: 1.4rem
      &:nth-child(2)
        font-size: 1.2rem
        font-weight: $fw-regular
