@import 'src/sass/_variables'

.ui-messages
  display: flex
  align-items: center
  flex-direction: column
  width: 100%
  margin-top: 1rem
  margin-bottom: 7.2rem

  .blank-state
    background-color: $sky-lighter
    margin-top: 6.4rem
    .graphs-no-comments
      width: 26.5rem
      height: 17.6rem
      background-color: $sky-lighter

  .answer,
  .question
    display: flex
    width: 100%
    margin-bottom: 1.6rem
    .ui-avatar
      margin-top: 2.3rem // optical

    .text
      display: flex
      flex-flow: column nowrap
      white-space: pre
      > p
        margin-bottom: 0.4rem
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-light
      > div
        display: flex
        align-items: center
        width: fit-content
        min-height: 3.8rem
        padding: 0.8rem 1.6rem
        margin-bottom: 0.4rem
        border: 0.1rem solid $sky-dark
        border-radius: 0.8rem
        font-size: 1.4rem
        line-height: 2rem
        color: $ink
        white-space: normal

      .file
        font-weight: $fw-bold
        > div
          display: flex
          cursor: pointer
          margin-left: 0.8rem
        .icons-file
          margin-right: 0.8rem
        .icons-download
          width: 1.6rem
          path
            fill: $green

      > span
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-lighter
  .question
    flex-flow: row nowrap
    .ui-avatar
      margin-right: 0.8rem
    > div
      align-items: flex-start
    .text
      margin-right: 4rem
  .answer
    flex-flow: row-reverse nowrap
    .ui-avatar
      margin-left: 0.8rem
    > div
      align-items: flex-end
    .text
      margin-left: 4rem

.ui-message-input
  position: fixed
  bottom: -0.4rem
  display: flex
  align-items: center
  width: calc(80.8rem - 4.8rem)
  background-color: $sky-lighter
  padding-top: 1.6rem
  padding-bottom: 1.6rem
  margin-top: 2rem

  .connection-warning
    position: absolute
    right: 7.2rem
    margin-top: -0.2rem
    font-size: 1.2rem
    line-height: 1.6rem
    color: red

  // textarea
  .ui-avatar
    margin-right: 0.8rem

  // textarea
  .input-textarea
    width: stretch
    margin-right: 0.8rem
    &.ff-width
      width: 100%
    textarea
      width: 100%
      height: 4rem
      background-color: $sky-lighter
      padding: 1rem
      border: 0.1rem solid $sky-dark
      border-radius: 0.8rem
      font-family: $source-sans-pro
      font-size: 1.4rem
      resize: none
      color: $ink
      &::placeholder
        color: $ink-lighter
      &:focus
        // compensate focus border
        padding: 0.9rem 1rem 1rem 0.9rem
        border: 0.2rem solid $rombit-blue-600

  .btn-send
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    width: 3.2rem
    height: 3.2rem
    background-color: $rombit-blue-600
    cursor: pointer
    border-radius: 50%
    &.disabled
      background-color: $sky-dark
      .icons-send
        path
          fill: $sky-lighter
    .icons-send
      position: relative
      left: 0.1rem
      path
        fill: $white
