@import 'src/sass/_variables'

.filter-dropdown-element-group
  // TODO: Set correct color here
  border-top: 0.1rem solid transparentize($ink-lightest, 0.7)

  &:last-of-type
    // TODO: Set correct color here
    border-bottom: 0.1rem solid transparentize($ink-lightest, 0.7)

  &.devices
    .filters-box-header
      justify-content: space-between

  .filters-box-header
    display: flex
    align-items: center
    width: 100%
    background-color: $white
    cursor: pointer
    padding: 1.6rem 2.4rem

    h3
      margin-right: 0.8rem

    .count-indicator
      display: flex
      align-items: center
      background: $sky-light
      padding: 0 0.8rem
      border-radius: 1.2rem
      font-size: 1.2rem
      color: $ink-lightest

    .multi-selector
      margin-right: 0.8rem
      margin-left: auto

    div:last-child
      line-height: 0

      svg
        width: 2.4rem
        height: 2.4rem

  .filters-box-content
    display: none
    flex-direction: column
    height: 100%
    max-height: 32rem
    overflow-y: scroll
    background-color: $sky-light
    padding: 2.4rem
  
  &.open

    .filters-box-header
      background-color: unset

      .count-indicator
        background-color: $sky

    .filters-box-content
      display: flex
