.toast-wrapper
  position: relative
  display: flex
  justify-content: center
  width: 100%
  .toast-text
    .toast-message
      margin-bottom: 0
  .icons-load-spinner
    margin-right: 0.8rem
    animation: loadingSpinner 3s 0ms linear forwards infinite
