@import 'src/sass/_variables'

.ui-label-info-tooltip
  display: flex
  align-items: center
  font-size: 1.4rem
  line-height: 1.6rem
  > div:first-child
    display: flex
    align-items: center
  .tooltip-container
    display: inline
    margin-left: 0.6rem
    > div
      line-height: 0
      svg
        width: 1.6rem
        height: 1.6rem
        path
          fill: $sky-dark
