@import 'src/sass/_variables'

.no-map-items
  padding: 3rem 0 0
  text-align: center
  svg
    width: 5.8rem
    path
      fill: $sky-dark
  .text
    width: 100%
    padding: 0 1rem
    margin: 2rem 0
    font-size: 1.4rem
    color: $ink
