@import 'src/sass/_variables'

.routes-worker-licenses
  .manage-licenses-table
    .license-modal
      +browser-max-zoom-heigth(880px)

      .modal
        .ui-row
          > span
            text-align: left
        &.ui-checkbox
          > span
            margin-left: 1rem
        .checkbox
          flex-direction: row
          margin-top: 2rem
          .ui-checkbox
            > label
              display: inline-block
              margin-right: 1rem
        .content
          .icons-unassign
              path
                fill: $red
          .icons-assign
              path
                fill: $green
          .ui-row:last-child
            margin-bottom: 3.2rem
        .actions.success
          justify-content: flex-end
