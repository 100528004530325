@import 'src/sass/_variables'

.ui-dropdown-menu
  position: relative
  cursor: not-allowed
  &.clickeable
    cursor: pointer
  .dropdown-content
    +dropdown-content
    min-width: 19.5rem
    &.horizontal
      align-items: center
      justify-content: center
      flex-wrap: wrap
      margin-top: 1rem
      &.show
        display: flex
      div
        padding: 0 0.4rem
      .menu-row
        .icons-menu
          &:empty
            display: none
        [class^="icons-"]
          display: flex
          align-items: center
          justify-content: center
          width: 2.8rem
          margin-right: 0

  // Topbar right menu
  &.user-menu
    .dropdown-content
      .userdata
        height: 5.3rem
        border-bottom: 0.1rem solid $sky
        .menu-row
          align-items: flex-start
          justify-content: center
          flex-flow: column nowrap
          .icons-menu
            display: none
        &:hover
          background-color: white
          cursor: default
        .fullname
          max-width: 25rem
          margin-bottom: 0.1rem
          font-size: 1.4rem
          font-weight: 700
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden
          line-height: 1.6rem
          color: $ink
        .email
          font-size: 1.2rem
          font-weight: $fw-regular
          line-height: 1.6rem
          color: $ink-lightest

      .hub-component
        align-items: flex-start
        justify-content: flex-start
        height: auto
        padding: 0.8rem 0 1.2rem
        border-bottom: 0.1rem solid $sky
        .menu-row
          width: 100%
          max-width: 100%
          .icons-menu
            display: none
        &:hover
          background-color: white
          cursor: default

      .logout
        height: 3.4rem // evaluate to move it to last-child selector
        border-top: 0.1rem solid $sky

      .menu-row
        align-items: center
        svg[class^="icons-"]
          width: 2.4rem
          height: 2.4rem
          path
            fill: $ink

  // Sidebar bottom left menu
  &.places-menu
    > .right
      bottom: 0
    > div
      .menu-row
        max-width: 100%
        span
          max-width: 100%
    .dropdown-selected-place,
    .dropdown-selected-company
      font-weight: 700
    .dropdown-company-header
      border-bottom: 0.1rem solid $sky
      &:hover
        background-color: $white
      .menu-row
        span
          font-weight: $fw-bold
          color: $ink-lightest
        [class^="icons-"]
          pointer-events: none
          line-height: 0
          path
            fill: $ink-lightest
          &:empty
            display: none
    .icons-menu
      flex-shrink: 0

  &.layers-menu
    .icons-chevron-down,
    .icons-chevron-up
      width: 2.4rem
      height: 2.4rem
    .icons-layers
      path
        fill: $ink-lighter
    &.active
      .icons-layers
        path
          fill: $rombit-blue-600
    .dropdown-content
      z-index: 3
      left: 1.6rem
      bottom: 4rem
      align-items: center
      width: fit-content
      border-radius: 0.4rem
      font-size: 1.4rem
      line-height: 1.8rem
      .icons-menu
        &:empty
          display: none
      > div
        .menu-row
          align-items: center
        .ui-checkbox
          height: 1.6rem
        &:hover
          .menu-row
            .ui-checkbox
              .control input ~ .control_indicator
                border: 0.1rem solid $rombit-blue-600
    .component
      > div
        display: flex
        align-items: center
        flex-direction: row
        background-color: $white
        padding: 0.4rem 0.8rem
        margin-bottom: 3.6rem
        margin-left: 1.6rem
        border-radius: 0.4rem
        box-shadow: 0 0 0.4rem transparentize($ink, 0.25)
        h2
          margin-right: 0.4rem
          margin-left: 0.4rem
          font-size: 1.4rem
          font-weight: $fw-regular

  &.assets-menu
    height: 2.4rem
    margin-left: 0.8rem
    .component
      line-height: 1
      .icons-chevron-down
        line-height: 1
    .bottom.dropdown-content
      right: -1.6rem
      margin-top: 1rem

  &.menu-solutions
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    width: 4rem
    height: 4rem
    margin-right: 0.8rem
    border-radius: 0.8rem
    &:hover
      background-color: $sky
    .icons-menu-squares
      path
        fill: $ink-light
    &.show
      .icons-menu-squares
        path
          fill: $black
    .menu-items-title
      margin-bottom: 0.8rem
      h3
        font-size: 1.6rem
      &:hover
        background-color: $white
        cursor: default
        user-select: none
      span
        font-size: 1.6rem
    .menu-item-active
      &:hover
        background-color: $white
        cursor: default
    > .component
      display: flex
      align-items: center
      justify-content: center
      flex-shrink: 0
      width: 4rem
      height: 4rem
    > .dropdown-content
      top: 4rem
      width: 24rem
      padding: 1.6rem 0 0.8rem
      > div
        height: initial
        padding: 0 0 0.8rem
        margin: 0 1.6rem
        &:hover
          background-color: $white
          cursor: pointer
          border-radius: 0.8rem
          .menu-row
            background-color: $sky-light
          &.menu-items-title
            cursor: default
            .menu-row
              background-color: $white
        &.disabled-item
          pointer-events: none
          svg
            path
              fill: $sky
          span
            color: $sky
          &:hover
            cursor: not-allowed
      .menu-row
        width: 100%
        padding: 0.4rem
        span
          font-size: 1.2rem
          font-weight: $fw-bold
          text-transform: uppercase
          line-height: 1.6rem
        svg
          margin-right: 0.8rem


// With Portal
.with-portal.dropdown-content
  +dropdown-content
  &.show
    margin-top: 0
  &.left
    top: 0
    right: 100%
    transform: translateX(-100%)
    margin-top: 0.4rem
    margin-left: 2rem
    &.arrow:after,
    &.arrow:before
      border-right-color: $white
      margin-top: -1rem
    &.arrow:before
      border-right-color: $sky
      margin-top: -1.1rem

  &.edit-zone-list,
  &.item-list,
  &.panel-list,
  &.group-list,
  &.certificate-list,
  &.member-list,
  &.card-list,
  &.button-menu-list
    z-index: 5 // to place the menu over side-panel-update
    width: 14rem
    min-width: 14rem
    svg[class^="icons-"]
      width: 2.4rem
      height: 2.4rem
    > div:hover
      .icons-edit
        svg path
          fill: $rombit-blue-600
        & + span
          color: $rombit-blue-600
      .icons-trash,
      .icons-logout
        svg path
          fill: $red
        & + span
          color: $red
  &.card-list
    margin-top: 0.8rem
    margin-left: 3.2rem
  &.wide
    width: 19.4rem
