@import 'src/sass/_variables'

.tmv2-loading-data
  position: absolute
  z-index: 1
  // Topbar height + margin
  top: $topbar-height + 1.6rem
  display: flex
  align-items: center
  justify-content: center
  width: 100%

  .body
    display: flex
    align-items: center
    justify-content: center
    width: 29rem
    height: 4rem
    background-color: $white
    border-left: 0.8rem solid $rombit-blue-600
    border-radius: 0.4rem

    .icons-load-spinner
      margin-right: 0.8rem
      animation: loadingSpinner 3s 0ms linear forwards infinite

    .title
      font-size: 1.4rem
      font-weight: $fw-regular
      line-height: 2rem
