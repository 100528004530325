@import 'src/sass/_variables'

// Has to be more than all other controls on screen
$z-index: 5

.map-controls,
.overlay-indoor
  &.evacuation
    .zoom-control-mount
      // Factor in evacuation bar
      bottom: calc(9.5rem + 4.6rem)

    .fullscreen-control-mount
      bottom: calc(15rem + 4.6rem)

    .layers-control-mount
      // Factor in evacuation bar
      bottom: 7.6rem

.item-following-box-v2
  &.evacuation
    // Factor in evacuation bar
    /* stylelint-disable-next-line declaration-no-important */
    bottom: calc(6rem + 4.6rem) !important

.mapv2-evacuation
  .floating-header-container
    position: absolute
    display: flex
    align-items: flex-end
    justify-content: center
    width: 100%
    margin-top: 2rem
  .floating-header
    z-index: $z-index
    display: flex
    align-items: flex-end
    justify-content: center

    .ui-timer
      position: relative
      left: auto
      transform: none

    .danger-zone-create-button
      display: flex
      align-items: center
      justify-content: center
      width: 4rem
      height: 4rem
      background-color: $yellow
      cursor: pointer
      margin-right: 0.8rem
      border-radius: 0.4rem

      .icons-area,
      .icons-close
        path
          fill: $white
  .ui-accordion
    position: absolute
    z-index: $z-index
    bottom: $topbar-height
    width: 100%
    .right-block
      .icons-expand,
      .icons-shrink
        path
          fill: $ink-light

    & + .live-map-toast
      bottom: 10rem
      transition: bottom 600ms
