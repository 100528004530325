@import 'src/sass/_variables'

.routes-devices-infrastructure
  // Devices table
  .infrastructure-devices-table
    margin: 0
    margin-top: 1rem
    .ui-table
      .device-state
        margin-left: 0.8rem
        font-size: 1.4rem
        line-height: 1.6rem

      // Column status
      .ui-status
        padding: 0.4rem 1rem
        svg
          width: 1.6rem
          height: 1.6rem
        span
          font-size: 1.2rem
          font-weight: $fw-bold
          line-height: 1.2rem
          white-space: nowrap

        &.warning
          background-color: $yellow-lighter
          span
            color: $yellow-darker
          svg
            path
              fill: $yellow-darker

        &.theme-red
          background-color: $red-lighter
          span
            color: $red-darker
          svg
            path
              fill: $red-darker

        &.theme-green
          background-color: $green-lighter
          span
            color: $green-darker
          svg
            path
              fill: $green-darker

        &.theme-sky
          background-color: $sky
          span
            color: $ink-light
          svg.icons-close
            path
              fill: $ink-light

  .infrastructure-updates,
  .pairing-point-updates
    .installed-switch
      margin-bottom: 1.6rem
