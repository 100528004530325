@import 'src/sass/variables'
.ui-phone-input
  position: relative
  label
    display: block
    font-size: 1.4rem
    line-height: 3rem
    color: $input-label-color
    .mandatory
      color: $red
  .react-tel-input
    font-family: $source-sans-pro
    font-size: 1.6rem
    line-height: 2rem
    .form-control
      width: 100%
      height: 3.6rem
      background: linear-gradient(90deg, transparent 3.9rem, white 3.9rem)
      border: 0.1rem solid $input-border-color
      border-radius: 0.4rem
      &:focus,
      &:focus-within,
      &:focus-visible
        border-color: $rombit-blue-600
        /* stylelint-disable-next-line declaration-no-important */
        outline: 0.1rem solid $rombit-blue-600 !important
      &.open
        border: 0.1rem solid $rombit-blue-600
        box-shadow: 0 0 0 0.1rem $rombit-blue-600 inset
    .flag-dropdown
      z-index: 2
      top: 0.1rem
      left: 0.1rem
      height: 3.4rem
      border: none
      border-right: 0.1rem solid $input-border-color
      border-radius: 0.3rem 0 0 0.3rem
      &.open
        top: 0.2rem
        left: 0.2rem
        height: 3.2rem
        border-right: 0.1rem solid $input-border-color
        border-radius: 0.2rem 0 0 0.2rem
      .selected-flag
        top: 0.1rem
        height: 3.2rem
        border: none
        border-radius: 0.4rem 0 0 0.4rem
        &:focus
          /* stylelint-disable-next-line declaration-no-important */
          outline: none !important
        &.open
          top: 0.1rem
          width: 3.7rem
          height: 3rem
          .flag
            transform: translateX(-0.1rem)
    .country-list
      &:hover,
      &:focus,
      &:focus-within,
      &:focus-visible
        /* stylelint-disable-next-line declaration-no-important */
        outline: none !important

  // On error
  &.error
    .react-tel-input
      .form-control
        background: linear-gradient(90deg, transparent 3.9rem, $red-lighter 3.9rem)
        border: 0.1rem solid $red
        &:focus,
        &:focus-within,
        &:focus-visible
          border-color: $red
          /* stylelint-disable-next-line declaration-no-important */
          outline: 0.1rem solid $red !important
        &.open
          border: 0.1rem solid $red
          box-shadow: 0 0 0 0.1rem $red inset

    .tip.error
      position: absolute
      display: block
      width: 100%
      min-height: 1.8rem
      font-size: 1.2rem
      line-height: 1.8rem
      color: $red
