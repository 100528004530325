@import 'src/sass/_variables'

.tmv2-filters-empty-state
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  width: 100%
  height: 100%
  padding: 0 7.2rem
  margin: 0 auto
  text-align: center

  .icon
    svg
      width: 4.8rem
      height: 4.8rem

  .title
    width: 100%
    margin-bottom: 0.8rem
    font-size: 1.6rem
    font-weight: $fw-bold
    color: $ink-light

    .ui-title
      justify-content: center

  .text
    font-size: 1.4rem
    font-weight: $fw-regular
    color: $ink-light
