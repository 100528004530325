@import 'src/sass/_variables'
.ui-change-password
  .group-label-password
    margin-right: auto
    margin-bottom: 2.4rem
    margin-left: auto
    p,
    label
      font-size: 1.6rem
      line-height: 2rem
      color: $ink-lightest
    p
      margin-bottom: 1rem
      font-weight: 700
    label
      display: block
    > div
        display: flex
        align-items: center
        margin-bottom: 0.4rem
    .icons-check
      display: inline
      margin-right: 0.8rem
      path
        fill: $green
    .icons-check,
    .icons-close
      width: 1.6rem
      height: 1.6rem
    .icons-close
      display: inline
      margin-right: 0.8rem
      path
        fill: $sky-dark
  .password-form
    .ui-input
      > div
        position: relative
        input::-ms-reveal
          display: none
        > div
          position: absolute
          top: 0.6rem
          right: 0.8rem
          cursor: pointer
          line-height: 0
          .icons-view
            line-height: 0
            path
              fill: $ink-lightest
