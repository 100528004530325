@import 'src/sass/_variables'

.tmv2-filters-box
  position: absolute
  z-index: 5
  top: 0
  right: -100%
  transition: right 0.3s ease
  width: 38.4rem
  height: 100%
  background-color: $white
  // Factor In TopBar's height
  margin-top: 6.4rem
  // TODO: Set correct color here
  border-top: 0.1rem solid transparentize($ink-lightest, 0.7)
  box-shadow: rgb(0 0 0 / 30%) 0 0 0.4rem -0.1rem
  font-size: 1.4rem

  .filters-wrapper
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    overflow-y: scroll
    &::-webkit-scrollbar
      display: none

    h3
      font-size: 1.4rem
      color: $ink-light

    h4
      font-size: 1.2rem
      color: $ink-light

    .map-pill
      font-size: 1.2rem
      color: $ink-lightest

    .multi-selector
      cursor: pointer
      font-size: 1.2rem
      color: $rombit-blue-600
      &.disabled
        cursor: not-allowed
        color: $sky-dark

  &.open
    right: 0
