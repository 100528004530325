@import 'src/sass/_variables'

.routes-map
  .filters
    overflow-y: scroll
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none
    .header
      .title
        width: 100%
        .btn-close
          margin-right: 2.4rem
          margin-left: auto
          line-height: 0
          &:hover
            cursor: pointer
          .icons-chevron-right
            width: 1.6rem
            height: 1.6rem
    .boxes
      justify-content: flex-start
      height: 100%
      &.edit-mode
        .box
          &.active
            .content
              display: block
              height: 100%
              min-height: initial
              max-height: 45rem
      .box
        display: flex
        flex-direction: column
        width: 100%
        &:not(.active)
          flex-grow: 0
        &::selection
          background-color: transparent
        .header
          display: flex
          justify-content: space-between
          flex-shrink: 0
          width: 100%
          background-color: $sky
          cursor: pointer
          padding: 1.6rem 2.4rem
          border-top: 0.1rem solid $sky-dark
          border-bottom: 0.1rem solid $sky-dark
          > div
            display: flex
            align-items: center
          // left side of the box
          > div:first-child
            // title
            > div:first-child
              margin-right: 1rem
              font-size: 1.4rem
              font-weight: $fw-bold
              line-height: 2rem
              color: $ink-light
            .map-badge
              background-color: $sky-light
              padding: 0 0.8rem
              border-radius: 0.8rem
              font-size: 1.2rem
              color: $ink-lightest
          // right side of the box
          > div:last-child
            // "todos" text
            > div:first-child
              margin-right: 1.6rem
              font-size: 1.2rem
              color: $rombit-blue-600
            // arrow
            > div:last-child
              line-height: 0
              svg
                width: 2.4rem
                height: 2.4rem
                path
                  fill: $ink

        .content
          display: none
          transition: all 0.2s
          background-color: $sky-light
          padding: 1.6rem 2.4rem
          margin-bottom: 0
          overflow: hidden scroll
          .map-blank-state
            .blank-state
              height: 8rem
              background-color: transparent
              margin-top: 0
              .message-content
                max-width: 30rem
                font-size: 1.2rem
                line-height: 1.6rem
                .link
                  cursor: pointer
                  margin: 0
                  font-size: 1.2rem
                  font-weight: $fw-regular
                  color: $rombit-blue-600
                p
                  display: flex
                  flex-direction: column
                  font-size: 1.2rem
                .text
                  max-width: 25rem
                  max-height: 3rem
                  white-space: normal
          .ui-search-input
            height: 2.4rem
            margin-bottom: 1.6rem
            .icons-search
              position: absolute
              z-index: 1
              width: 1.6rem
              height: 1.6rem
              margin-left: 0.8rem
            .ui-input
              div:first-child
                line-height: 1
              input
                height: 2.4rem
                padding-left: 2.8rem
                font-size: 1.2rem
                &:active,
                &:focus
                  box-shadow: none
            .icons-close
              width: 1.6rem
              height: 1.6rem
              margin-right: -0.6rem
          .ui-tree
            .control input + .control_indicator:before
              animation: none
            .list-item
              > div:last-child ul
                .list-row
                  position: relative
                  &:hover
                    &:before
                      content: ""
                      position: absolute
                      z-index: 1
                      display: block
                      width: calc(100% - 2rem)
                      height: 2rem
                      background-color: $sky
                      margin-left: 2rem
                      border-radius: 0.2rem
                    > .item-info,
                    > .item-codename
                      position: relative
                      z-index: 2
                  > .item-info
                    > .item-name
                      max-width: 19.5rem
                  > .item-codename
                    width: 5.4rem
        &.events
          .item-codename
            margin-left: 0.4rem

        &.active
          flex-grow: 1
          .header
            background-color: $sky-light
            border-bottom-color: $sky-light
            padding-bottom: 0
            > div
              .map-badge
                background-color: $sky
          .content
            display: block
            height: 100%
            min-height: 12rem
            max-height: 22rem
            +browser-min-zoom-heigth(958px)
              max-height: 28rem
            +browser-min-zoom-heigth(992px)
              max-height: 34.4rem
