@import 'src/sass/_variables'

.ui-select-items
  .helper-text
    display: block
    margin-top: 0.4rem
    font-size: 1.2rem
    font-weight: $fw-regular
    text-align: left
    line-height: 1.4rem
    color: $ink-lighter
  > label
    +common-label
  .contractors-label,
  .items-label
    display: flex
    align-items: center
    justify-content: space-between
    span:last-child
      color: $ink-lightest
