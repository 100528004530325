@import 'src/sass/_variables'

.routes-communication
  .container
    margin: 2rem 4.4rem 4.8rem 3.2rem
    .ui-module-header
      .ui-table-toolbar
        margin-left: auto
      .btn-add-new
        margin-left: 0.8rem
    .ui-side-panel
      .update
        width: 100%
