@import 'src/sass/_variables'

.tmv2-toggle-filters-button
  position: absolute
  z-index: 1
  right: 0
  display: flex
  transition: transform 0.3s ease
  margin-top: 1.6rem

  button
    display: flex
    align-items: center
    background: white
    cursor: pointer
    border: none
    border-radius: 0.4rem
    box-shadow: rgb(0 0 0 / 30%) 0 0.1rem 0.4rem -0.1rem
    font-size: 1.6rem
    color: $ink-lighter

    &:first-child
      padding: 0.4rem
      margin-right: 1.6rem
      svg
        width: 3.2rem
        height: 3.2rem

      &:last-child
        padding: 0.8rem 1.2rem
        svg
          width: 2.4rem
          height: 2.4rem
          margin-right: 0.4rem

      path
        fill: $ink-lighter

      &:hover
        color: $ink-light
        path
          fill: $ink-light
      &:active
        color: $ink
        path
          fill: $ink

  &.open
    transform: translateX(100%)

  &.changed
    button
      position: relative
      &:after
        content: ""
        position: absolute
        top: -0.6rem
        right: -0.6rem
        width: 1.4rem
        height: 1.4rem
        background-color: $rombit-blue-600
        border-radius: 50%
