@import 'src/sass/_variables'

=panel-label
  margin-bottom: 0.4rem
  font-size: 1.6rem
  font-weight: $fw-semibold
  line-height: 2.2rem
  color: $r-black

.analitics-filters
  background-color: $white
  > .panel-header
    position: sticky
    z-index: 1
    top: 0
    display: flex
    align-items: center
    justify-content: flex-start
    background-color: $white
    padding: 1.6rem
    border-bottom: 0.1rem solid $sky
    svg
      margin-right: 1.6rem
      path
        fill: $ink-light
    span
      font-size: 2rem
      font-weight: $fw-semibold
      line-height: 2.4rem
      color: $ink-light
    .apply-button
      margin-left: auto

  .filters-box
    border-bottom: 0.1rem solid $sky
    > .filters-box-header
      display: flex
      align-items: center
      justify-content: flex-start
      padding: 1.6rem
      > svg
        margin-right: 1.6rem
      span
        font-size: 2rem
        font-weight: $fw-semibold
        line-height: 2.4rem
        color: $ink-light
      .filter-buttons
        display: flex
        align-items: center
        justify-content: flex-start
        margin-left: auto
        .ui-button
          > div
            font-weight: $fw-regular

    // Common to all labels
    label
      +panel-label
    .filters-content
      &.collapsed
        height: 0
        overflow: hidden
      &.expanded
        height: 100%

    .filters-datepicker
      display: flex
      flex-flow: column nowrap
      padding: 1.6rem
      .ui-date-range-picker
        .DateRangePicker
          width: 100%
        .DateInput
          width: 50%
        .icons-calendar
          path
            fill: $ink-lighter

    .filters-select
      display: flex
      flex-flow: column nowrap
      padding: 0 1.6rem 1.6rem
      label
        display: block

    .filters-options
      display: flex
      flex-flow: column nowrap
      padding: 0 1.6rem 1.6rem
      .filters-select-buttons
        display: flex
        flex-flow: row wrap
        gap: 0.8rem
        .ui-button
          border: 0.1rem solid $sky
          border-radius: 0.8rem
          font-size: 1.6rem
          font-weight: $fw-regular
          color: $ink
          &.active
            border-color: $rombit-blue-700
            color: $rombit-blue-700

  .filters-row
    display: flex
    flex-flow: row nowrap
    padding: 0 1.6rem 3.2rem
    gap: 1.6rem
    > div
      flex-basis: 50%
