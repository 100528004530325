@import 'src/sass/_variables'

.productivity-process
  > .container
    > .row
      align-items: flex-start
      flex-direction: column
      width: 100%
      margin-bottom: 2rem

    .process
      display: flex
      justify-content: flex-start
      flex-flow: row nowrap
      width: 100%
      margin-bottom: 2.4rem
