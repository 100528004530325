@import 'src/sass/_variables'

.case-detail
  &.ui-side-panel
    z-index: 1
    width: calc(100% - 5.6rem)

  .detail-header
    display: flex
    align-items: center
    flex-flow: row nowrap
    height: 4.8rem
    background-color: $sky-light
    padding: 0.4rem 1.6rem
    .btn-back
      width: 3.2rem
      height: 3.2rem
      background-color: transparent
      padding: 0
      margin: 0 2.4rem 0 0
      > div
        line-height: 0
      svg
        path
          fill: $ink-light
      &:hover
        background-color: transparent
        svg
          path
            fill: $ink-lighter
      &:active
        svg
          path
            fill: $ink
    .ui-title
      max-width: fit-content
      margin-right: 0.8rem
      h2
        color: $ink
        +ellipsis
    .visible-id
      margin-top: 0.2rem
      margin-right: 0.8rem
      font-size: 1.2rem
      line-height: 2rem
      color: $ink-light
      white-space: nowrap
    .btn-declare-accident,
    .btn-resolve-case
      padding: 0.2rem 1.2rem 0
      // TODO: Keep an eye on this
      svg
        position: relative
        top: -0.1rem
    .btn-declare-accident
      margin-right: 1.6rem
      &.disabled
        outline: none
        path
          fill: $white
    .btn-resolve-case
      padding-right: 2rem
      margin-right: 4.8rem
      > div
        display: flex
        align-items: center
      svg
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem
        path
          fill: $white

    .ui-select-status
      margin-left: 0.8rem
      &.progress
        .select-single__control
          background-color: $orange
      &.resolved
        .select-single__control
          background-color: $green
      &.open
        .select-single__control
          background-color: $rombit-blue-700
      &.undefined
        .select-single__control
          background-color: $ink-lighter

      +select-pill($bg: $ink-lighter, $input-w: 14.4rem, $menu-w: 16.8rem)
      label
        display: block
        margin-bottom: 0.4rem
        font-size: 1.2rem
        font-weight: $fw-regular
        line-height: 2rem
        color: $ink-light
        &:empty
          margin-bottom: 0
      .status-value
        .color
          display: none
        .select-single__single-value
          margin-left: 0

  .ui-tabs
    height: 4.8rem
    background-color: $white
    padding-left: 2.4rem
    > div:first-child
      span
        display: flex
        align-items: center

  .panel-content
    display: flex
    flex-flow: row nowrap
    width: 100%
    height: calc(100% - 5rem)
    .left-panel
      flex-shrink: 0
      width: 80rem
      .activities-tab,
      .manual-tab
        height: calc(100% - 4.6rem)
        overflow: hidden scroll
      .case-detail-activities
        margin: 2.4rem
        .activity-action
          .activity-file
            display: flex
        .icons-ambulance-outline
          path
            fill: $rombit-blue-600
        .activity-container
          .icons-manual-case
            path
              fill: $rombit-blue-600
              opacity: 0.9 // optical compensation

    .right-panel
      width: 100%
      border-top: 0.4rem solid $sky
      border-left: 0.4rem solid $sky
      .blank-state
        width: 100%
        height: calc(100vh - 9.8rem)
        background-color: $sky-light
        .icon
          svg
            width: 8.3rem
            height: 8.3rem
        .message-content
          max-width: 32rem
          .ui-title
            h2
              white-space: normal
          p
            font-size: 1.4rem
      .map
        position: relative
        width: 100%
        height: calc(100vh - 9.8rem)
        .buttons
          position: absolute
          z-index: 1
          bottom: 0
          right: 0
          display: flex
          flex-flow: row nowrap
          margin: 0 2.4rem 3.2rem 0
          .btn-map
            display: flex
            align-items: center
            justify-content: center
            background-color: white
            padding: 0 1.2rem
            margin-left: 1.2rem
            border: 0.1rem solid $sky
            border-radius: 0.4rem
            &:disabled
              background-color: trasparent
              cursor: default
              pointer-events: none
              border: 0.1rem solid $sky
              > div
                color: $ink-lightest
            > div
              color: $rombit-blue-600

        .google-maps
          width: 100%
          height: 100%
      img
        object-fit: fill
        width: 100%
      .ui-canvas
        height: calc(100vh - 9.8rem)

  .tabs-content
    .container-files
      padding: 2.4rem
      .ui-input.upload
        .dropzone
          .blank-state
            background-color: $sky-light

    .msg-wrapper
      .ui-messages
        padding: 0.8rem 1.6rem 2.4rem
        .blank-state,
        .graphs-no-comments
          background-color: $sky-light
      .ui-message-input
        width: 80rem
        padding: 1.6rem
