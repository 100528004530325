@import 'src/sass/_variables'

.rules
  &.list
    overflow: hidden
    .container
      display: flex
  .button
    display: flex
    justify-content: flex-end
    width: 100%
    margin-right: 0
    font-size: 1.4rem
  .wrapper
    flex: 1
    .ui-toast
      font-size: 1.4rem
      line-height: 2rem
      .toast-title
        font-weight: 700
  .ui-toast
    .toast-message
      margin-bottom: 0.8rem
      &:only-child
        margin-bottom: 0
        font-weight: $fw-bold
    .toast-link
      text-decoration: underline
      &:hover
        cursor: pointer

.ui-modal
  .critical
    font-weight: 700
  .folders-modal
    display: flex
    align-items: center
    width: 100%
    .ui-row
      label
        font-size: 1.4rem
        font-weight: 400
        text-align: left
    .ui-select-color
      position: relative
      top: 0.1rem
      .select
        margin-right: 0
      .circle-picker
        right: 1.5rem
    .ui-input
      span
        position: absolute
        width: 37rem
        margin: 0
        text-align: left
  &.change-rule-folder
    .content
      > div:last-child
        width: 100%
