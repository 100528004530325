@import 'src/sass/_variables'

.emails
  .ui-row
    margin-bottom: 0
  .content
    position: relative
    > div
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 2.4rem
      &:last-child
        margin-bottom: 1.6rem
      .ui-input
        width: 100%
        span
          position: absolute
          width: 37rem
          margin: 0
          text-align: left
      .icons-trash
        &:hover
          cursor: pointer
          svg
            path:first-child
              fill: $red
