@import 'src/sass/_variables'

.ui-input
  position: relative
  display: flex
  vertical-align: middle
  text-overflow: ellipsis
  line-height: 3rem
  &.column
    flex-direction: column
  &.with-message
    // Temporary fix until remove .rule .box .ui-input selector which now
    // affects all inputs
    &.column,
    &.row
      padding-bottom: 1.2rem
  > div:first-child
    width: 100%
  &:disabled
    color: $sky-lighter
  label
    font-size: 1.4rem
    color: $input-label-color
    .mandatory
      color: $red
  input
    width: 100%
    height: 3.6rem
    background-color: $white
    padding: 0 0 0 1.2rem
    border: 0.1rem solid $input-border-color
    border-radius: 0.4rem
    font-family: $source-sans-pro
    font-size: 1.4rem
    color: $input-font-color
    &:active,
    &:focus
      border-color: $rombit-blue-600
      outline: none
      box-shadow: 0 0 0 0.1rem $rombit-blue-600 inset
    &:hover
      border-color: $input-border-color-hover
      outline: none
      &:active,
      &:focus
        border-color: $rombit-blue-600
    &:-webkit-input-placeholder
      /* stylelint-disable-next-line declaration-no-important */
      color: $input-placeholder-color !important
    &-ms-input-placeholder
      /* stylelint-disable-next-line declaration-no-important */
      color: $input-placeholder-color !important
    &:placeholder
      /* stylelint-disable-next-line declaration-no-important */
      color: $input-placeholder-color !important
    &:placeholder-shown
      text-overflow: ellipsis
    &:disabled
      border: 0.1rem solid $input-border-color
      box-shadow: none
      color: $ink-lightest
    &.error
      background-color: $red-lighter
      border-color: $red
      &:active,
      &:focus
        border-color: $red
        outline: none
        box-shadow: 0 0 0 0.1rem $red inset
    &.warning
      border-color: $orange
  .tip
    position: absolute
    display: block
    width: 100%
    min-height: 1.8rem
    font-size: 1.2rem
    line-height: 1.8rem
    color: $ink-lightest
    &.info
      font-style: italic
    &.error
      color: $red
    &.warning
      color: $orange
    &:empty
      display: none

.ui-input,
.ui-phone-input
  &.with-inside-btn
    position: relative
    .inside-input-btn
      position: absolute
      top: 3.6rem
      right: 0
      margin-right: 1.6rem
      font-size: 1.2rem
      font-weight: $fw-bold
      text-decoration: none
      line-height: 2.4rem
      color: $rombit-blue-600
      &.disabled
        color: $ink-lightest
