@import 'src/sass/_variables'
@import 'src/routes/map/live/satellite/styles.sass'

.live-map
  &.evacuation
    .map-satellite
      .google-maps
        height: calc(100% - 4.6rem)
    .layers-menu
      padding-bottom: 2.4rem
      .dropdown-content
        &.show
          bottom: 10rem
  .sidebar
    max-width: 25rem
    height: 100vh
    background-color: $sky-lighter
  .ui-side-panel.modal-open
    z-index: 2
  .info-tooltip
    margin-left: 15rem
    > div
      z-index: 1
  .toast
    position: absolute
    z-index: 4
    left: 1.6rem
    bottom: 10rem
    margin: 0
    > div
      position: static
      min-width: 20rem
      background-color: $white
      box-shadow: 0 0.1rem 0.4rem $ink-lightest
      span
        font-weight: $fw-bold
        color: $ink
        white-space: nowrap
      svg
        path
          fill: $rombit-blue-600

  .ui-title
    > div
      display: flex
      align-items: center
      justify-content: flex-end
    .ui-checkbox
      display: flex
      align-items: center
      justify-content: center

  .routes-map-canvas
    z-index: 0
    display: flex
    flex-direction: row
    height: calc(100vh - 5rem)
    padding-top: 5rem
    .canvas
      margin-top: 2rem
      margin-left: 2rem
      canvas
        border: 0.1rem solid $rombit-blue-900
      .tooltip
        position: absolute
        top: 50%
        left: 50%
        opacity: 0.5
        width: 20rem
        background-color: $black
        cursor: pointer
        padding: 1rem
        border-radius: 0.4rem
        font-size: 1.1rem
        text-align: center
        color: $white

  .partial-expanded
    & + .live-map-toast
      bottom: 27.4rem
      transition: bottom 600ms

  .full-expanded
    & + .live-map-toast
      bottom: 56.4rem
      transition: bottom 600ms

  .ui-accordeon
    & + .live-map-toast
      bottom: 10rem
      transition: bottom 600ms

  .ui-timer
    margin-top: 2rem
  .map-filters
    > div:first-child
      overflow-y: hidden
