@import 'src/sass/_variables'

.update-nfc.container
  display: flex
  align-items: center
  flex-direction: column
  width: 100%
  height: 80vh
  h1
    font-size: 1.9rem
    text-align: left
  input
    opacity: 0
  .box
    width: 30%
    padding: 1rem
    margin-top: 20vh
    p
      margin-top: 3rem
      font-size: 1.6rem
      color: $ink-lighter
    .label
      margin-bottom: 1rem
      font-size: 2.4rem
      &.success
        color: $green
      &.error
        color: $red
