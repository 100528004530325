@import 'src/sass/_variables'

.routes-worker-licenses
  .manage-licenses-table
    .ui-button.toolbar-button
      height: 2.8rem
      box-shadow: none
      > div
        font-weight: $fw-bold
      &:hover
        background-color: $sky-light
      &:active
        background-color: $sky-lighter
      [class^="icons-"]
        width: 1.6rem
        height: 1.6rem
        path:first-child
          fill: $ink-lighter
