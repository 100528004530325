@import 'src/sass/_variables'

.routes-backoffice-companies
  padding: 1rem 0 2rem
  > button:first-child
    position: absolute
    top: 5rem
    right: 2rem
    display: flex
    align-items: center
  .ui-list
    max-height: calc(100vh - 12rem)
    margin-right: -4rem
    .field
      cursor: pointer
      text-overflow: ellipsis
      overflow: hidden
      &.name
        &:hover
          text-decoration: underline
  .company-header
    align-items: center
    justify-content: flex-end
    flex-direction: row
    .ui-button
      margin-left: 2.4rem
    .ui-checkbox
      color: red
      .control
        margin-bottom: 0
    label
      margin-bottom: 0
.company
  .ui-title
    margin-bottom: 2.4rem
