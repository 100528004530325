@import 'src/sass/_variables'

.flag-options
  .ui-row
    align-items: center
    justify-content: space-between
    flex-direction: row
    width: 100%
  .title
    font-weight: 700
  .subgroup-title
    font-weight: 600
