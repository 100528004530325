@import 'src/sass/_variables'

.ui-avatar
  cursor: pointer
  img
    object-fit: cover
    border-radius: 50%

  &.smaller
    +inputSize(1.6rem)
  &.small
    +inputSize(2.4rem)
  &.medium
    +inputSize(3.2rem)
  &.medium-large
    +inputSize(8rem)
  &.big
    +inputSize(10rem)
    &.editable
      &:hover
        &:before
          width: 10rem
          height: 10rem

  &.editable
    position: relative
    display: flex
    align-items: center
    justify-content: center
    .icons-camera
      display: none
    &:hover
      &:before
        content: ""
        position: absolute
        z-index: 1
        display: block
        width: 100%
        height: 100%
        background: $ink-lightest
        border-radius: 50%
        mix-blend-mode: multiply
      .icons-camera
        position: absolute
        z-index: 1
        display: block
        margin: auto
        path
          fill: $white
