@import 'src/sass/_variables'

.ui-error
  display: flex
  align-items: center
  justify-content: center
  vertical-align: middle
  width: 100%
  height: 100vh
  background-color: $sky-light
  padding: 0.8rem 0 0
  font-size: 3rem
  text-align: center
  .blank-state
    background-color: $sky-light
    .message-content
      .title
        display: block // TODO: Use heading tags
        margin-bottom: 1.6rem
    .text
      max-width: 45rem
      margin-bottom: 2.4rem
      font-size: 1.6rem
      font-weight: $fw-regular
      color: $ink-light
    .ui-link
      border-bottom: none
      text-decoration: none
      color: $rombit-blue-600
    .ui-button
      margin: 0 auto
