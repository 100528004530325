.backoffice-impersonate
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: calc(100vh - 12rem)
  .content-container
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    margin: 0 auto
    .ui-input
      width: 25rem
      margin-bottom: 1rem
