.routes-backoffice-users
  padding: 1rem 2rem 2rem
  > .buttons
    position: absolute
    top: 5rem
    right: 2rem
    display: flex
    align-items: center
    button
      margin-right: 0.6rem
