@import 'src/sass/_variables'

.certificates-list
  padding-top: 2.4rem
  border-top: 0.1rem solid $ink-lightest
  header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 0.8rem
    > div
      font-size: 1.2rem
      font-weight: $fw-bold
      line-height: 1.6rem
      color: $ink
    .ui-button.link
      padding: 0
  .certificates-content
    margin-top: 0.8rem
  .log-list
    display: flex
    flex-direction: column
    .log-box
      display: flex
      align-items: center
      margin-bottom: 0.4rem
      img
        margin-right: 0.8rem
        border-radius: 1rem
      .content
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        padding: 0.8rem 1.4rem 0.8rem 1.6rem
        border: 0.1rem solid $sky-dark
        border-radius: 0.8rem
        p
          font-size: 1.4rem
          line-height: 2rem
        span
          font-weight: $fw-bold
        > div
          margin-left: 0.4rem
          line-height: 0
          .icons-download
            width: 2.4rem
            height: 2.4rem
            path
              fill: $green
          &:hover
            cursor: pointer
            .icons-download
              path
                fill: $green-darker
    > div
      margin-bottom: 1.6rem
      > p
        margin-left: 4rem
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-lighter
