@import 'src/sass/_variables'

.workers
  +dashboard-card

  .grid-items
    display: grid
    grid-template-columns: repeat(4, 1fr)
    > div
      background-color: $white
      border-left: 0.1rem solid $sky-light
      &:hover
        background-color: $sky-light
      &:first-child
        border-left: none

  .numbered-items
    border-bottom: 0.1rem solid $sky-light
    > div
      padding: 1.2rem 1.6rem 1.6rem
      &:hover
        cursor: pointer
    h3
      height: 3.2rem
      margin-bottom: 0.4rem
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 1.6rem
      color: $ink

    p
      font-size: 3.2rem
      font-weight: $fw-bold
      line-height: 4rem
      color: $ink

  .empty-state-simple
    display: flex
    align-items: center
    justify-content: center
    height: 9.2rem
    div
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      background-color: $white
      border-radius: 0.8rem
    p
      font-size: 1.2rem
      font-weight: $fw-regular
      text-align: center
      line-height: 1.6rem
      color: $ink
      &:first-of-type
        font-weight: $fw-bold
