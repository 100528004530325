@import 'src/sass/_variables'

.ui-single-date-picker
  .SingleDatePicker
    border-radius: 0.4rem
    .SingleDatePickerInput
      display: flex
      padding-right: 0.8rem
      padding-left: 1.2rem
      &__withBorder
        border-color: $input-border-color
        border-radius: 0.4rem
      &_calendarIcon
        height: 3rem
        padding: 0
        margin: 0
        > div
          line-height: 0
      .DateInput
        height: 3rem
        border-style: hidden
        border-radius: 0.4rem
        .DateInput_input
          height: 3rem
          padding: 0
          border: none
          border-radius: 0.4rem
          font-family: $source-sans-pro
          font-size: 1.4rem
          font-weight: $fw-regular
          line-height: 1.6rem
          color: $input-font-color
      input::placeholder
        padding-right: 0
        color: $input-placeholder-color

      &__disabled
        button
          cursor: default
          .icons-calendar
            path
              fill: $ink-lightest
        .DateInput
          .DateInput_input
            color: $ink-lightest

    .SingleDatePicker_picker,
    .DateInput_fang
      margin-top: -1.6rem

.ui-date-range-picker
  .DateRangePicker
    border-radius: 0.4rem
    .DateRangePickerInput
      display: flex
      padding-right: 0.8rem
      padding-left: 1.2rem

      &__withBorder
        border-color: $input-border-color
        border-radius: 0.4rem
      &_arrow
        span
          display: flex
          align-items: center
          height: 100%
          padding: 0 0.8rem
      &_calendarIcon
        height: 3rem
        padding: 0
        margin: 0 0 0 auto
        > div
          line-height: 0
      &_clearDates
        position: relative
        top: 0
        transform: none
        width: 3.2rem
        height: 3.2rem
        background: none
        margin: 0 0 0 0.4rem
        &:hover
          background: none
          svg
            path
              fill: $ink
        svg
          path
            fill: $ink-lightest

      .DateInput
        display: flex
        align-items: center
        width: 12.4rem
        border-style: hidden
        border-radius: 0.4rem
        .DateInput_input
          height: 3rem
          padding: 0
          border: 0.1rem solid transparent
          border-radius: 0.4rem
          font-family: $source-sans-pro
          font-size: 1.4rem
          font-weight: $fw-regular
          line-height: 1.6rem
          color: $input-font-color
        input::placeholder
          padding-right: 0
          color: $input-placeholder-color

      .DateRangePicker_picker
        z-index: 2
        margin-top: -1.6rem
      .DateInput_fang
        margin-top: -1.6rem
      .DateInput_fangStroke
        stroke: $rombit-blue-600
  &:hover
    .DateRangePickerInput__withBorder
      border-color: $input-border-color-hover
  &.short
    .DateRangePicker
      .DateRangePickerInput
        .DateInput
          width: 8.2rem

// COMMON
// ======

.ui-date-range-picker,
.ui-single-date-picker
  .CalendarDay
    &__default
      border: none
      border-radius: 50%
      &:hover
        background: $rombit-blue-100
        border-radius: 50%

    &__selected
      background: $rombit-blue-500
      font-weight: $fw-bold
      color: $white

      &:hover
        background: $rombit-blue-500
        font-weight: $fw-bold
        color: $white

    &__today:not(.CalendarDay__selected)
      background: $rombit-blue-300
      font-weight: $fw-regular
      color: $rombit-blue-700

    &__selected_span
      background-color: $rombit-blue-100
      border-radius: 0
      font-weight: $fw-regular
      color: $ink
      &:hover
        background: $rombit-blue-200
        border-radius: 0
    &__blocked_calendar
      background-color: $white
      color: $ink-lightest
    &__hovered_span
      background-color: $rombit-blue-100

    // Concave shapes near active dates
    &.CalendarDay__selected_start:not(.CalendarDay__lastDayOfWeek)
      position: relative
      &:after
        content: " "
        position: absolute
        top: 0
        left: 1rem
        display: block
        width: 100%
        height: 100%
        background: url("../../images/reverse-circle-left.svg") no-repeat 50% 50%
    &.CalendarDay__selected_end:not(.CalendarDay__firstDayOfWeek)
      position: relative
      &:after
        content: " "
        position: absolute
        top: 0
        left: -1rem
        display: block
        width: 100%
        height: 100%
        background: url("../../images/reverse-circle-right.svg") no-repeat 50% 50%
    &.CalendarDay__selected_start.CalendarDay__selected_end.CalendarDay__selected,
    &.CalendarDay__selected_start.CalendarDay__selected_start_no_selected_end
      &:after
        background: none
    &__blocked_out_of_range
      &:hover
        background-color: $white
