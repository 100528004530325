@import 'src/sass/_variables'

.google-maps
  .gm-style-iw-tc:after
    top: -0.8rem // to separate tooltip from icon as expected in design
    background-color: transparent
  .gm-style-iw
    top: -0.8rem // to separate tooltip from icon as expected in design
    border-radius: 0.4rem
  .gm-style-iw-chr
    height: 1.6rem
    .gm-ui-hover-effect
      transform: scale(0.75) // visual effect to "override" inline styles
      > span
        padding: 0
  .marker-tooltip
    display: flex
    align-items: center
    img
      width: 4rem
      margin-right: 1rem
  .google-maps-tooltip
    min-width: 16rem
    max-width: 24rem
    min-height: 13rem
    max-height: 16rem
    &.events
      .ui-priority
        margin-bottom: 0.4rem
        > div
          justify-content: flex-start
        .critical,
        .high,
        .medium,
        .low
          > div
            margin-right: 0.4rem
            line-height: 0
          svg
            width: 1.6rem
            height: 1.6rem
        .critical
          color: $red-darker
        .high
          color: $orange-darker
        .medium
          color: $blue-dark
        .low
          color: $ink-lighter
      .maps-content
        font-weight: $fw-bold
    &.cluster
      width: 24rem
      max-height: 20rem
    &.poi
      min-height: unset
    .title
      font-size: 1.4rem
      font-weight: $fw-bold
      line-height: 2rem
      color: $ink
    .maps-content,
    .sub-content,
    .buttons
      font-size: 1.2rem
      line-height: 1.6rem
    .maps-content
      color: $ink-light
      svg
        width: 1.4rem
        height: 1.4rem
        margin-right: 0.6rem
      > div
        display: flex
      &.cluster
        flex-direction: column
        width: 100%
        padding: 0.4rem
        .cluster-header
          width: 100%
          margin-bottom: 0.8rem
          .title
            margin-right: 0.4rem
            font-size: 1.6rem
            text-align: left
            line-height: 2rem
            color: $ink
        .maps-content
          flex-direction: column
          width: 100%
          height: 14rem
          background-color: $sky-light
          padding: 0.8rem
          border: 0.1rem solid $sky
          border-radius: 0.4rem
          overflow: hidden auto
          .map-pill
            // this styles are quite the same as ui-pill but I rather copy/paste
            // most of them, instead of changing the types of the ui-pill text
            display: flex
            align-items: center
            justify-content: center
            width: fit-content
            background-color: $sky
            padding: 0.4rem 1.2rem
            margin-bottom: 0.8rem
            border-radius: 1.2rem
            > [class^="icons"]
              flex-shrink: 0
              margin-right: 0.6rem
              line-height: 0
              path
                fill: $ink-light
            .icons-machine
              svg
                margin-right: 0
            > div
              font-size: 1.2rem
              line-height: 1.4rem
              white-space: normal
              color: $ink-lighter
        .sub-content
          align-self: flex-start
          margin-top: 0.4rem
    .sub-content
      color: $ink-lightest
    .buttons
      margin-top: 1rem
      span
        background-color: $rombit-blue-600
        cursor: pointer
        padding: 0.4rem 1.2rem
        border-radius: 0.2rem
        font-weight: $fw-regular
        color: $white
  .google-maps-info-tooltip
    display: flex
    justify-content: space-around
    flex-direction: column
    min-width: 16rem
    min-height: 3.5rem
    font-size: 1.2rem
    color: $ink-light
    .device-content,
    .position-content
      display: flex
      justify-content: space-around
      font-weight: $fw-regular
    .device-content
      flex-direction: column
      margin-bottom: 0.8rem
    .position-content
      align-items: center
    span
      margin: 0 0.2rem
    .title
      font-weight: 700
      line-height: 2rem
