@import 'src/sass/_variables'

.box-header
  display: flex
  align-items: center
  justify-content: space-between
  transition: all ease-in 0.1s 0s
  height: 4.2rem
  border-bottom: 0.1rem solid $sky-light
  border-top-left-radius: 0.8rem
  border-top-right-radius: 0.8rem
  .icon
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    width: 2.4rem
    height: 2.4rem
    background-color: $rombit-blue-100
    margin-right: 0.8rem
    margin-left: 1.6rem
    border-radius: 0.8rem
    [class^=icons-]
      width: 1.6rem
      height: 1.6rem
      path
        fill: $rombit-blue-600

    .icons-docking-slot
      path
        stroke: $rombit-blue-600
        fill: $rombit-blue-300

  .icons-chevron-right
    flex-shrink: 0
    width: 1.6rem
    height: 1.6rem
    margin-right: 1.6rem
    margin-left: auto
  &:hover
    background-color: $rombit-blue-100
    cursor: pointer
    .ui-title h2
      text-decoration: underline
      color: $rombit-blue-600
    .icons-chevron-right
      path
        fill: $rombit-blue-600
