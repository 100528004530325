@import 'src/sass/_variables'

.ui-input
  &.upload
    width: 100%
    input
      width: 0
  .input-container
    display: flex
    flex-direction: column
    [type="file"]
      height: 0
    label,
    [type="file"]
      position: relative
      display: flex
      align-items: center
      vertical-align: middle
      transition: all 0.3s
      background: $rombit-blue-200
      cursor: pointer
      border: none
      border-radius: 0.4rem
      outline: none
      font-size: 1.4rem
      font-weight: 700
      color: $rombit-blue-600
      &:hover
        background-color: $rombit-blue-200
    label
      width: fit-content
      height: 3.2rem
      span
        text-align: center
    .file-value
      display: flex
      align-items: center
      margin-top: 1rem
      .icons-trash
        margin-left: 0.8rem
        line-height: 0
        &:hover
          svg
            path
              cursor: pointer
              fill: $red
  .errors
    display: flex
    flex-direction: column
    color: $red

  .dropzone
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    padding: 1rem 0
