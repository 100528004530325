@import 'src/sass/_variables'

.routes-map
  .live-map
    .map-satellite
      display: flex
      width: 100%
      height: calc(100vh - 6.4rem)
      .ui-info-box
        top: 11rem
        pointer-events: none
      .google-maps
        width: 100%
        margin-left: 0
    .edit
      .google-maps
        width: calc(100% - 25.6rem)
        margin-left: 25.6rem
