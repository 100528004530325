@import 'src/sass/_variables'

.routes-events
  top: 0
  .cluster
    display: flex
    align-items: center
    justify-content: center
  .container
    // Events Dashboard Summary
    .events-resolve
      z-index: 4 // over the backdrop
      top: 0
      width: $sidepanel-large-width
      overflow: hidden
      > div:first-child
        height: 100%
      .close.right
        display: none

    .backdrop
      position: fixed
      z-index: 2 //over table-header
      top: 0
      left: 0
      bottom: 0
      right: 0
      display: block
      transition: all 0.3s linear
      width: 100%
      height: 100%
      background-color: transparentize($ink-light, 0.5)
    .event-detail
      position: relative
      z-index: 4 // over the rainbox
      width: $sidepanel-large-width
      height: 100%
      background-color: $sky-lighter
      padding: 2.4rem
      .detail-header
        display: flex
        justify-content: space-between
        flex-flow: row nowrap
        margin-bottom: 0.4rem
        .ui-title
          +ellipsis(48rem)
      .trigger-info
        display: flex
        align-items: center
        justify-content: flex-start
        margin-bottom: 2.4rem

      // Container
      .content
        position: relative
        display: flex
        justify-content: center
        flex-direction: column
        .ui-tabs
          width: fit-content
          margin-top: 1.6rem
        // Rule type
        > .events-type
          margin-bottom: 0.8rem
          font-size: 2rem
          font-weight: 700
          text-align: left
          line-height: 2.4rem
          +ellipsis

        // Map/Message container
        > .tabs-content
          display: flex
          align-items: flex-start
          justify-content: space-between
          width: 100%
          height: 100%
          margin-top: 1.6rem

          // Files tab
          .container-files
            .ui-input.upload
              .dropzone
                .blank-state
                  background-color: $sky-lighter

          // Details tab
          .field-record-detail
            width: calc(100% - 28rem)
            height: 100%
            padding-bottom: 2.4rem
            &.expanded
              width: 100%

            .sidepanel-event-label
              +field-record-label
            .detail-row
              display: flex
              align-items: flex-start
              justify-content: flex-start
              > div
                min-height: 2.4rem
                font-size: 1.4rem
                line-height: 2.4rem
              &.single-items
                margin-bottom: 0.8rem
              &:empty
                margin-bottom: 0
              &.stacked
                flex-direction: column
                header
                  p
                    +field-record-label
              .asset-speed
                font-size: 3.2rem
                font-weight: 700
                line-height: 4rem
                &.red-color
                  color: $red
              // TODO SAME AS .with-pills UNIFY
              .assets-item-pills
                display: flex
                align-items: flex-start
                flex-flow: row wrap
                .ui-pill
                  margin: 0 0.8rem 0.8rem 0
              .priority-combo
                margin-right: 1.6rem
              .category-combo
                .select-label
                  +select-pill($bg: $ink-lighter, $input-w: 14rem, $menu-w: 16.8rem)
                  label
                    +field-record-label
              header
                display: flex
                align-items: flex-start
                justify-content: flex-start
                margin-bottom: 0
                p
                  display: flex
                  align-items: flex-start
                  justify-content: flex-start
                  line-height: 3.2rem
                  &:only-child
                    +field-record-label
                    display: flex
                    align-items: center
                    margin-bottom: 0
                  span
                    +field-record-label
                    align-self: center
                    margin-bottom: 0

            .pills-block
              .block-label
                +field-record-label
            .side-panel-card
              display: flex
              flex-direction: row
              width: fit-content
              padding: 1.6rem 1.2rem
              border: 0.1rem solid $sky
              border-radius: 0.8rem
              .evacuation-action
                display: flex
                flex-direction: column
                &:first-child
                  margin-right: 4rem
                .ui-pill
                  min-height: 2.4rem
                  margin-bottom: 0.8rem
                .evacuation-time
                  font-size: 1.4rem
                  line-height: 2rem
              &.total-evacuated
                flex-direction: column
                .evacuated-numbers
                  font-size: 4rem
                  font-weight: 700
                  color: $ink
                  &.high-numbers
                    color: $red-darker
                  &.dg-numbers
                    color: $yellow

            // startedby, ended by and total count
            .evacuation-top-row
              display: flex
              align-items: stretch
              justify-content: flex-start
              flex-direction: row
              width: 100%
              gap: 1.6rem
              max-width: 85%
              margin-top: 1.6rem
              margin-bottom: 1.6rem
              .side-panel-card
                &:first-child
                  flex-grow: 2
              .total-evacuated
                flex-grow: 1

            .evacuation-time-row
              max-width: 85%
              margin-bottom: 1.6rem
              .side-panel-card
                width: 100%
                > .detail-row:not(:first-child)
                  margin-left: 3.2rem

            .evacuation-meeting-row
              display: grid
              grid-template-rows: 1fr 1fr
              grid-template-columns: 1fr 1fr 1fr
              grid-gap: 1.6rem
              max-width: 85%
              margin-bottom: 0.8rem
              .side-panel-card
                flex-direction: column
                width: 100%
                .sidepanel-event-label
                  max-width: 15.6rem
                  line-height: 2rem

            .event-certificates
              width: 30rem
              margin-top: 2.4rem
              .certificates-header
                display: flex
                justify-content: space-between
                margin-bottom: 0.4rem
                font-weight: 700
                line-height: 2rem
                h3
                  font-size: 1.4rem
                  text-align: left
            .view-more
              position: relative
              cursor: pointer
              margin-top: 0.8rem
              margin-bottom: 1.6rem
              font-size: 1.2rem
              font-weight: $fw-semibold
              text-align: left
              color: $rombit-blue-600
              &:before
                content: ""
                position: absolute
                top: -1.2rem
                left: -1.2rem
                width: calc(100% + 1.2rem)
                height: 0.8rem
                background: linear-gradient(to bottom, transparent, $sky-lighter 50%)
              &.expanded:before
                display: none
              &.top-right
                margin-top: 0
                margin-bottom: 0
                font-weight: $fw-semibold
                text-align: right
            .nested-detail-rows
              display: flex
              flex-direction: row
              > .detail-row
                flex: 1
                max-width: 50%

            .pills-details
              .wrapped-text
                max-width: 10rem
              .ui-pill.safety-zone
                .wrapped-text
                  max-width: initial

            .infra-details
              .wrapped-text
                max-width: 10rem
            .event-duration
              flex-direction: column
            .evacuated
              .evacuated-numbers
                font-size: 4rem
                font-weight: 700
                color: $ink
              .ui-link
                position: relative
                top: 0.4rem
                display: inline
                height: 1.4rem
                margin-left: 0.8rem
                border: none
                font-size: 1.2rem
                font-weight: 700
                text-align: left
                line-height: 1.4rem
                color: $rombit-blue-600
                &:hover
                  cursor: pointer
                  text-decoration: underline

          .map
            flex-shrink: 0
            width: 42rem
            // Error message
            > .map-msg
              background-color: $red-lighter
              padding: 0.5rem 0
              font-size: 1.2rem
              text-align: center
              line-height: 1.6rem
              color: $red

            .google-maps
              width: 42rem
              height: calc(100vh - 28rem)

          .full-map-detail
            flex-shrink: 0
            width: 100%
            height: calc(100vh - 30rem)
            .google-maps
              width: 100%
              height: 100%
            .ui-canvas
              height: calc(100vh - 30rem)
          > div
            &.event-image
              display: flex
              align-items: center
              justify-content: center
              overflow: hidden
              img
                object-fit: cover
                width: 28rem
                height: 28rem

        // Activities tab
        .event-detail-activities
          display: flex
          align-items: center
          flex-direction: column
          width: 100%
          margin-top: 1rem
          margin-bottom: 7.2rem
          .activity-container
            display: flex
            flex-flow: row nowrap
            width: 100%
            margin-bottom: 1.6rem
            > div:first-child
              margin-top: 0.8rem
              margin-right: 0.8rem
              [class^="icons-"]
                width: 2.4rem
                height: 2.4rem
              &.filled
                .icons-lightning
                  path
                    fill: $orange
                .icons-mail,
                .icons-phone,
                .icons-sms,
                .icons-whatsapp
                  path
                    fill: $green
                .icons-reports
                  path
                    fill: $rombit-blue-600
            img
              width: 2.4rem
              height: 2.4rem
            .file
              display: flex
              align-items: center
              flex-grow: 1
              max-width: 24rem
              margin-right: 0.8rem
              font-weight: $fw-bold
              color: $ink-light
              .icons-file
                width: 1.6rem
                height: 1.6rem
              .ui-url
                margin-left: 0.4rem
                span
                  max-width: 16rem
            .text
              display: flex
              flex-flow: column nowrap
              > .activity-action
                display: flex
                align-items: center
                width: fit-content
                min-height: 3.8rem
                padding: 0.8rem 1.6rem
                margin-bottom: 0.4rem
                border: 0.1rem solid $sky-dark
                border-radius: 0.8rem
                font-size: 1.4rem
                line-height: 2rem
                color: $ink
                > svg
                  margin-left: 0.8rem
                .icons-circle-filled-check
                  path:first-child
                    fill: $green
                  path:nth-child(2)
                    fill: $white
                .icons-circle-filled-cross
                  path:first-child
                    fill: $red
                  path:nth-child(2)
                    fill: $white
                .ui-status
                  margin-left: 0.4rem
                &:empty
                  height: 3.8rem
              > span
                margin-left: 0.8rem
                font-size: 1.2rem
                line-height: 1.6rem
                color: $ink-lighter

        // Comments tab
        .msg-wrapper
          display: flex
          justify-content: space-between
          flex-flow: column nowrap
          width: 100%
          min-height: 100%


  .detail-bar
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: 3.2rem
    border-bottom: 0.1rem solid $sky
    .id-and-date
      display: flex
      justify-content: flex-start
    .temp-box
      color: $ink
      &:first-child
        margin-right: 3.2rem
    .record-buttons
      display: flex
      justify-content: flex-end
      flex-flow: row nowrap
      margin-top: 0
      .ui-button
        margin-left: 0.8rem
      .btn-map
        display: flex
        align-items: center
        justify-content: center
        background-color: white
        padding: 0 1.2rem
        border: 0.1rem solid $sky
        border-radius: 0.4rem
        &:disabled
          background-color: trasparent
          cursor: default
          pointer-events: none
          border: 0.1rem solid $sky
          > div
            color: $ink-lightest
        > div
          color: $rombit-blue-600
        &:last-child
          margin-left: 1rem

  .ui-notification-bar
    > div
      a
        color: $red
  .evacuation-bar
    display: flex
    align-items: center
    justify-content: center

  .icons-evacuation
    width: 2rem
    height: 2rem
    path
      fill: $red

  .rule-name
    display: flex
    align-items: center
    margin-bottom: 0.8rem
    [class^="icons-"]
      width: 1.6rem
      height: 1.6rem
      margin-right: 0.8rem
    &.area-related
      path,
      circle
        fill: $orange
    &.drixit-spotter
      path,
      circle
        fill: $indigo-light
    &.safety
      path,
      circle
        fill: $rombit-blue-700
    &.people-management
      path,
      circle
        fill: $red
    &.permits-control,
    &.manual
      path,
      circle
        fill: $teal
    &.productivity
      path,
      circle
        fill: $yellow
    &.covid-19,
    &.others
      path,
      circle
        fill: $lime
    &.device-status
      path,
      circle
        fill: $purple
    &.reports
      path,
      circle
        fill: $indigo
