@import 'src/sass/_variables'

.ui-tag
  display: flex
  align-items: center
  min-height: 2.4rem
  background-color: $sky
  padding: 0.1rem 0.8rem
  margin-top: 0.8rem
  margin-right: 0.4rem
  border-radius: 0.3rem
  font-size: 1.2rem
  color: $ink-light
  span:nth-child(1)
    display: flex
    align-items: center
    white-space: nowrap
    .ui-avatar
      margin-right: 0.8rem
      img
        width: 1.6rem
        height: 1.6rem
  > [class^="icons-"]
    flex-shrink: 0
    width: 1.6rem
    height: 1.6rem
    margin-right: 0.8rem
    line-height: 0
  > .remove
    cursor: pointer
    margin-left: 0.8rem
    line-height: 0
    .icons-close
      width: 1.6rem
      height: 1.6rem
      line-height: 0
      &:hover
        path
          fill: $ink

  svg
    fill: $ink-light

  > div:first-child
    &.icons-filters-settings,
    &.icons-filters-unassigned,
    &.icons-filters-events,
    &.icons-filters-people
      svg
        width: 1.6rem
        height: 1.6rem
  .colon
    margin-right: 0.35rem
  .icons-calendar
    width: 1.6rem
    height: 1.6rem

  .open,
  .progress,
  .resolved,
  .default
    > svg
      margin-right: 0.8rem
  .open
    svg
      path
        fill: $rombit-blue-700
  .progress
    svg
      path
        fill: $orange
  .resolved
    svg
      path
        fill: $green
  .default
    svg
      path
        fill: $black
