@import 'src/sass/_variables'

.analytics-options
  .title
    font-weight: 700
  .options-container
    margin-top: 1.6rem
    .ui-row
      align-items: center
      justify-content: space-between
      flex-direction: row
      width: 100%
    label
      width:50%
