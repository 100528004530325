@import 'src/sass/_variables'

.awaiting-review
  display: grid
  grid-template-columns: repeat(2, minmax(0, 1fr))
  grid-column-gap: 0.8rem

  .certificates-review,
  .unresolved-cases
    +dashboard-card
    display: flex
    justify-content: flex-start
    flex-direction: column
    &:hover
      cursor: pointer
      border: 0.1rem solid $ink
      footer
        h3
          text-decoration: underline

    > .box-icon
      margin: 1.6rem 0 0.8rem 1.6rem
    > .ui-title
      width: fit-content
      margin: 0 1.6rem 1.6rem
      > h2
        min-height: 3.6rem
        white-space: normal

  .certificates-review
    .box-icon
      background-color: $purple-lighter
      svg
        path
          fill: $purple-dark

  .unresolved-cases
    .box-icon
      background-color: $rombit-blue-100
      svg
        path
          fill: $rombit-blue-600

  footer
    padding: 1.6rem
    border-top: 0.1rem solid $sky-light
    h3
      font-size: 1.4rem
      font-weight: $fw-bold
      line-height: 2rem
      color: $ink
    p
      display: flex
      flex-flow: row wrap
      flex-shrink: 0
      min-height: 4.8rem
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 1.6rem
      color: $ink-lightest
