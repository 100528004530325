@import 'src/sass/_variables'

.ui-sidebar
  z-index: 6
  display: flex
  align-items: center
  flex-direction: column
  flex-shrink: 0
  width: 5.6rem
  height: 100vh
  background: $white
  .company-logo
    cursor: pointer
    margin: 2rem 0 1.6rem
    svg
      width: 4.2rem
      height: 4.2rem
      margin-bottom: 0.8rem
      path
        fill: $r-orange
    hr
      width: 3.2rem
      background: $ink-light
      margin: 0 auto
      border-top: none

  .sidebar-item
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    margin-bottom: 0.8rem
    border-radius: 0.8rem
    &:hover
      background: $rombit-blue-200
    > div
      display: flex
      align-items: center
      justify-content: center
      width: 4rem
      height: 4rem
      line-height: 0
      svg
        path
          fill: $black
    &.selected
      background: $rombit-blue-200
      svg
        path
          fill: $rombit-blue-800
      &:hover
        background: $rombit-blue-200
  .places
    position: absolute
    bottom: 0
    display: flex
    align-items: center
    width: 3.4rem
    height: 3.4rem
    margin-bottom: 4rem
    color: $white
    .place
      display: flex
      align-items: center
      img
        display: inline-block
        object-fit: cover
        width: 3.5rem
        height: 3.5rem
        border: 0.1rem solid $white
        border-radius: 0.2rem
      .initials
        +initials
      span
        max-width: 25rem
        margin: 0 1rem
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
        font-size: 1.4rem
      .icons-arrow
        margin-top: -0.3rem
        border-color: $rombit-blue-500

  &.hide-sidebar
    transition: margin 0.6s
    margin-left: -5.6rem

  &.full-height
    height: 100vh

  // Evacuation
  &.alert
    background-color: $red-darker
    .company-logo
      svg
        path
          fill: $white
    .sidebar-item
      svg
        path
          fill: $white
      &:hover
        background-color: $red
      &.selected
        background: $white
        svg
          path
            fill: $red-darker
          &:hover
            background: $white
