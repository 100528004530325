@import 'src/sass/_variables'

.report-card
  background-color: $white
  padding: 1.6rem
  border: 0.1rem solid $sky
  border-radius: 0.8rem
  header
    display: flex
    justify-content: space-between
    flex-flow: row nowrap
    padding-bottom: 0.8rem
    border-bottom: 0.1rem solid $sky
    > div:first-child
      .dashboard-label
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-lightest
        &:empty
          height: 1.6rem
      .ui-title.medium

        margin-bottom: 0
        h2
          font-weight: $fw-semibold
    .header-options
      display: flex
      align-items: center
      flex-flow: row nowrap
      .report-card-bullets
        margin-left: 0.8rem
  .reports-delivering
    display: flex
    justify-content: space-between
    flex-flow: row nowrap
    padding-block: 0.8rem
    border-bottom: 0.1rem solid $sky
    .element
      width: 50%
      .title
        display: flex
        align-items: center
        margin-bottom: 0.4rem
        svg
          width: 1.6rem
          height: 1.6rem
          margin-right: 0.8rem
          path
            fill: $ink-lightest
        span
          font-size: 1.2rem
          font-weight: $fw-semibold
          line-height: 1.6rem
          color: $ink-lightest
      .text
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-lightest
      // to select tooltip
      .receivers-count + div
        margin-left: 0.4rem
        line-height: 0
  .reports-dates
    display: flex
    justify-content: space-between
    flex-flow: row nowrap
    padding-top: 0.8rem
    .element
      width: 50%
      .title
        font-size: 1.2rem
        font-weight: $fw-bold
        line-height: 1.6rem
        color: $ink-lightest
      .text
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-lightest
