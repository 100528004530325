@import 'src/sass/_variables'

.routes-map,
.mapv2-evacuation
  .ui-accordion
    z-index: 2
    width: 100%
    .title
      padding: 1.6rem 1.6rem 0
      > div
        margin-right: 0.8rem
        line-height: 1
        > svg
          &:hover
            cursor: pointer
          path
            fill: $ink
      > h4
        margin-right: 0.4rem
        font-size: 1.6rem
        font-weight: $fw-bold
        line-height: 2.4rem // to match icon
        color: $ink
    &.partial-expanded
      .ui-table
        .rdt_TableBody
          max-height: 11rem
      > div:nth-child(2)
        overflow: hidden scroll
        max-height: 21rem
      .blank-state
        height: 15rem
    &.full-expanded
      .ui-table
        .rdt_TableBody
          max-height: 40rem
      .blank-state
        height: 44rem
    .tab-title-tooltip
      > div
        height: 1.6rem
      .icons-circle-filled-information
        width: 1.6rem
        height: 1.6rem
        path
          fill: $ink-light

    .ui-table
      padding: 0.8rem 1.6rem 0
      .meeting-blank-state
        display: flex
        justify-content: center
        flex-direction: column
        height: 14rem
        font-size: 2rem
        color: $ink-light
      .rdt_TableRow:hover
        background-color: $rombit-blue-100
        .icons-my-location
          path
            fill: $rombit-blue-600
      .rdt_TableRow
        &.following
          background-color: $rombit-blue-100
          path
            fill: $rombit-blue-600

    > div:nth-child(2)
      > div:first-child
        display: flex
        align-items: center
        font-size: 1.6rem
        font-weight: $fw-bold
        line-height: 2rem
      .cards
        display: flex
        flex-wrap: wrap
        align-content: flex-start
        background-color: $sky-light
        padding: 0 1.6rem 1.6rem
        overflow: hidden
        > div
          height: 11rem
          margin-top: 1.6rem

    .ui-tabs > div:first-child > div
      padding: 1.3rem 1rem

    .blank-state
      +base-card
      margin: 0.8rem 1.6rem
      .icon
        svg
          width: 4.8rem
          height: 4.8rem
      .ui-title + div
        display: none
      .footer
        margin: 0 0 0.8rem
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 1.6rem
        > span
          cursor: pointer
          text-decoration: underline
  .item-altitude
    display: flex
    align-items: center
    justify-content: flex-start

    .altitude-icon
      width: 2.4rem
      height: 2.4rem

      svg
        width: 2.4rem
        height: 2.4rem
