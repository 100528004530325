@import 'src/sass/_variables'

.ui-collapsable
  display: flex
  flex-direction: column
  width: 100%
  margin-bottom: 2.4rem
  .safe-space
    width: 2.4rem
    height: 2.4rem
  &:not(.active)
    .ui-box-content
      display: none
  .collapsable-header
    display: flex
    justify-content: space-between
    flex-direction: row
    width: 100%
    height: 3rem
    cursor: pointer
    margin-bottom: 0.8rem
    > div
      display: flex
      align-items: center
      .collapsable-arrow
        > div:first-child
          background-color: $sky
          padding: 0 0.8rem
          border-radius: 0.8rem
          color: $ink-lighter
        [class^="icons"]
          width: 2.4rem
          height: 2.4rem
      > div:first-child
          margin-right: 0.8rem
      .collapsable-title
          white-space: nowrap

.view-more-stepped
  .view-options
    display: flex
    justify-content: space-between
    margin-top: 0.8rem
    font-size: 1.2rem
    font-weight: $fw-semibold
    color: $rombit-blue-600
    > div
      cursor: pointer
