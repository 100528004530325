@import 'src/sass/_variables'

.docking-station-container
  display: flex
  flex-direction: column

  .docking-toolbar,
  .docking-header,
  .docking-info
    width: 100%
    max-width: 117.2rem

  .docking-header
    margin: 0 auto 1.6rem
    .ui-resume-bar
      margin-bottom: 0

  .docking-info
    display: flex
    flex-direction: column
    height: 100%
    margin: auto
    .info-header
      display: flex
      align-items: center
      justify-content: space-between
      flex-direction: row
      width: 100%
      min-height: 4rem
      margin-bottom: 1.2rem
      font-size: 1.4rem
      font-weight: $fw-bold
      line-height: 2rem
      .resume-menu
        display: flex
        align-items: center
        > .ui-title
          margin-right: 1.6rem
        > .menu-button
          background-color: inherit
          padding: 0 0.4rem
          &:hover
            background-color: $rombit-blue-100
          &.active
            background-color: $rombit-blue-300
          > div
            display: flex
            align-items: center
            justify-content: space-around
          &:first-of-type

            margin-right: 0.8rem
    .container
      display: flex
      justify-content: space-between
      width: 100%
      margin: 0
      &.table
        .ui-table
          width: 100%
          background-color: $white
          .rdt_Table
            max-height: 35.4rem
          .rdt_TableBody
            max-height: calc(100vh - 45.4rem)
            .device-firmware
              .icons-close
                path
                  fill: $red
              .icons-check
                path
                  fill: $green

      > .devices-blank-state
          width: 100%
