@import 'src/sass/_variables'

.routes-groups
  > button:first-child
    position: absolute
    top: 12rem
    right: 5rem
    display: flex
    align-items: center
  .ui-side-panel > div:first-child
    overflow: hidden scroll
