@import 'src/sass/_variables'

.routes-worker-licenses
  .worker-licenses-table
    margin: 0
    margin-top: 1rem
    .ui-table
      .rdt_TableBody
        max-height: calc(100vh - 45.4rem)
    .icons-close
      width: 2.4rem
      height: 2.4rem
      path
        fill: $red
  .edit-worker-licenses
    .ui-table
      // custom toolbar spacing
      .rdt_TableBody
        max-height: calc(100vh - 34rem)
      .contractor-name
        display: block
        font-size: 1.2rem
        line-height: 1.6rem
      .rdt_TableCell:nth-child(4)
        > div
          display: flex
          align-items: center
          width: auto
        .icons-close
          width: 2.4rem
          height: 2.4rem
          path
            fill: $red

        .icons-check
          width: 2.4rem
          height: 2.4rem
          path
            fill: $green

      .license-state
        margin-left: 0.8rem
        font-size: 1.4rem
        line-height: 1.6rem
      .license-pill
        > div
          display: flex
          align-items: center
          padding: 0.4rem 0.8rem
          border-radius: 0.3rem
          > div
            line-height: 0
            [class^="icons"]
              width: 1.6rem
              height: 1.6rem
          > span
            display: flex
            align-items: center
            margin-left: 0.8rem
        .full
          background-color: $teal-lighter
          color: $teal-darker
          [class^="icons"]
            path
              fill: $teal-darker
          .icons-plus
            flex-shrink: 0
            margin: 0 0.2rem

        .virtual-ppe
          background-color: $orange-lighter
          color: $orange-darker
          [class^="icons"]
            path
              fill: $orange-darker

        .worker-app
          background-color: $rombit-blue-100
          color: $rombit-blue-600
          [class^="icons"]
            path
              fill: $rombit-blue-600

        .disabled
          background-color: $sky
          color: $ink-lighter
          [class^="icons"]
            path
              fill: $ink-lighter
