@import 'src/sass/_variables'

.rules
  .rules-list
    > .container
      display: flex
      align-items: center
      flex-direction: column
      overflow: auto
      height: calc(100vh - 4.8rem)
      padding-top: 2rem
      .ui-module-header
        margin-bottom: 1.4rem
        +browser-min-zoom-width(1200px)
          max-width: 85rem
        +browser-min-zoom-width(1280px)
          max-width: 95rem
        .ui-title
          background-color: transparent
          padding-left: 0
        .ui-table-toolbar
          .ui-button
            margin-right: 0
        .btn-create-rule
          margin-left: 0.8rem
      .box
        +base-card
        margin-bottom: 2.4rem
        &.incomplete
          background-color: $red-lighter
          > div:first-child
            margin: 2.4rem 0 1.6rem 2.4rem
            font-size: 1.4rem
            font-weight: $fw-bold
            line-height: 2rem
            color: $ink
          .show-more
            &:before
              background: linear-gradient(to bottom, transparent, $sky-lighter 50%)

      .rule
        +base-card
        display: flex
        align-items: center
        flex-direction: column
        transition: all 0.2s
        max-height: 12.4rem
        cursor: pointer
        margin: 0 2.4rem
        margin-bottom: 1.6rem
        &:first-child
          margin-top: 2.4rem
        &:last-child
          margin-bottom: 2.4rem
        +browser-only-max-zoom-width(768px)
          width: 40rem
        +browser-only-max-zoom-width(480px)
          width: 30rem
        +browser-min-zoom-width(1200px)
          max-width: 80rem
        +browser-min-zoom-width(1280px)
          max-width: 90rem
        &:hover
          box-shadow: 0 1.5rem 2rem -1.6rem rgba(0, 0, 0, 0.75)
        // for now, rules cannot have a fixed height
        &.active
          max-height: 100%
        &.with-border
          position: relative
          background-color: $sky-lighter
          .card-border
            position: absolute
            top: 0
            left: 0
            display: block
            width: 0.8rem
            min-height: 100%
            background-color: $red
            border-radius: 0.8rem 0 0 0.8rem
          .card
            .alert
              position: absolute
              left: -0.2rem // optical space
              bottom: -2.4rem
              display: flex
              align-items: center
              justify-content: flex-start
              .icons-circle-filled-exclamation
                flex-shrink: 0
                width: 1.6rem
                height: 1.6rem
                margin-right: 0.4rem
                path
                  fill: $red
              > div
                display: flex
                align-items: center
                line-height: 0
              span
                font-size: 1.2rem
                font-weight: $fw-regular
                line-height: 1.6rem
                color: $red

        .card
          display: flex
          align-items: center
          width: 100%
          padding: 3.2rem 2.4rem
          &:active
            background-color: $sky-light
            border-radius: 0.8rem
          .rule-details
            position: relative
            display: flex
            flex: 1
            flex-direction: column
            width: 40rem
            margin-right: 4.4rem
            font-size: 1.2rem
            &:not(.alert)
              .folders
                position: absolute
                bottom: -2.4rem
                display: flex
                align-items: center
                justify-content: flex-start
                span
                  margin-left: 0.8rem
                  font-size: 1.2rem
                  font-weight: $fw-regular
                  line-height: 1.6rem
                  color: $ink-light
            span
              &:nth-child(3)
                font-size: 1.2rem
                color: $ink-light
            .rule-code
              width: 36rem
              color: $ink-lighter
            .ui-title.medium
              h2
                width: 36rem
                color: $ink-light

          // Steps
          > .steps
            flex: 1
            min-width: 13.6rem
            margin-right: 0.8rem
            .icons
              display: flex
              align-items: center

          // Priority
          .ui-priority
            display: flex
            align-items: center
            flex: 1
            > span
              display: block
              margin-left: 0.4rem
              font-size: 1.4rem
              font-weight: $fw-semibold
              line-height: 2rem
              color: $ink-light

          .enabled-switch
            margin-right: 1.6rem
            &:empty
              width: 5rem
              background-color: coral
          .frequency-spot
            height: 2.4rem
            > div
              line-height: 2.4rem
            .clock-icon
              height: 2.4rem
            &:empty
              width: 2.4rem
            .icons-clock
              path
                fill: $ink-lighter

          .ui-icon-box.action:nth-of-type(3)
            margin-left: -0.8rem

          .starred
            padding: 0.8rem
            &.disabled
              &:hover
                cursor: not-allowed
            &:not(.disabled)
              .icons-star
                &:hover
                  path
                    fill: $rombit-blue-300
                    stroke: $rombit-blue-300
          .bullets
            &:empty
              width: 3.2rem
          .rules-dropdown-menu
            .dropdown-content
              .menu-row
                .icons-menu
                  width: 0
                  margin-right: 0
          .icons-settings
            margin: -0.1rem 0.1rem
            svg
              width: 1.6rem
          // .ui-bullets
          // If .bullets is not the last element
          > div[class]:last-child:not(.bullets)
              margin-left: 2.4rem

        &:not(.with-border)
          .card
            .name
              margin-bottom: 1.6rem
            .region
              margin-right: -6rem

    // Safari only and only one spec because of
    // issues with flex-containers. All animations are a little bit slower
    &.is-safari
      .ui-module-header
        min-height: 4rem
      .rule
        min-height: 10rem
        &.active
          flex-basis: auto
          flex-grow: 2
          flex-shrink: 0
          .details
            transition: all 0.6s ease-in
        .card
          min-height: 9.7rem
          .name
            min-height: 5.3rem
        .details
          transition: all 0.1s ease-out
          min-height: 9.7rem
