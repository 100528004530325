@import 'src/sass/_variables'

.ui-select-reminder
  display: flex
  flex-direction: column
  .reminder-container
    .reminder-box
      display: flex
      align-items: center
      width: 28rem
      margin-bottom: 0.8rem
      &:only-child
        margin-bottom: 0
      &:last-child
        margin-bottom: 0
      .delete-reminder
        position: relative
        top: 1.6rem
        margin-left: auto
        line-height: 0
        &:hover
          cursor: pointer
          .icons-close
            path
              fill: $red
      .select-reminder
        .ui-select-single
          width: 21rem

  .add-reminder
    +btn-add
    width: 18rem
    margin-top: 1.6rem
