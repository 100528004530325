@import 'src/sass/_variables'

.tmv2-layers-control
  position: absolute
  left: 1.5rem
  bottom: 1.5rem
  border-radius: 0.2rem
  font-size: 1.4rem

  &.suspended
    bottom: calc(4.4rem + 1.5rem)

  .toggle-button
    display: flex
    align-items: center
    justify-content: center
    width: 12rem
    background: white
    cursor: pointer
    padding: 0.4rem 0.8rem
    box-shadow: rgb(0 0 0 / 30%) 0 0.1rem 0.4rem -0.1rem

    .icons-layers
      path
        fill: $ink-lighter

    &.on
      .icons-layers
          path
            fill: $rombit-blue-600

    div
      margin: 0 0.4rem

    svg
      width: 2.4rem
      height: 2.4rem

  .layers
    position: relative
    bottom: 0.4rem
    background: white
    border-radius: 0.2rem
    box-shadow: rgb(0 0 0 / 30%) 0 0.1rem 0.4rem -0.1rem

    .layer
      display: flex
      align-items: center
      height: 3.2rem
      cursor: pointer
      padding: 0 1.6rem

      &:hover
        background: $sky-light
