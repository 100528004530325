@import 'src/sass/_variables'

.more-alerts
  .modal
    .content
      align-items: flex-start
      margin: 0 0  3.2rem
      > span .success
        width: 100%
      .undefined
        align-self: start
        text-align: left
      .title
        &.notification,
        &.success
          width: 100%
      .container-actions
        margin-top: 3.2rem
        > div
          justify-content: flex-start
          padding: 0
          margin-bottom: 1.6rem
          &:last-child
            margin-bottom: 0
          > span
            padding-left: 0.4rem
          > div
            &:last-child
              width: 9rem
