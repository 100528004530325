@import 'src/sass/_variables'

.backoffice-firmware
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: calc(100vh - 12rem)
  .content-container
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    margin: 0 auto
    .input-container
      margin-bottom: 2rem
    .ui-input
      width: 25rem
      margin-bottom: 1rem
    label
      font-size: 1.6rem
  .info-message
    width: 100%
    margin-bottom: 1.6rem
    font-size: 1.4rem
    line-height: 2rem
    color: $ink
  .version-container
    display: flex
    flex-direction: column
    width: 100%
    margin: auto
    margin-bottom: 2rem
    font-size: 1.6rem
    ul
      margin-left: 3rem
    li
      margin: 1rem 0
  .last-update-info
    margin-top: 1rem
    font-size: 1.1rem
    color: $ink-light
