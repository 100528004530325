@import 'src/sass/_variables'

.backoffice-blank-state
  .blank-state
    width: 100%
    height: calc(100vh - 29rem)
    background-color: $white
    margin-top: 3rem
    margin-right: 0
    .message-content
      display: flex
      align-items: center
      flex-direction: column
      max-width: 65rem
      .title
        max-width: 65rem
        margin-bottom: 1rem
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
