@import 'src/sass/_variables'

.docking-container
  +base-card
  display: flex
  align-items: center
  justify-content: space-around
  width: 100%
  max-width: 117.2rem
  +browser-max-zoom-heigth(760px)
    height: calc(100vh - 36rem)
  +browser-max-zoom-heigth(680px)
    height: calc(100vh - 36rem)

  &.charging-panel-layout
    padding: 4rem 0
    .docking-slots
      display: grid
      grid-template-columns: repeat(8, 1fr)
      grid-template-rows: repeat(7, 4rem)
      grid-gap: 0.8rem 0.4rem
      justify-items: center
      align-items: center
      width: 95%
      +browser-max-zoom-heigth(720px)
        grid-gap: 0.4rem 2rem
      +browser-max-zoom-heigth(680px)
        grid-template-rows: repeat(7, 3.6rem)
        grid-gap: 0 2rem
      // First row
      .ui-docking-slot:nth-child(1)
        grid-column: 1/2
      .ui-docking-slot:nth-child(2)
        grid-column: 2/3
      .ui-docking-slot:nth-child(3)
        grid-column: 3/4
      .ui-docking-slot:nth-child(4)
        grid-column: 6/7
      .ui-docking-slot:nth-child(5)
        grid-column: 7/8
      .ui-docking-slot:nth-child(6)
        grid-column: 8/9

      // second row
      .ui-docking-slot:nth-child(7)
        grid-column: 1/2
      .ui-docking-slot:nth-child(8)
        grid-column: 2/3
      .ui-docking-slot:nth-child(9)
        grid-column: 3/4
      .ui-docking-slot:nth-child(10)
        grid-column: 6/7
      .ui-docking-slot:nth-child(11)
        grid-column: 7/8
      .ui-docking-slot:nth-child(12)
        grid-column: 8/9

      // third row
      .ui-docking-slot:nth-child(13)
        grid-column: 1/2
      .ui-docking-slot:nth-child(14)
        grid-column: 2/3
      .ui-docking-slot:nth-child(15)
        grid-column: 3/4
      .ui-docking-slot:nth-child(16)
        grid-column: 6/7
      .ui-docking-slot:nth-child(17)
        grid-column: 7/8
      .ui-docking-slot:nth-child(18)
        grid-column: 8/9
      .charging-panel-details:last-child
        display: flex
        align-items: center
        justify-content: center
        grid-column: 4 / 6
        grid-row: 1 / 4
        width: calc(100% - 3rem)
        height: calc(100% - 1.2rem)
        background-color: $sky
        margin: 0.4rem 1.8rem
        border: 0.2rem solid $ink-lightest
        border-radius: 0.8rem
        > p
          font-size: 1.2rem
          text-align: center
          color: $ink


  &.docking-layout
    .docking-slots
      display: grid
      grid-template-columns: repeat(10, 1fr)
      grid-template-rows: repeat(7, 4rem)
      grid-gap: 0.8rem 2.2rem
      justify-items: center
      align-items: center
      +browser-max-zoom-heigth(720px)
        grid-gap: 0.4rem 2rem
      +browser-max-zoom-heigth(680px)
        grid-template-rows: repeat(7, 3.6rem)
        grid-gap: 0 2rem
      // First row
      .ui-docking-slot:nth-child(1)
        grid-column: 2/4
      .ui-docking-slot:nth-child(2)
        grid-column: 4/6
      .ui-docking-slot:nth-child(3)
        grid-column: 6/8
      .ui-docking-slot:nth-child(4)
        grid-column: 8/10
      .ui-docking-slot:nth-child(5)
        grid-column: 10/12
      .ui-docking-slot:nth-child(6)
        grid-column: 12/14
      .ui-docking-slot:nth-child(7)
        grid-column: 14/16

      // Second row
      .ui-docking-slot:nth-child(8)
        grid-column: 3/5
      .ui-docking-slot:nth-child(9)
        grid-column: 5/7
      .ui-docking-slot:nth-child(10)
        grid-column: 7/9
      .ui-docking-slot:nth-child(11)
        grid-column: 9/11
      .ui-docking-slot:nth-child(12)
        grid-column: 11/13
      .ui-docking-slot:nth-child(13)
        grid-column: 13/15

      // /*  Third row */
      .ui-docking-slot:nth-child(14)
        grid-column: 2/4
      .ui-docking-slot:nth-child(15)
        grid-column: 4/6
      .ui-docking-slot:nth-child(16)
        grid-column: 6/8
      .ui-docking-slot:nth-child(17)
        grid-column: 8/10
      .ui-docking-slot:nth-child(18)
        grid-column: 10/12
      .ui-docking-slot:nth-child(19)
        grid-column: 12/14
      .ui-docking-slot:nth-child(20)
        grid-column: 14/16

      // /*  Fourth row */
      .ui-docking-slot:nth-child(21)
        grid-column: 1/3
      .ui-docking-slot:nth-child(22)
        grid-column: 3/5
      .ui-docking-slot:nth-child(23)
        grid-column: 5/7
      .ui-docking-slot:nth-child(24)
        grid-column: 7/9
      .ui-docking-slot:nth-child(25)
        grid-column: 9/11
      .ui-docking-slot:nth-child(26)
        grid-column: 11/13
      .ui-docking-slot:nth-child(27)
        grid-column: 13/15
      .ui-docking-slot:nth-child(28)
        grid-column: 15/17

      // /*  Fifth row */
      .ui-docking-slot:nth-child(29)
        grid-column: 2/4
      .ui-docking-slot:nth-child(30)
        grid-column: 4/6
      .ui-docking-slot:nth-child(31)
        grid-column: 6/8
      .ui-docking-slot:nth-child(32)
        grid-column: 8/10
      .ui-docking-slot:nth-child(33)
        grid-column: 10/12
      .ui-docking-slot:nth-child(34)
        grid-column: 12/14
      .ui-docking-slot:nth-child(35)
        grid-column: 14/16

      // /*  Sixth row */
      .ui-docking-slot:nth-child(36)
        grid-column: 1/3
      .ui-docking-slot:nth-child(37)
        grid-column: 3/5
      .ui-docking-slot:nth-child(38)
        grid-column: 5/7
      .ui-docking-slot:nth-child(39)
        grid-column: 7/9
      .ui-docking-slot:nth-child(40)
        grid-column: 9/11
      .ui-docking-slot:nth-child(41)
        grid-column: 11/13
      .ui-docking-slot:nth-child(42)
        grid-column: 13/15
      .ui-docking-slot:nth-child(43)
        grid-column: 15/17

      // /*  Seventh row */
      .ui-docking-slot:nth-child(44)
        grid-column: 2/4
      .ui-docking-slot:nth-child(45)
        grid-column: 4/6
      .ui-docking-slot:nth-child(46)
        grid-column: 6/8
      .ui-docking-slot:nth-child(47)
        grid-column: 8/10
      .ui-docking-slot:nth-child(48)
        grid-column: 10/12
      .ui-docking-slot:nth-child(49)
        grid-column: 12/14
      .ui-docking-slot:nth-child(50)
        grid-column: 14/16
