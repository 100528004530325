@import src/sass/_variables

.docking-header
  .ui-resume-bar
    .values-box
      min-width: 24rem
      margin-left: 4rem
      &:first-child
        margin-left: 0
      .quantity
        padding-left: 1.6rem
