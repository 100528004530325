@import 'src/sass/_variables'

.people-management-blank-state
  .blank-state
    width: 100%
    height: calc(100vh - 18.8rem)
    background-color: $white
    margin-top: 1rem
    margin-right: 0
    .message-content
      display: flex
      align-items: center
      flex-direction: column-reverse
      max-width: 65rem
      .title
        max-width: 65rem
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
    .icons-box
      display: flex
      align-items: center
      justify-content: center
    .icons-person,
    .icons-machine
      width: 8rem
      height: 8rem
      path
        fill: $ink-light
