@import 'src/sass/_variables'

.ui-select-frequencies
  display: flex
  flex-direction: column
  margin-bottom: 1.6rem
  &:last-child
    margin-bottom: 0
  .frequency-container
    margin-bottom: 1.6rem
    &:empty
      margin-bottom: 0
    .frequency-box
      display: flex
      align-items: center
      margin-bottom: 0.8rem
      &:only-child
        margin-bottom: 0
      .delete-frequency
        position: relative
        top: 1.5rem
        cursor: pointer
        margin-right: 0.4rem
        margin-left: auto
      .add-time-frequency
        position: relative
        top: 1.5rem
        display: flex
        align-items: center
        font-size: 1.2rem
        color: $rombit-blue-600
        > div:first-child
          &:hover
            cursor: pointer
            text-decoration: underline
        > div:last-child
          display: flex
          align-items: center
        .icons-information
          path
            fill: $rombit-blue-600
      > .select-common
        width: 25rem
        margin-right: 1.6rem

      .ui-time-picker
        margin-right: 1.6rem

      .ui-input-combo
        margin-bottom: 0
        .ui-input
          width: 8rem
          margin-bottom: 0

  .add-frequency
    +btn-add

  .invalid-frequency
    background-color: $rombit-blue-100
    padding: 0.8rem 0.8rem 0.8rem 1.6rem
    margin-top: 1.6rem
    border: 0.1rem solid $rombit-blue-200
    border-radius: 0.4rem
    font-size: 1rem
    color: $rombit-blue-600
