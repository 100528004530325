@import 'src/sass/_variables'

.routes-analytics
  height: 100vh
  .container
    display: flex
    flex-flow: row nowrap
    // Screen - Topbar
    height: calc(100vh - 6.4rem)
    .ui-menu
      height: 100%
      padding: 1.6rem
      border-right: 0.1rem solid $sky-light
      .ui-menu-options
        height: calc(100vh)
        .menu-elements
          display: flex
          flex-direction: column
          height: 100%
          .ui-title
            margin-bottom: 1.6rem
            h2
              min-height: 1.8rem
              color: $ink-lightest
      .ui-collapsable-container
        margin-bottom: 0.8rem
        > div:first-child:empty
          display: none
        .ui-collapsable
          margin-bottom: 0.8rem
          .collapsable-header
            height: initial
            padding: 0.8rem 1.2rem 0.8rem 1.6rem
            margin-bottom: 0
            border-radius: 0.8rem
            > div:nth-child(1)
              line-height: 2.4rem
            .collapsable-icon
              line-height: 0
              svg
                path
                  fill: $ink-lighter
            .collapsable-title
              font-size: 1.4rem
              line-height: 2rem
              color: $ink-lighter
            > div:last-child
              line-height: 0
              div:first-child
                display: none
          .ui-box-content
            .internal-item
              padding: 0.8rem 1.6rem
              border-left: 0.4rem solid transparent
              border-radius: 0.8rem
              font-size: 1.4rem
              line-height: 2rem
              color: $ink-lighter
              &:hover
                background-color: $sky
                cursor: pointer
                color: $ink-light
              &.active-dashboard
                background-color: #E2F1FF
                border-left: 0.4rem solid #B9DDFF
          &:hover
            background-color: $sky
            border-radius: 0.8rem
            .collapsable-header
              .collapsable-icon
                svg
                  path
                    fill: $ink-light
              .collapsable-title
                color: $ink-light
              .collapsable-arrow
                svg
                  path
                    fill: $ink-light
          &.active,
          &.header-active
            background-color: $rombit-blue-100
            border-radius: 0.8rem
            .collapsable-header
              margin-bottom: 0.8rem
              .collapsable-icon
                svg
                  path
                    fill: $rombit-blue-800
              .collapsable-title
                font-weight: $fw-semibold
                color: $rombit-blue-800
              .collapsable-arrow
                svg
                  path
                    fill: $rombit-blue-800
    .single-menu-item
      display: flex
      align-items: center
      padding-block: 0.8rem
      padding-left: 1.6rem
      svg
        flex-shrink: 0
        margin-right: 0.8rem
        path
          fill: $ink-lighter
      span
        font-size: 1.4rem
        line-height: 2rem
        color: $ink-lighter
      &.active,
      &.active:hover
        background-color: $rombit-blue-100
        svg
          path
            fill: $rombit-blue-700
        span
          font-weight: $fw-semibold
          color: $rombit-blue-700
      &:hover
        background-color: $sky
        cursor: pointer
        border-radius: 0.8rem
        span
          color: $ink-light
        svg
          path
            fill: $ink-light

    .btn-collapse
      display: flex
      align-items: center
      flex-flow: row nowrap
      align-self: flex-end
      padding: 0.8rem 1.6rem
      margin-top: auto
      border-radius: 0.8rem

      &.collapsed
        align-items: center
        justify-content: center
        width: 4.2rem
        height: 4.2rem
        padding: 0
        margin-left: 0 !important

      span
        margin-left: 0.8rem
        font-size: 1.4rem
        color: $ink-lighter
      &:hover
        background-color: $sky
        cursor: pointer
        span
          color: $ink-light
        svg
          path
            fill: $ink-light
    .title-space
      display: flex
      align-items: center
      justify-content: center
      width: 4.2rem
      height: 3.2rem
      .single-menu-item
        width: 4rem
        padding-left: 0.8rem

    .menu-expanded
      .ui-menu
        width: 24.8rem
      & + .frame-wrapper
        width: calc(100% + -8.8rem)

    .menu-collapsed
      background: gold
      .ui-menu
        width: 6rem
        padding: 1.6rem 0.8rem 1.6rem 1rem //optical
        .ui-collapsable
          width: 4rem
          height: 4rem
          .collapsable-header
            padding: 0.8rem
            .collapsable-title
              display: none
            .collapsable-arrow
              display: none
          .ui-box-content
            display: none

        .menu-elements
          .ui-title
            display: none

      & + .frame-wrapper
        flex-basis: 100%
        iframe
          height: calc(100vh + -6.4rem)

      .btn-collapse
        align-self: flex-start
        margin-top: auto
        margin-left: 1.2rem
        span
          display: none
    .frame-wrapper
      & > header
        display: flex
        align-items: center
        justify-content: space-between
        flex-flow: row nowrap
        padding: 0 1.6rem
        margin-top: 1.6rem
        margin-bottom: 1.6rem
        .ui-title
          flex-basis: 18rem
          h2
            font-weight: $fw-semibold
        .analitycs-items-select
          .ui-select-single
            width: 28rem
        .ui-row.checkbox
          flex-basis: 16rem
          margin-right: 3rem
          margin-bottom: 0
          label
            white-space: nowrap
        .ui-table-toolbar
          width: initial
          .btn-export
            height: 3.2rem
            padding: 0 1.2rem
            margin-right: 0.8rem
            svg
              width: 1.6rem
              height: 1.6rem
            &:hover
              svg
                path
                  fill: $rombit-blue-800
          .btn-filters
            height: 3.2rem
            padding: 0 1.2rem
            > div
              display: flex
              align-items: center
              font-weight: $fw-regular
            svg
              width: 1.6rem
              height: 1.6rem
              margin-right: 0.8rem
              path
                fill: $white

      iframe
        width: 100%
        background-color: $white
        border: 0

  .analytics-block
    padding: 3.2rem 1.6rem 2.4rem
    overflow: hidden scroll
    header
      .title-and-text
        .ui-title
          h2
            font-weight: $fw-semibold

  .analitycs-items-select
    .ui-select-single
      width: 28rem
      margin-right: 0.8rem
      margin-left: 0.8rem
      .item-label
        +item-label
      .select-single__single-value
        .item-label
          +item-label-sizes(9.6rem, 5.6rem, 7.8)
      .select-single__menu-list
        .item-label
          +item-label-sizes(9.4rem, 8.4rem)

.analytics-block
  display: flex
  flex-direction: column
  width: 100%
  padding-top: 2.4rem
  header
    display: flex
    justify-content: space-between
    margin-bottom: 2.4rem
    .title-and-text
      .ui-title
        margin-bottom: 0.8rem
      .subtitle
        font-size: 1.4rem
        line-height: 2rem
        color: $ink-light
    .search-combo
      display: flex
      align-items: center
      .ui-input
        width: 24rem
        > div
          height: 3.2rem
        input
          height: 3.2rem
      .ui-button
        margin-left: 0.8rem
  .create-modal-reports,
  .edit-modal-reports
    .modal
      height: calc(100vh - 12.2rem)
      overflow: visible auto
    .content
      margin-block: 0
      .form
        width: 100%
        .ui-row
          margin-bottom: 2rem
          label
            line-height: 1.6rem
          &.report-name
            .ui-input
              input
                height: 3.2rem
            .tip
              &.error
                text-align: left
      .modal-select-row
        display: grid
        grid-template-columns: 1fr 1fr
        grid-column-gap: 1.6rem
        label
          margin-bottom: 0.8rem
          font-size: 1.4rem
          line-height: 2rem
          color: $ink
      .ui-row.datepicker-combo
        align-items: flex-start
        margin-bottom: 2.4rem
        .DateInput.DateInput_1
          width: 12rem
      .resume-text
        margin-top: -1.6rem
        text-align: left
    .separator
      margin-bottom: 1.6rem
      border-top: none
      border-bottom: 0.1rem solid $sky
    .report-receivers
      .ui-title
        margin-bottom: 1.6rem
      .email-action
        margin-bottom: 2.4rem
        .ui-input
          display: flex
          flex-direction: column
        .inside-input-btn
          top: 3rem
        .tip
          text-align: left
      .receiver-tag-title
        margin-bottom: 0.8rem
        font-size: 1.4rem
        text-align: left
        line-height: 2rem
      .receiver-tag-wrapper
        display: flex
        justify-content: flex-start
        flex-flow: row wrap
    .modal-buttons
      display: flex
      justify-content: flex-end
      flex-direction: row
      margin-bottom: 0
  .edit-modal-reports
    &.delete-report
      .modal
        height: initial
        overflow: hidden
      .actions
        margin-top: 1.6rem

  .report-cards-block
    display: grid
    grid-gap: 1.6rem
    grid-template-columns: 1fr 1fr 1fr

  .blank-state
    width: 100%
    height: calc(100vh - 20rem)
    padding: 0 3.6rem
    svg
      width: 4.8rem
      height: 4.8rem
      path
        fill: $ink-light
    .title
      font-size: 1.6rem
      font-weight: $fw-bold
      line-height: 2.4rem
      color: $ink-light
    .text
      font-size: 1.4rem
      font-weight: $fw-regular
      text-align: center
      line-height: 2rem
      color: $ink-light
      &:empty
        margin: 0
        line-height: 0

.filters-frame-wrapper
  &.show-filters
    .analytics-filter-backdrop
      position: absolute
      z-index: 6 // Has to be over the Evacuation Button and side bar
      top: 0
      left: 0
      display: block
      width: 100vw
      height: 100vh
      background-color: transparentize($ink, 0.6)

.settings-section
  &.collapsed
    padding-top: 1rem
    border-top: 0.1rem solid $sky

  & > .title-space
    /* stylelint-disable-next-line declaration-no-important */
    height: 4.2rem !important
    margin-bottom: 0.8rem

  .single-menu-item
    margin-bottom: 0.8rem


.settings-days-without-accidents
  width: 100%
  padding: 4.8rem
  & > .container
    width: 100%
    /* stylelint-disable-next-line declaration-no-important */
    height: auto !important
    padding: 0 !important
    margin: 0 !important
    +scrollable-container
    .box
      +base-card
      position: relative
      display: flex
      justify-content: flex-start
      flex-direction: column
      width: 100%
      padding: 4.8rem
      margin-bottom: 4.8rem
      // Card title
      .ui-title.larger
        margin-bottom: 2.4rem
      .ui-title.medium
        margin-bottom: 0.8rem
      > .subtitle
        font-size: 1.4rem
        line-height: 2rem
        color: $ink-light

      .helper-text
        margin: 0.8rem 0 0
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-lighter

      .ui-input-number
        margin: 1.6rem 0
        .ui-input
          input
            border-right: 0.1rem solid $input-border-color
            border-left: 0.1rem solid $input-border-color
            border-radius: $input-border-radius
            &:hover
              border-color: $input-border-color-hover

      .switch-option
        margin-bottom: 2.4rem

        .subtitle
          margin-bottom: 0.8rem
        > div
          display: flex
          align-items: center
          > span
            margin-left: 1.6rem
            font-size: 1.4rem
            line-height: 2rem
            color: $ink-light
          > .ui-switch
            margin-top: 0.8rem

    .analytics-block
      border-top: 0.1rem solid $sky
