@import 'src/sass/_variables'
@import 'src/routes/map/live/satellite/styles.sass'

.map-view
  &.evacuation
    .map-satellite
      .google-maps
        height: calc(100% - 4.6rem)
    .layers-menu
      padding-bottom: 2.4rem
      .dropdown-content
        &.show
          bottom: 10rem
  .sidebar
    max-width: 25rem
    height: 100vh
    background-color: $sky-lighter
  .ui-side-panel.edit-zone
    // zIndex should be greater or equal than Evacuation's zIndex
    z-index: 5
    top: $topbar-height
    width: 25.6rem
    > div
      width: 25.6rem
      height: calc(100vh - 6.4rem)
      background-color: $sky-light
      overflow-y: hidden
      margin-bottom: 6.4rem
      > .ui-button
        display: flex
        align-items: center
        width: 100%
        margin-bottom: 2.4rem
  .ui-side-panel.modal-open
    z-index: 3
  .info-tooltip
    margin-left: 15rem
    > div
      z-index: 1
  .toast
    position: absolute
    z-index: 4
    left: 1.6rem
    bottom: 10rem
    margin: 0
    > div
      position: static
      min-width: 20rem
      background-color: $white
      box-shadow: 0 0.1rem 0.4rem $ink-lightest
      span
        font-weight: $fw-bold
        color: $ink
        white-space: nowrap
      svg
        path
          fill: $rombit-blue-600

  .list-map-items,
  .no-map-items,
  .create-zone
    > .ui-button
      display: flex
      align-items: center
      width: 100%
      height: 4rem
      margin-bottom: 2.4rem
  .create-zone,
  .create-poi,
  .no-map-items
    width: 25.6rem
    padding: 0 1.6rem
    margin-top: 0
    overflow: hidden scroll

  .list-map-items
    width: 100%
    min-width: 25.6rem
    max-width: 25.6rem
    height: inherit
    padding: 1.6rem 1.2rem 1.2rem
    margin-top: 0

  .list-map-items > .ui-button,
  .ui-side-panel.edit-zone .buttons .ui-button
    display: flex
    align-items: center
    width: 100%
    height: 4rem
    margin-bottom: 2rem

  .ui-title
    > div
      display: flex
      align-items: center
      justify-content: flex-end
    .ui-checkbox
      display: flex
      align-items: center
      justify-content: center

  .routes-map-canvas
    z-index: 0
    display: flex
    flex-direction: row
    height: calc(100vh - #{$topbar-height})
    padding-top: 5rem
    .canvas
      margin-top: 2rem
      margin-left: 2rem
      canvas
        border: 0.1rem solid $rombit-blue-900
      .tooltip
        position: absolute
        top: 50%
        left: 50%
        opacity: 0.5
        width: 20rem
        background-color: $black
        cursor: pointer
        padding: 1rem
        border-radius: 0.4rem
        font-size: 1.1rem
        text-align: center
        color: $white

  .partial-expanded
    & + .live-map-toast
      bottom: 27.4rem
      transition: bottom 600ms

  .full-expanded
    // Overriding here to maintain style scoped
    /* stylelint-disable-next-line declaration-no-important */
    overflow-y: auto !important
    & + .live-map-toast
      bottom: 56.4rem
      transition: bottom 600ms

  .map-filters
    > div:first-child
      overflow-y: hidden
