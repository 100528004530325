@import 'src/sass/_variables'

.ui-tabs
  display: flex
  justify-content: space-between
  border-bottom: 0.1rem solid $sky-dark
  > div
    display: flex
  > div:first-child
    > div
      display: flex
      align-items: center
      flex-flow: row nowrap
      cursor: pointer
      padding: 0.8rem 1rem
      border-bottom: 0.2rem solid transparent
      font-size: 1.6rem
      color: $ink-light
      > span
        padding: 0 0.4rem
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 1.6rem
        color: $ink-lighter
      &:hover
        border-bottom: 0.2rem solid $sky-dark
      &.selected
        border-bottom: 0.2rem solid $rombit-blue-600
        span
          font-weight: 700
          color: $ink
          &.items-count
            color: $ink-light
      &.disabled
        border-bottom: unset
