@import 'src/sass/_variables'

// Groups table
.contractors-table
  .ui-table
    .group-bullets-options
      padding: 0.1rem 0.5rem
      border-radius: 0.8rem
    .contractor-name
      width: 100%
      .wrapped-text
        p,
        label
          text-overflow: ellipsis
          overflow: hidden
          white-space: nowrap
        label
          cursor: pointer
          font-size: 1.2rem
          color: $ink-light

    .group-icons
      display: flex
      align-items: center
      justify-content: flex-start
      flex-flow: row nowrap
      .place-top
        margin-top: 0
      > div
        transform: scale(0.75) // result 24x24
        height: 3.2rem
        &:first-child
          z-index: 2
          margin-left: 0
        &:last-child
          z-index: 1
          margin-left: -1.2rem
        &:only-child
          z-index: 2
          margin-left: 0

      svg
        circle
          stroke-width: 0.1rem
          stroke: $white
        path
          transform-origin: center

    .rdt_TableBody
      .rdt_TableRow
        > div:last-child
          display: flex
          justify-content: flex-end
