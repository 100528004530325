.ui-label-tooltip
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
  .wrapped-text
    display: block
    width: 100%
  > div
    display: flex
    [class^="icons-"]
      margin-right: 0.8rem
      line-height: 0
