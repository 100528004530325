@import 'src/sass/_variables'

.due-date
  width: 100%
  margin-bottom: 3.2rem

  .due-date-box
    display: flex
    align-items: baseline
    flex-direction: row
    .box
      display: flex
      justify-content: center
      flex-direction: column

      label
        margin-bottom: 0.4rem
        text-align: left
        line-height: 2rem

      .ui-input
        width: 100%
        max-width: 13rem
        margin-top: -0.8rem
        margin-left: 1.4rem

      .ui-input-combo
        margin-bottom: 0
        .row
          .ui-select-single
            top: 1.6rem

      .ui-single-date-picker
        .SingleDatePicker
          .SingleDatePickerInput
            justify-content: space-between
            width: 18rem
          .DateInput
            height: 3.4rem
            .DateInput_input
              width: 12rem
              height: 3.4rem
          &_calendarIcon
            height: 3.4rem

  .info-text
    position: absolute
    height: 2rem
    margin: 0
    font-size: 1.2rem
    text-align: left
    color: $ink-lighter
    font-style: italic
