@import 'src/sass/_variables'

.update-profile
  width: 33rem
  margin-right: auto
  margin-left: auto
  color: $ink-light
  .title
    font-size: 1.8rem
  h2
    margin: 2.4rem 0 2.5rem
    font-size: 2rem
    font-weight: 700
    text-align: center
    line-height: 2.4rem
    color: $ink-light
  .react-tel-input
    .country-list
      width: 28rem
    input
      width: 28rem

  .group-label-password
    width: 28rem
  form
    width: 28rem
    margin-right: auto
    margin-left: auto
    .group-label
      margin-bottom: 1.6rem
      text-align: center
      > label
        font-size: 1.4rem
        color: $ink-lightest
      .label-editable
        margin-bottom: 1.6rem
        font-size: 2rem
        font-weight: 700
        .ui-input
          input
            font-size: 2rem
            font-weight: 700
            text-align: center
            line-height: 2.4rem
            color: $ink
    .ui-phone-input
      margin-bottom: 1.6rem

    .ui-input
      margin-bottom: 1.6rem
      label
        margin-bottom: 0.4rem
        line-height: 2rem

    .group-input
      margin: 0 0 1.8rem
      label
        display: block
        width: 28rem
        margin-bottom: 0.4rem
        font-size: 1.4rem
        text-align: left
        line-height: 2rem
    .charge
      align-self: flex-end
      width: 40rem
      text-align: left
    .button
      display: flex
      justify-content: center
      margin: 2.6rem 0 2.4rem
