@import 'src/sass/_variables'

.ui-table-header
  position: relative
  z-index: 2
  width: 100%
  height: 5.6rem
  background-color: $white
  padding: 1.2rem 1.6rem
  margin-bottom: 0.8rem
  border-radius: 0.4rem
  .table-header-content
    .ui-select-single
      width: 24rem
  + .ui-table
    .rdt_TableBody
      max-height: calc(100vh - 33.8rem)

.status-buttons
  + .cases-table
    .ui-table
      .rdt_TableBody
        max-height: calc(100vh - 32.8rem)

.ui-table
  .rdt_Table,
  .rdt_TableHead,
  .rdt_TableHeadRow,
  .rdt_TableHeadRow .rdt_TableCol
    border-radius: 0.4rem 0.4rem 0 0

  // Move vertical scroll from main wrapper to table-body
  > div:first-child,
  > .ui-table-component
    overflow-y: hidden

  .rdt_TableHeadRow
    z-index: 1
    height: 4rem
    min-height: 4rem // override
    margin-right: 0.6rem // compensation to avoid the horizontal displacement of the cells

    .rdt_TableCol,
    .rdt_TableCol_Sortable
      > div
        &:not([class*="sort_icon"])
          font-size: 1.4rem
          font-weight: $fw-semibold
          line-height: 2.4rem
          white-space: nowrap
        [class*="sort_icon"]
          line-height: 1
          > svg
            position: relative
            top: 0.2rem
      &:hover,
      &:focus
        color: $ink

  .rdt_TableBody
    overflow-y: scroll
    overflow-x: hidden
    max-height: calc(100vh - 27.4rem)

  .rdt_TableRow
    &:hover
      background-color: $sky-light
      outline-color: $sky-dark
      border-bottom-color: $sky-dark
    .rdt_TableCol
      > div
        font-size: 1.4rem
        font-weight: $fw-bold
        line-height: 1.6rem
        color: $ink
  .rdt_TableCell
    > div
      max-width: 100%
      font-size: 1.4rem
      line-height: 1.6rem
      color: $ink-light // presentar en la meet
      &.inner-ellipsis
        +ellipsis
      &.ui-label-tooltip
        .ellipsis
          width: 100%
      .certificates-icon
        position: relative
        display: flex
        margin-right: 1.6rem
        .icons-certificate-file
          width: 2.4rem
          height: 2.4rem
        .attach-bubble
          position: absolute
          top: -0.8rem
          left: 2.2rem
          display: flex
          align-items: center
          justify-content: center
          width: 1.6rem
          height: 1.6rem
          background-color: $rombit-blue-600
          border-radius: 50%
          font-size: 1.2rem
          color: $white

      .icons-check,
      .icons-close
        width: 2.4rem
        height: 2.4rem

      .icons-close
        path
          fill: $red

      .icons-check
        path
          fill: $green
    .nowrap
      white-space: nowrap

  .rdt_Pagination
    button
      &:hover
        background-color: transparent
    > span:nth-child(1),
    > div:nth-child(2)
      display: none
    #pagination-first-page,
    #pagination-previous-page,
    #pagination-next-page,
    #pagination-last-page
      svg
        fill: $ink-lightest
      &:disabled
        svg
          fill: $sky-dark

// Responsive tables
// =================
.certificates-table
  .ui-table
    .rdt_TableHeadRow .rdt_TableCol,
    .rdt_TableBody .rdt_TableRow .rdt_TableCell
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4)
        max-width: 24rem
        +browser-min-zoom-width(1366px)
          max-width: 22rem
          +browser-min-zoom-width(1460px)
            max-width: 24rem
          +browser-min-zoom-width(1500px)
            max-width: initial

.items-table
  .ui-table
    .rdt_TableHeadRow .rdt_TableCol,
    .rdt_TableBody .rdt_TableRow .rdt_TableCell
      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(6)
        +browser-min-zoom-width(1366px)
          max-width: 24rem
          +browser-min-zoom-width(1440px)
            max-width: 26rem
          +browser-min-zoom-width(1500px)
            max-width: initial

        &:nth-child(2)
          +browser-min-zoom-width(1366px)
            max-width: 11rem
          +browser-min-zoom-width(1440px)
            max-width: initial

        &:nth-child(3),
        &:nth-child(4)
          +browser-min-zoom-width(1366px)
            max-width: 15rem
          +browser-min-zoom-width(1500px)
            max-width: initial

.settings-table
  .ui-table
    .rdt_TableHeadRow .rdt_TableCol,
    .rdt_TableBody .rdt_TableRow .rdt_TableCell

      &:nth-child(1),
      &:nth-child(4)
        +browser-min-zoom-width(1366px)
          max-width: 24rem
          +browser-min-zoom-width(1500px)
            max-width: initial

        &:nth-child(2)
          +browser-min-zoom-width(1366px)
            max-width: 16rem
          +browser-min-zoom-width(1440px)
            max-width: 20rem
          +browser-min-zoom-width(1500px)
            max-width: initial

        &:nth-child(3)
          +browser-min-zoom-width(1366px)
            max-width: 17rem
          +browser-min-zoom-width(1440px)
            max-width: 20rem
          +browser-min-zoom-width(1500px)
            max-width: initial

        &:nth-child(5)
          +browser-min-zoom-width(1366px)
            max-width: 17rem
          +browser-min-zoom-width(1440px)
            max-width: 20rem
          +browser-min-zoom-width(1500px)
            max-width: initial
