@import 'src/sass/_variables'

.manual-case-tab-detail
  form
    > section
      display: flex
      padding: 1.6rem 2.4rem 0.8rem
      &:empty
        padding: 0
  section
    .ui-input
      width: 32rem
    .ui-row
      margin-bottom: 0.8rem
    .ui-input label,
    .ui-row label
      display: block
      margin-bottom: 0.4rem
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 2rem
      color: $ink-light
    &.date-category
      .manual-case-picker
        width: fit-content
        .ui-single-date-picker
          margin-right: 1.6rem
          .SingleDatePicker
            width: 17.8rem

      // Eventually simplify this
      .select-label
        +select-pill($bg: $ink-lighter, $input-w: 19.2rem, $menu-w: 20.8rem)
        label
          display: block
          margin-bottom: 0.8rem
          font-size: 1.2rem
          font-weight: $fw-regular
          line-height: 2rem
          color: $ink-light

    &.region-zone
      > div
        margin-right: 1.6rem
        > label
          display: block
          margin-bottom: 0.4rem
          font-size: 1.2rem
          font-weight: $fw-regular
          line-height: 2rem
          color: $ink-light
      .ui-select-single
        width: 17.8rem
    &.items-selector
      > div
        margin-right: 1.6rem
        > label
          display: block
          margin-bottom: 0.4rem
          font-size: 1.2rem
          font-weight: $fw-regular
          line-height: 2rem
          color: $ink-light
      .ui-select-single
        width: 32rem

    &.item-pills
      .ui-pill
        margin-right: 0.8rem

  .select-assignees
    margin-top: 1.6rem
    margin-left: 2.4rem
    .select-rule-assignees
      margin-right: 1.6rem
      > label
        display: block
        margin-bottom: 0.4rem
        font-size: 1.2rem
        font-weight: $fw-regular
        line-height: 2rem
        color: $ink-light
    .ui-select-multi
      width: 32rem
    .tip
      width: 100%
      min-height: 1.8rem
      font-size: 1.2rem
      font-style: italic
      text-align: left
      line-height: 1.8rem
      color: $ink-lightest
  .due-date
    position: relative
    margin: 0.8rem 0 1.6rem 2.4rem
    .due-date-box
      margin-bottom: 0.4rem
      .box
        label
          font-size: 1.2rem

  .buttons
    display: flex
    align-items: center
    justify-content: flex-start
    padding-block: 2.4rem
    margin-top: auto
    margin-left: 2.4rem
    button
      margin-right: 1.6rem

    .ui-button
      flex-shrink: 0
      width: fit-content

      &.small
        padding-left: 0
