@import 'src/sass/_variables'

.ui-top-notification
  position: absolute
  z-index: 1
  top: 12rem
  left: 50%
  display: flex
  align-items: center
  justify-content: center
  transform: translateX(-50%)
  background-color: $ink-light
  padding: 0.8rem 2.4rem
  border-radius: 0.4rem
  white-space: nowrap
  color: $sky
  &.withCloseIcon
    padding: 0.8rem 2.4rem
  .notification-text
    font-size: 1.4rem
  .close-button
    cursor: pointer
    margin: 0rem -1.2rem 0rem 1.6rem
    line-height: 0
    svg
      width: 1.6rem
      height: 1.6rem
      path
        fill: $white
  &.error
    background-color: $red
    box-shadow: 0 0.4rem 0.4rem transparentize($sky, 0.75)
    color: $white
  &.warning
    background-color: $orange
    color: $white
  &.success
    background-color: $green
    color: $white
  &.default
    path
      fill: $sky-lighter
  &.top
    top: 12rem
  &.bottom
    position: relative
    top: 1.6rem
    width: fit-content
