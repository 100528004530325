@import 'src/sass/_variables'

.dot-devices-table
  // Column charge
  .device-charge
    display: flex
    align-items: center
    justify-content: space-between
    flex-wrap: nowrap
    svg
      path
        fill: $ink-lightest
    > div:first-child
      line-height: 0
    > span
      flex-shrink: 0
      width: 4.1rem
      height: 1.8rem
      white-space: nowrap
    .icons-charging
      width: 1.6rem
      height: 1.6rem
      margin: auto 0.8rem auto 0
    .not-charging
      width: 0
      &.other-charging
        width: 2.4rem
    // Charge colors
    .very-low
      color: $red-darker
    .low
      color: $orange-darker
    .mid
      color: $orange
    .high
      color: $green
    .charge-good,
    .complete
      color: $green-darker

  // REMOVE ONCE ALL DEVICES STATUS PILLS ARE CONSISTENT
  .ui-status
    padding: 0.4rem 1rem
    svg
      width: 1.6rem
      height: 1.6rem
    span
      font-weight: $fw-bold
      white-space: nowrap

    &.theme-red
      background-color: $red-lighter
      span
        color: $red-darker
      svg
        path
          fill: $red-darker

    &.theme-green
      background-color: $green-lighter
      span
        color: $green-darker
      svg
        path
          fill: $green-darker

    &.theme-sky
      background-color: $sky
      span
        color: $ink-light
      svg.icons-close
        width: 1.6rem
        height: 1.6rem
        path
          fill: $ink-light
