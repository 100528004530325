@import 'src/sass/_variables'

.groups
  .ui-row
    margin-bottom: 0.8rem
  .create-container
    display: flex
    align-items: center
    margin-bottom: 0.8rem
    > div
      &:first-child
        width: 100%

  .create-container,
  .groups-container
    .ui-select-single
      .group-label
        display: flex
        align-items: center
        .star-group
          cursor: pointer
          margin-right: 0.8rem
          line-height: 0

  .groups-container
    > div:first-child
      .icons-star
        path
          fill: $rombit-blue-600
          stroke: $rombit-blue-600

  .ui-resizable-container
    .view-more
      &:before
        content: ""
        position: absolute
        top: -1.2rem
        width: 100%
        height: 1rem
        background: linear-gradient(to bottom, transparent, $sky-light 50%)
