@import 'src/sass/_variables'

.routes-backoffice-members
  margin-top: 1rem
  .buttons
    display: flex
    justify-content: space-between
  .ui-list
    max-height: calc(100vh - 15rem)
    margin-top: 1rem
    margin-right: -4rem
    .row
      padding: 1.75rem 1rem //remove if edit and delete buttons are added.
    .field
      &.name
        &:hover
          text-decoration: none
      cursor: default
  .content-page
    display: flex
    height: calc(100vh - 14rem)
  .wrapper
    flex: 1
    .users-permissions-list
      height: 100%
      overflow-y: scroll
  .container
    margin: 1rem 1.6rem
    .settings-header
      display: flex
      justify-content: space-between
