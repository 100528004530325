@import 'src/sass/_variables'

=title
  > h3
    display: flex
    align-items: center
    margin-bottom: 1.6rem
    font-size: 1.6rem
    line-height: 2rem
    color: $ink-light
    svg
      margin-left: 0.4rem

.routes-productivity
  height: calc(100vh - 5rem)
  .productivity-processes,
  .productivity-process
    > .container
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      flex-wrap: wrap
      margin: 2.6rem 8rem 0
      overflow: auto
      > .row
        flex-direction: column
        width: 100%
        margin-bottom: 2rem
        +title
      .process
        width: 100%
        margin-bottom: 2.4rem
        +title
        > div
          display: flex
          justify-content: flex-start
