@import 'src/sass/_variables'

.routes-devices
  .container
    margin: 2rem 4.4rem 4.8rem 3.2rem
    .ui-table-toolbar
      .ui-search-input
        input
          padding-right: 1.6rem
    .devices-header
      .docking-toolbar
        width: 100%
        max-width: 117.2rem
        .area-select
          .select-single__menu
            z-index: 2
    .devices-filters,
    .dot-filters
      .filters
        .switch-box
          border-top: unset
        .content
          .ui-tag
            > span.very-low,
            > span.low,
            > span.mid,
            > span.high,
            > span.complete
              margin-right: 0.8rem
              line-height: 0
            .icons-charging
              width: 1.6rem
              height: 1.6rem

        // Charge colors
        .very-low
          .icons-charging
            path
              fill: $red-darker
        .low
          .icons-charging
            path
              fill: $orange-darker
        .mid
          .icons-charging
            path
              fill: $orange
        .high
          .icons-charging
            path
              fill: $green
        .complete
          .icons-charging
            path
              fill: $green-darker

  .installed-switch
    max-width: 12rem
    margin-bottom: 0
     // because of tooltip
    .ui-switch
      label
        min-width: 8.4rem
        margin-bottom: 0
