@import 'src/sass/_variables'

.tmv2-filters-tags
  padding: 0 2.4rem 1.6rem
  font-size: 1.2rem
  .showing-elements
    display: flex
    span
      margin-right: 1.2rem
    .clear-selection
      cursor: pointer
      color: $rombit-blue-600
