@import "./_colors"
@import "./_typography"
@import "./_inputs"
@import "./_sidepanel"
@import "./_mixins"

$base-unit: 0.8rem

$sidebar-width: 7 * $base-unit
$topbar-height: 8 * $base-unit
