@import 'src/sass/_variables'

.ui-bullets
  &.disabled
    [class^="icons-three-dots"]
      opacity: 0.5
    .ui-dropdown-menu
      cursor: not-allowed
  &.medium
    .bullets
      width: 3.2rem
      height: 3.2rem
      .icons-three-dots
        line-height: 0
        path
          fill: $ink
  &.small
    .bullets
      width: 2rem
      height: 2rem
      .icons-three-dots
        width: 2rem
        height: 2rem
        > svg
          height: 1.2rem
  .bullets
    display: flex
    align-items: center
    justify-content: center
  .bullets.active,
  .clickeable:hover
    background-color: $sky
    border-radius: 0.4rem
