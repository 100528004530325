@import 'src/sass/_variables'

.ui-pagination
  display: flex
  justify-content: space-between
  margin-top: 1.6rem
  .ui-button
    &.icon.disabled
      .icons-pagination-arrow-left-end,
      .icons-pagination-arrow-left,
      .icons-pagination-arrow-right-end,
      .icons-pagination-arrow-right
        path
          fill: $sky-dark
