@import 'src/sass/_variables'

.ui-loading
  position: absolute
  z-index: 9999
  top: 0
  left: 0
  display: flex
  visibility: visible
  width: 100%
  height: 100%
  overflow: hidden
  background: transparentize($white, 0.25) no-repeat 50% 50%
  .loading-card
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    width: 32rem
    background-color: $white
    padding: 1.6rem
    margin: auto
    border-radius: 0.8rem
  .graphs-rombit-r
    animation: beat 3s 0ms ease-in-out forwards infinite
    margin-bottom: 0.8rem
  .ui-title
    justify-content: center
  .loading-container
    width: 90%
    height: 0.2rem
    background-color: $white
    margin-bottom: 0.8rem
    overflow: hidden

  .loading-bar
    width: 0%
    height: 100%
    background-color: $r-orange
    animation: loading 3s ease-in-out infinite

@keyframes loading
    0%
      width: 0%
    100%
      width: 100%

@keyframes beat
  0%
    transform: scale(1)
  25%
    transform: scale(0.85)
  50%
    transform: scale(1)
  75%
    transform: scale(0.85)
  100%
    transform: scale(1)
