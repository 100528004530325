@import 'src/sass/_variables'

.contractors-updates
  // To mimic placeholder styles
  .uses-placeholder
    .ui-update-avatar
      .ui-avatar
        width: 10rem
        height: 10rem
        background-color: $sky-dark
        border-radius: 50%
      img[src="/contractor.svg"]
        width: 4.8rem
        height: 4.8rem
        border-radius: 0
