@import 'src/sass/_variables'
@import 'src/routes/map/time-machine/map/styles.sass'

.time-machine
  .controls
    display: flex
    align-items: center
    justify-content: flex-end
    width: 100%
  .container
    display: flex
    flex-direction: column

    .content
      position: relative
      display: flex
      width: 100%
      height: 100vh
      background-color: $sky
      .statistics-canvas
        .statistics-map-controls
          position: relative
          top: -4.4rem
          width: 100%
    &.filtering
      .content
        position: relative
        .statistics-satellite,
        .statistics-canvas
          .statistics-map-controls
            position: relative
            top: -4.4rem
            transition: width 0.3s
            width: calc(100% - 38.4rem)
        .statistics-canvas
          .statistics-map-controls
            left: -19.2rem // ¯\_(ツ)_/¯
    .statistics-satellite,
    .statistics-canvas
      width: 100%
      .google-maps
        width: 100%
        height: calc(100% - 5rem)
        // reference to zoom controls on the bottom
        .gm-bundled-control-on-bottom
          // gives us extra space for video controls and to expand height of page.
          bottom: 13rem !important /* stylelint-disable-line declaration-no-important */
      .statistics-map-controls
        position: relative
        top: -4.4rem
        width: 100%
        background-color: transparent
        border: none
        // SLIDER
        > div:first-child
          display: flex
          justify-content: center
          width: 100%
          height: fit-content
          background-color: rgba(33, 43, 54, 0.5)
          cursor: pointer
          .rc-slider
            padding: unset
            border-radius: unset
          .rc-slider-rail
            background-color: $sky
          .rc-slider-track
            background-color: $red
          .rc-slider-handle
            background-color: $red
            border-color: $red
        // CONTROLS ICON (PLAY / FULLSCREEN)
        > div:nth-child(2)
          position: relative
          display: flex
          align-items: center
          width: 100%
          height: 3rem
          background-color: rgba(33, 43, 54, 0.5)
          padding-bottom: 0.5rem
          > div
            cursor: pointer
          // PLAY / PAUSE ICON
          > div:first-child
            width: 4rem
            .icons-play,
            .icons-pause
              margin-top: -0.3rem
              margin-left: 0.8rem
              path
                fill: $white
          // TIMESTAMP
          > div:nth-child(2)
            width: 13rem
            margin-left: 2rem
            font-size: 1.2rem
            color: $white
          > div:nth-child(3)
            display: flex
            justify-content: flex-end
            width: 100%
            // VELOCITY
            > div:first-child
              display: flex
              align-items: center
              justify-content: center
              width: 3.1rem
              height: 2rem
              margin-right: 2rem
              border-radius: 0.4rem
              font-size: 1.2rem
              font-weight: 700
              color: $white
            // FULL-SCREEN
            > div:nth-child(2)
              width: 1.8rem
              height: 1.8rem
              margin-right: 2rem

  .map-filters
    .boxes
      .box
        &.active
          .content
            max-height: calc(100vh - 47.2rem)
