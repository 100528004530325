@import 'src/sass/_variables'

.rule-card
  display: flex
  flex-direction: column
  margin-bottom: 4rem
  user-select: none
  &.connected
    margin-bottom: 0
  &.in-step
    .card
      background-color: $rombit-blue-100
      outline: 0.1rem solid $rombit-blue-600
      .ui-title
        h2
          color: $rombit-blue-600
  &.disabled
    .card
      background-color: $sky-dark
      cursor: not-allowed
  .card
    position: relative
    display: flex
    flex-direction: column
    width: 100%
    min-height: 10rem
    background-color: $white
    cursor: pointer
    border-radius: 0.8rem
    box-shadow: 0 0 1.2rem 0 $sky-dark
    font-size: 1.2rem
    &:active
      background-color: $sky-light
    .head
      position: absolute
      top: -1rem
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      width: 100%
      .bullet
        display: flex
        align-items: center
        justify-content: center
        width: 11rem
        height: 2.4rem
        background-color: $sky
        border-radius: 10rem
        font-size: 1.2rem
        font-weight: $fw-bold
        text-transform: uppercase
        line-height: 1.2rem
        color: $ink-light
      .completed
        position: absolute
        align-self: flex-end
        width: 2.4rem
        height: 2.4rem
        background-color: $white
        border-radius: 50%
        .icons-circle-filled-check
          +circle-filled-green
        .icons-circle-filled-cross
          +circle-filled-red
    .body
      display: flex
      width: 100%
      padding: 1rem 1.6rem 1.6rem
      margin-top: 1.6rem
      .card-error
        display: flex
        align-items: center
        .icons-circle-filled-exclamation
          flex-shrink: 0
          width: 1.6rem
          height: 1.6rem
          margin-right: 0.4rem
          path:first-child
            fill: $red
          path:nth-child(2)
            fill: $white
        span
          color: $red

      .side
        display: flex
        margin-right: 1.6rem
        svg
          width: 2.4rem
          height: 2.4rem
      .center
        display: flex
        flex-direction: column
        flex-grow: 1
        .title
          display: flex
          align-items: center
          max-width: 26rem
          > span
            font-size: 1.4rem
            font-weight: $fw-bold
            color: $ink-light
            +ellipsis(16.8rem)
            +browser-max-zoom-width(1280px)
              max-width: 13rem
          .ui-priority
            margin-right: 0.4rem
            svg
              width: 1.6rem
              height: 1.6rem
              margin-right: 0
          &.editing
            > span
              color: $rombit-blue-600
        .subtitle
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-lighter
          &.wrapped-text
            width: initial
            max-width: 28rem
            white-space: nowrap
        > div.card-error
            display: flex
            align-items: center
            .icons-circle-filled-exclamation
              flex-shrink: 0
              width: 1.6rem
              height: 1.6rem
              margin-right: 0.4rem
              +circle-filled-red
            span
              font-size: 1.2rem
              font-weight: $fw-bold
              line-height: 1.6rem
              color: $red
        .ui-assignees
          margin: 0.4rem 0 0
          span
            color: $ink-lighter
            +ellipsis(22rem)
      .trash
        display: flex
        align-items: center
        justify-content: center
        width: 2.4rem
        height: 2.4rem
        cursor: pointer
        border-radius: 50%
        &:hover
          background-color: $sky
        &:active
          background-color: $sky-dark
        .icons-trash-filled
          width: 1.6rem
          height: 1.6rem

  &.trigger
    &.area-related
      .side
        path
          fill: $orange
    &.safety
      .side
        path
          fill: $rombit-blue-700
    &.drixit-spotter
      .side
        path
          fill: $indigo-light
    &.people-management
      .side
        path
          fill: $red
    &.permits-control,
    &.manual
      .side
        path
          fill: $teal
    &.productivity
      .side
        path
          fill: $yellow
    &.covid-19,
    &.others
      .side
        path
          fill: $lime
    &.device-status
      .side
        path
          fill: $purple
    &.reports
      .side
        path
          fill: $indigo

  .connector
    align-self: center
    width: 0.1rem
    height: 4rem
    border-left: 0.1rem dashed $sky

  .plus
    display: flex
    align-items: center
    justify-content: center
    align-self: center
    width: 4.8rem
    height: 4.8rem
    cursor: pointer
    border-radius: 50%
    box-shadow: 0 0.2rem 1rem 0 $sky-dark
    &:hover
      background-color: $sky-light
    &:active
      background-color: $sky-lighter
    svg
      path
        fill: $ink-lighter
