@import 'src/sass/_variables'

.ui-update-avatar
  width: 33rem
  margin: 2.4rem 0 1rem
  .row
    align-items: center
    flex-direction: column
    .image
      display: flex
      justify-content: center
      margin: 0 auto 2rem
      &:hover .icon
        visibility: visible
      .icon
        visibility: hidden
        position: absolute
        cursor: pointer
        margin: -1rem 0 0
        svg
          width: 1rem
      img
        display: inline-block
        width: 10rem
        height: 10rem
    button
      background-color: transparent
      cursor: pointer
      border: 0.1rem solid $rombit-blue-500
      color: $rombit-blue-500
