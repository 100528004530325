@import 'src/sass/_variables'

.productivity-processes
  > .container
    > .row
      align-items: flex-start
      flex-direction: column
      width: 100%
      margin-bottom: 2rem
    .process
      width: 100%
      margin-bottom: 2.4rem
      > div
        display: flex
        justify-content: flex-start
        flex-flow: row nowrap
