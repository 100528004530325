@import 'src/sass/_variables'

.routes-spotter-devices
  .ui-side-panel
    .update
      .ui-side-panel-row
        .ui-label
          span:first-child
            font-weight: $fw-bold
        .section-title
          font-size: 1.6rem
          font-weight: $fw-bold
  // spotter table
  .spotter-table
    .ui-table
      .spotter-load-header,
      .spotter-firmware-header
        display: flex
        justify-content: space-between
        line-height: 1.6rem
        > div
          display: flex
          line-height: 2.4rem
          > div
            margin: auto 0
          path
            fill: $sky-dark
        .spotter-load-subtitle
          font-size: 1rem
          color: $sky
      .icons-close
        path
          fill: $red
      .spotter-machine-profile,
      .spotter-driver-profile
        display: flex
        align-items: center
        width: 100%
        [class^="icons-"]
          display: flex
          align-items: center
          justify-content: center
          // Remove once all icons are ok
          width: 1.6rem
          height: 1.6rem
          margin-right: 0.8rem
          border-radius: 50%
          line-height: 0

      // Column firmware
      .spotter-firmware
        display: flex
        align-items: center
        justify-content: space-between
        flex-wrap: nowrap
        width: 6rem
        .icons-close
          path
            fill: $red
        .icons-check
          path
            fill: $green

      // Column position
      .device-position
        display: flex
        align-items: center
        justify-content: center

        .ui-button,
        .no-position-container
          display: flex
          align-items: center
          justify-content: center
          width: 3.6rem
          height: 3.6rem

          svg.icons-my-location
            width: 2rem
            height: 2rem
            margin: 0
            &.active
              path
                fill: $green

      // Column activity
      .spotter-activity
        display: flex
        align-items: center
        flex-flow: row nowrap
        .active,
        .inactive,
        .reporting
          flex-shrink: 0
          margin-right: 0.4rem
          line-height: 0
        .active
          .icons-dot
            path
              fill: $green
        .reporting
          .icons-dot
            path
              fill: $rombit-blue-700
        .inactive
          .icons-dot
            path
              fill: $ink-lightest
        span
          white-space: nowrap
