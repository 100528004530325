@import 'src/sass/_variables'

.routes-create-announcements
  height: 100vh
  padding-bottom: 20rem
  overflow: scroll
  .disable-buttons
    .ui-steps
      .step
        &:hover
          cursor: default
  > div:nth-child(1)
    width: 80%
    max-width: 67rem
    margin: auto
    >.ui-button:nth-child(1)
      margin-top: 4rem
  .actions
    margin-top: 1rem
    .ui-row
      width: 100%
      button
        div
          display: flex
          align-items: center
          justify-content: space-between
    .action-btn
      display: flex
      button:first-child
        margin-right: 1rem
    .icons-arrow-left
      path
        fill: $rombit-blue-600
    .row
      margin-bottom: 0
  .form
    +base-card
    padding: 4.8rem
    margin: 0
    &.details
      label
        font-size: 1.4rem
        line-height: 1.6rem
        color: $ink-lighter
      >
        .ui-row:nth-child(1)
          margin-bottom: 4.8rem
        .ui-row:nth-child(2)
          margin-bottom: 8rem
      .type-item
        display: flex
        align-items: center
        span
          width: 2rem
          height: 2rem
          background-color: $red-darker
          margin-right: 1.6rem
          margin-left: 0.6rem
          border-radius: 50%
        &.events
          span
            background-color: $green
        &.human-resources
          span
            background-color: $orange
        &.trainning
          span
            background-color: $indigo
    &.recipients
      span
        font-size: 1.4rem
        line-height: 1.6rem
        color: $ink
      .row
        margin-top: 1.6rem
        margin-bottom: 0
        label
          margin-bottom: 0
      .ui-input
        flex-direction: column
      .ui-select-multi
        z-index: 2
      .ui-label
        > span:nth-child(2)
          font-weight: 700
          color: $ink
      .ui-table
        .rdt_TableBody
          .icons-trash-filled
            width: 1.6rem
            height: 1.6rem
    &.content
      .ui-editor
        margin-top: 0.8rem
    &.summary
      font-size: 1.6rem
      line-height: 2rem
      .row
        margin-bottom: 1.6rem
        .title
          margin: 0
      .ui-label
        > span:nth-child(2)
          font-weight: 700
          color: $ink
      .preview
        padding: 1rem
        border: 0.1rem solid $sky
        border-radius: 0.4rem
      .ui-table
        .rdt_TableBody
          .icons-trash-filled
            width: 1.6rem
            height: 1.6rem
    .ui-title
      margin-bottom: 0.8rem
    .ui-table
      border: 0.2rem solid $sky
      border-radius: 0.4rem
  .select-date
    .ui-single-date-picker
      width: 100%
      margin-right: 0.8rem
      .SingleDatePicker
        width: 100%
      .DateInput
        width: 100%

  .buttons
    position: relative
    display: flex
    align-items: center
    width: 100%
    > .ui-button:nth-child(1)
        margin-right: 2.4rem
        &:only-child
          margin-right: 0
    > .ui-button:not(:last-child)
      margin-right: 0.8rem
      margin-left: auto
    > .ui-button:first-child
      margin-left: 0
  .details-row
    .ui-label
      margin-bottom: 1rem
  .steps-container
    margin-bottom: 4rem
