@import 'src/sass/_variables'

.routes-map
  .statistics-canvas
    position: relative
    display: flex
    align-items: center
    flex-direction: column
    width: 100%
    background-color: $white
    > div:first-child
      width: 100%
      height: calc(100% - 8.4rem)
    canvas
      border: 0.1rem solid $rombit-blue-900

    .statistics-map-controls
      z-index: 10
      width: 100%
      background-color: $sky-lighter
      margin-top: -1.3rem
      border: 0.2rem solid $rombit-blue-900
      border-top: none
      border-radius: 0.4rem
      // SLIDER
      > div:first-child
        display: flex
        justify-content: center
        width: 100%
        height: fit-content
        background-color: $sky
        cursor: pointer
        .rc-slider
          padding: unset
          border-radius: unset
        .rc-slider-rail
          background-color: rgba(0, 0, 0, 0.3)
        .rc-slider-track
          background-color: $red
        .rc-slider-handle
          background-color: $red
          border-color: $red
      // CONTROLS ICON (PLAY / FULLSCREEN)
      > div:nth-child(2)
        position: relative
        display: flex
        align-items: center
        width: 100%
        height: 3rem
        background-color: $sky
        padding-bottom: 0.5rem
        > div
          cursor: pointer
        // PLAY / PAUSE ICON
        > div:first-child
          width: 4rem
          .icons-play,
          .icons-pause
            margin-top: -0.3rem
            margin-left: 0.8rem
            path
              fill: $white
        // TIMESTAMP
        > div:nth-child(2)
          width: 15rem
          margin-left: 2rem
          font-size: 1.2rem
          color: $ink-light
        // VELOCITY
        > div:nth-child(3)
          display: flex
          justify-content: flex-end
          width: 100%
          // VELOCITY
          > div:first-child
            display: flex
            align-items: center
            justify-content: center
            width: 3.1rem
            height: 2rem
            background-color: $ink-light
            margin-right: 2rem
            border-radius: 0.4rem
            font-size: 1.2rem
            font-weight: $fw-bold
            color: $white
          // FULL-SCREEN
          > div:nth-child(2)
            width: 1.8rem
            height: 1.8rem
            margin-right: 2rem
