@import 'src/sass/_variables'

.ui-tree
  > ul
    > .list-item
      > .list-row:first-child
        margin-bottom: 0.2rem
        .item-checkbox:empty
          margin-right: 0
        .item-info
          .item-name
            max-width: 100%

  .list-item
    padding: 0.1rem 0.3rem 0.2rem 0
    margin: 0 -0.3rem 0 0
    border: 0.1rem solid transparent
    font-size: 1.2rem
    line-height: 1.6rem
    color: $ink-light
    > .list-row
      display: flex
      align-items: center
      &.list-header
        margin-bottom: -0.3rem
      > .item-checkbox
        margin-right: 0.8rem
        .control
          margin-right: 0
      > .item-info
        display: flex
        align-items: center
        cursor: pointer
        .icons-arrow
          vertical-align: sub

        > .item-icon
          margin: 0
          margin-right: 0.8rem
          line-height: 0.8rem
          > [class^="icons-"]
            height: 1.6rem

        > .item-name
          max-width: 100%
          margin-right: 0.4rem
          color: $ink-light
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

      // bullet
      > div:nth-child(4)
        background-color: $sky
        padding: 0.1rem 0.8rem
        border-radius: 0.8rem
        color: $ink-lightest

      // edit
      > div:last-child
        align-items: center
        margin-right: 0
        color: $rombit-blue-600
        div
          cursor: pointer
          margin-right: 0
        .ui-bullets
          .component
            width: 2rem
            height: 2rem
            background: transparent
          .dropdown-content.show
            margin-top: 0

    // Nested content
    .tree-children
      margin-left: 0
      .list-item
        padding-left: 2.2rem
        &:hover
          background-color: $sky
      .list-row
        .item-info
          > .item-icon
            > div[style]
              width: 1.2rem
              margin-right: 0.2rem
              margin-left: 0.2rem
              border-radius: 0.2rem
          > .item-name
            margin-top: 0
            &:first-letter
              text-transform: capitalize
        .item-codename
          margin-right: 0.4rem
          margin-left: auto
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          color: $ink-lightest
