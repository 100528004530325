@import 'src/sass/_variables'

.ui-badge
  display: flex
  align-items: center
  span
    display: flex
    align-items: center
    font-size: 1.2rem
    font-weight: 400
    line-height: 1.6rem
    color: $ink-lighter
    &:last-child,
    &:only-child
      padding: 0 0.8rem
      margin-left: 0.4rem
      border-radius: 0.8rem
  &.light
    span:last-child
      background-color: $sky
      color: $ink-lighter
  &.dark
    span:last-child
      background-color: $sky-dark
      color: $ink-lighter
