@import 'src/sass/_variables'

.ui-accordion
  position: absolute
  bottom: 0
  width: calc(100% - 4.8rem)
  &:not([class*="-expanded"])
    .icons-chevron-down
      svg
        fill: $ink-light
  > div:first-child
    display: flex
    align-items: center
    justify-content: space-between
    height: 4.6rem
    background-color: $sky
    padding: 1.4rem 1rem 1.4rem 1.6rem
    border-radius: 0.4rem 0 0
    h2
      display: flex
      align-items: center
      justify-content: flex-start
      font-size: 1.4rem
      font-weight: $fw-bold
      line-height: 1.6rem
      color: $ink
    > div
      display: flex
      align-items: center
      line-height: 0
      &.chevron-left
        flex-direction: row-reverse
      &:hover
        cursor: pointer
      [class^="icons-"]
        width: 2.4rem
        height: 2.4rem
        path
          fill: $ink

  // collapsible content
  > div:nth-child(2)
    transition: height 600ms
    width: 100%
    height: 0
    background-color: $sky-light
    overflow: hidden
    &.partial-expanded
      transition: height 800ms
      height: 24rem
    &.full-expanded
      transition: height 800ms
      height: 50rem
      +browser-max-zoom-heigth(680px)
        height: 36rem
