@import 'src/sass/_variables'

.ui-pill
  display: flex
  align-items: center
  justify-content: center
  flex-flow: row nowrap
  width: fit-content
  height: 2.4rem
  background-color: $sky
  padding: 0 1.2rem 0 0.8rem
  border-radius: 1.2rem
  > [class^="icons"]
    width: 1.6rem
    height: 1.6rem
    margin-right: 0.4rem
    line-height: 0
    path
      fill: $ink-light

  // For better visual match
  .icons-tag,
  .icons-region,
  .icons-person,
  .icons-machine-cog
    opacity: 0.85

  span
    font-size: 1.2rem
    line-height: 1.6rem
    white-space: nowrap
    color: $ink-lighter
  &:hover
    cursor: default

  // For pills with label tooltip as child content
  .wrapped-text
    .ui-label-tooltip
      > div
        > div
          max-width: 100%
