@import 'src/sass/_variables'

.ui-search-input
  position: relative
  display: flex
  align-items: center
  .search-icon-wrapper
    width: 2.4rem
    height: 2.4rem
    line-height: 2.4rem
    svg
      position: absolute
      z-index: 1
      margin-left: 0.8rem
      fill: $ink-lightest
      pointer-events: none
  &.regular-size
    .search-icon-wrapper
      position: relative
      right: -3rem
      svg
        margin: 0
        pointer-events: all
  .ui-input
    width: 100%
    input
      padding-right: 3.3rem
      padding-left: 4rem
      &::placeholder
        opacity: 1 // Firefox
        color: $input-placeholder-color
      &:focus
        padding-left: 3.9rem
        border: 0.2rem solid $rombit-blue-600

  // close to clear
  > div:nth-child(3)
    position: absolute
    right: 1.2rem
    line-height: 0
    svg
      position: static
      width: 1.6rem
      height: 1.6rem
      fill: $ink-lightest
      margin: 0
    &:hover
      cursor: pointer
      svg
        path
          fill: $ink
