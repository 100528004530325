@import 'src/sass/_variables'

.ui-toolbar
  position: relative
  display: flex
  align-items: center
  justify-content: left
  width: 100%
  height: auto
  min-height: 4.8rem
  background-color: $white
  padding: 1.1rem 2.4rem 0.9rem
  margin: auto
  margin-bottom: 1rem
  border-left: 0.8rem solid $rombit-blue-700
  border-radius: 0.4rem
  box-shadow: 0 0.1rem 0.4rem transparentize($black, 0.75)
  white-space: normal
  font-size: 1.4rem
  line-height: 2rem
  color: $ink-light
