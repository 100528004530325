@import 'src/sass/_variables'

.routes-worker-licenses
  .worker-licenses-table
    margin: 0
    margin-top: 1rem
    .ui-table
      .contractor-name
        display: block
        font-size: 1.2rem
        line-height: 1.6rem
      .rdt_TableCell:nth-child(4)
        > div
          display: flex
          align-items: center
          width: auto
          .icons-close
            path
              fill: $red
          .icons-check
            path
              fill: $green
      .license-state
        margin-left: 0.8rem
        font-size: 1.4rem
        line-height: 1.6rem
