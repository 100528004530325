@import 'src/sass/_variables'

.ui-select-zones
  margin: 0 0 1.6rem
  > label
    +common-label
  > div
    width: 100%
    .zones-value
      display: flex
      .color
        width: 1rem
        height: 1rem
        margin-right: 1rem
      div
        &:nth-child(2)
          margin-left: 1.6rem
    .zones-option
      > div
        display: flex
        align-items: center
      .color
        width: 1rem
        height: 1rem
        margin-right: 1rem
    .zones-menu
      > div
        display: flex
        width: 100%
        > div
          position: relative
          width: 100%
  .tip
    display: block
    width: 100%
    min-height: 1.8rem
    margin-top: 0.4rem
    margin-bottom: -0.4rem
    font-size: 1.2rem
    line-height: 1.8rem
    color: $red
