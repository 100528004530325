@import 'src/sass/_variables'

.ui-link
  height: auto
  background-color: transparent
  cursor: pointer
  padding: 0
  border-bottom: 0.1rem dashed $ink-light
  font-weight: $fw-bold
  text-decoration: none
  line-height: 1.6rem
  color: $ink-light
  pointer-events: all
