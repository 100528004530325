@import 'src/sass/_variables'

.routes-backoffice-configuration-updates
  .copy-box
    margin-top: 2.4rem
  .device-config
    .config-group
      padding: 1rem
      margin-bottom: 1rem
      border: 0.2rem solid $sky-dark
      border-radius: 0.4rem
      &:hover
        cursor: pointer
      label
        margin-bottom: 1.4rem
  span.tip.error
    margin-top: 0.4rem
    font-size: 1.2rem
    color: $red

.multi-config
  margin-top: 2rem
.button-actions
  margin-top: 1.4rem
  margin-bottom: 1.4rem

.select-field
  width: 100%
  > div:first-child
    flex: 1
    max-width: 26rem
    margin-right: 1.4rem
  .single-config
    position: relative
    top: 3.4rem
    flex: 1
    margin-bottom: 1.4rem

.config-field
  padding: 0.2rem 0
  padding-left: 0.4rem
  margin: 0.4rem 0
  border-radius: 0.2rem
  &:hover
    background-color: $sky
    cursor: pointer
