@import 'src/sass/_variables'

.ui-icon-box
  display: flex
  align-items: center
  justify-content: center
  width: 4rem
  height: 4rem
  cursor: pointer
  svg
    width: 1.6rem
    height: 1.6rem
  path
    fill: $teal
  > div
    display: flex
    align-items: center
    justify-content: center
    width: 4rem
    height: 4rem
    text-align: center
    line-height: 0
  &.trigger,
  &.action
    border-radius: 50%
  &.trigger
    border-radius: 50%
    &.area-related
      background-color: $orange-lighter
      path,
      circle
        fill: $orange
    &.drixit-spotter
      background-color: $indigo-lighter
      path,
      circle
        fill: $indigo
    &.safety
      background-color: $rombit-blue-100
      path,
      circle
        fill: $rombit-blue-700
    &.people-management
      background-color: $red-lighter
      path,
      circle
        fill: $red
    &.permits-control
      background-color: $teal-lighter
      path,
      circle
        fill: $teal
    &.productivity
      background-color: $yellow-lighter
      path,
      circle
        fill: $yellow
    &.covid-19,
    &.others
      background-color: $lime-lighter
      path,
      circle
        fill: $lime
    &.device-status
      background-color: $purple-lighter
      path,
      circle
        fill: $purple
    &.reports
      background-color: $indigo-lighter
      path,
      circle
        fill: $indigo
    &.manual
      background-color: $teal-lighter
      path,
      circle
        fill: $teal

  &.action
    background-color: $green-lighter
    font-size: 1.4rem
    font-weight: 700
    color: $green
    &:nth-child(3)
      margin-left: 0
    path
      fill: $green
