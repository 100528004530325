@import 'src/sass/_variables'

.routes-map
  .header
    .title
      > div
        display: flex
      .save-filters
        cursor: pointer
        margin-left: 1.6rem
        p
          font-size: 1.2rem
          color: $rombit-blue-700
          &.disabled
            cursor: not-allowed
            color: $ink-lightest

  .ui-side-panel
    .tags
      > div:first-child
        display: flex
        span
          margin-right: 1.2rem
        > div
          cursor: pointer
          color: $rombit-blue-700
          &.disabled
            cursor: not-allowed
            color: $ink-lightest
    .boxes
      display: flex
      flex-direction: column
      &.boxes-wrapper
        .content
          .ui-tree
            .list-item
              &:hover
                border: 0.1rem solid transparent
            > ul
              > .list-item
                > .list-row
                  .item-info
                    .item-name
                      margin-right: 0.8rem
                      font-weight: $fw-bold

      .box
        .list-item
          > .list-row
            svg
              width: 1.6rem
              height: 1.6rem
            > .item-info
              // icon
              > div:first-child
                line-height: 0

      .events,
      .people
        .item-icon
          margin-right: 0.4rem
        .list-item
          > .list-row
            svg
              width: 1.6rem
              height: 1.6rem
              path
                fill: $ink-light
            > .item-info
              > .item-name
                max-width: 20.5rem
      .my-location
        display: none
        justify-content: flex-end
      .list-item > .list-row
        &:hover
          .my-location
            display: flex
            cursor: pointer
