@import 'src/sass/_variables'

.ui-list
  display: grid
  overflow: auto
  .columns,
  .row
    display: grid
    align-items: center
    justify-content: center
    font-size: 1.4rem
    color: $ink-light
  .list-columns
    position: sticky
    z-index: 1
    top: 0
    display: grid
    height: 5rem
    background-color: $white
    cursor: pointer
    padding: 1rem
    border-bottom: 0.1rem solid $sky
    font-weight: $fw-bold
    > .header
      display: flex
      align-items: center
      font-size: 1.4rem
      font-weight: $fw-semibold
      line-height: 2rem
      color: $ink
      > span
        line-height: 0
  .list-rows
    .row
      padding: 1rem
      &:nth-child(odd)
        background-color: $sky-lighter
        border: none
        border-bottom: 0.1rem solid $sky
      &:nth-child(even)
        background-color: $white
