@import 'src/sass/_variables'

.ui-module-header
  display: flex
  align-items: center
  justify-content: space-between
  flex-direction: row
  width: 100%
  min-height: 4rem
  margin-bottom: 2rem
  > div:only-child
    margin-left: auto
