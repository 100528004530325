@import 'src/sass/_variables'

.routes-invite-signup
  &.common-login
    .wrap
      position: relative
      z-index: 2
      h2,
      .email-text,
      .terms-checkbox
        margin-bottom: 1.6rem
      h2
        font-size: 2rem
        text-align: center
        line-height: 2.4rem
      .email-text
        font-size: 1.6rem
        font-weight: $fw-bold
        text-align: center
        line-height: 2rem
        color: $ink-lightest
      .ui-change-password
        margin-bottom: 1.6rem
        .group-label-password
          margin-bottom: 1.6rem

      .terms-checkbox
        display: flex
        align-items: center
        label
          width: auto
          margin-left: 1rem
          font-size: 1.2rem
      .btn
        display: flex
        justify-content: center
        flex-direction: row
        &.step-password
          align-items: center
          justify-content: center
          flex-direction: column
          .ui-button:first-child
            margin-bottom: 0.4rem
