/* stylelint-disable no-missing-end-of-source-newline */
@import 'src/sass/_variables'

.ui-input-number
  .ui-input-number-row
    display: flex

  button:first-child
    border-top: 0.1rem solid $input-border-color
    border-bottom: 0.1rem solid $input-border-color
    border-left: 0.1rem solid $input-border-color
    border-top-left-radius: 0.4rem
    border-bottom-left-radius: 0.4rem

  button:last-child
    border-top: 0.1rem solid $input-border-color
    border-right: 0.1rem solid $input-border-color
    border-bottom: 0.1rem solid $input-border-color
    border-top-right-radius: 0.4rem
    border-bottom-right-radius: 0.4rem

  button
    width: 3rem
    max-height: 3.6rem
    svg
      width: 2rem
      height: 2rem
      path
        fill: $black
    &.disabled
      color: $black
      svg path
        fill: $sky-dark
    &.icon
      background: $white
      &.disabled
        background: $white
        color: $sky-dark

  .ui-input
    input,
    &:disabled
      width: 100%
      padding: 0
      padding-left: 1rem
      border-radius: 0.4rem
    input.error
      border: 0.1rem solid $red


  .mandatory
      margin-left: 0.4rem
      color: $red