@import 'src/sass/_variables'
.routes-people-management
  .container
    .ui-module-header
      .ui-table-toolbar
        margin-left: auto
      .btn-add-new
        margin-left: 0.8rem
.toast-text
  .certificates-bulk-upload-success,
  .certificates-bulk-upload-error,
  .warning-toast-message
    display: flex
    flex-direction: column
    p:nth-child(1)
      margin-bottom: 0.4rem
    p:nth-child(2)
      margin-bottom: 1.6rem
      font-weight: $fw-regular
    a
      cursor: pointer
      text-decoration: underline
