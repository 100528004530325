@import 'src/sass/_variables'

.pairing-point-table

  // REMOVE ONCE ALL DEVICES STATUS PILLS ARE CONSISTENT
  .ui-status
    padding: 0.4rem 1rem
    svg
      width: 1.6rem
      height: 1.6rem
    span
      font-weight: $fw-bold
      white-space: nowrap

    &.theme-red
      background-color: $red-lighter
      span
        color: $red-darker
      svg
        path
          fill: $red-darker

    &.theme-green
      background-color: $green-lighter
      span
        color: $green-darker
      svg
        path
          fill: $green-darker

    &.theme-sky
      background-color: $sky
      span
        color: $ink-light
      svg.icons-close
        width: 1.6rem
        height: 1.6rem
        path
          fill: $ink-light
