.routes-backoffice-places
  padding: 1rem 2rem 2rem 1rem
  .ui-side-panel
    top: 0
  .ui-title.medium
    margin-left: 1.6rem
  .ui-list
    .columns
      top: -0.4rem
    .field
      text-overflow: ellipsis
      overflow: hidden
  .routes-backoffice-updates
    padding: 2.4rem 3.2rem
    .owner-select
      .select-single__menu
        z-index: 5
    .title
      font-size: 1.8rem
    .ui-title
      margin-bottom: 2.4rem
