@import 'src/sass/_variables'

.ui-toast
  position: fixed
  z-index: 2
  display: flex
  align-items: center
  justify-content: center
  width: fit-content
  max-width: 38.3rem
  height: auto
  min-height: 4.8rem
  border-radius: 0.4rem
  box-shadow: 0 0.4rem 0.4rem transparentize($sky, 0.75)
  white-space: normal
  color: $white
  .toast-text
    font-size: 1.4rem
    font-weight: $fw-bold
    line-height: 2rem
    .toast-message
      font-weight: $fw-regular
      &.icon
        display: flex
        align-items: center
      .icons-information
        path
          fill: $rombit-blue-600
      .icons-information,
      .icons-warning
        flex-shrink: 0
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem

      .icons-warning
        path
          fill: $red
    .toast-action
      cursor: pointer
      margin-top: 1.6rem
      font-weight: $fw-bold
      text-decoration: underline
    .undo
      padding: 1.6rem 1.6rem 1.6rem 0
      margin-bottom: 0.8rem
      font-weight: $fw-regular
      text-decoration: underline
    .undo-message
      color: $red-lighter
  .close-button
    position: absolute
    top: 1.6rem
    right: 1.4rem
    cursor: pointer
    margin-left: 1.6rem
    line-height: 0
    .icons-close
      width: 1.6rem
      height: 1.6rem

  &.right
    animation: slide-in 1s ease forwards
    &.closing
      animation: slide-out 1.2s ease forwards
    &.error,
    &.success,
    &.warning,
    &.info,
    &.disabled
      padding: 1.6rem 3.2rem 1.6rem 2.4rem
      border-radius: 0.8rem 0 0 0.8rem
      &.withCloseIcon
        padding: 1.6rem 4.8rem 1.6rem 2.4rem
    &.error
      border-left: 0.8rem solid $red
    &.success
      border-left: 0.8rem solid $green
    &.warning
      border-left: 0.8rem solid $orange
    &.info
      border-left: 0.8rem solid $rombit-blue-600
    &.disabled
      border-left: 0.8rem solid $ink-lighter
  &.center
    &.error,
    &.success,
    &.warning,
    &.info,
    &.disabled
      padding: 1.6rem 3.2rem 1.6rem 1.6rem
      border-radius: 0.4rem
      &.withCloseIcon
        padding: 1.6rem 5rem 1.6rem 1.6rem
    &.error
      border-left: 0.8rem solid $red
    &.success
      border-left: 0.8rem solid $green
    &.warning
      border-left: 0.8rem solid $orange
    &.info
      border-left: 0.8rem solid $rombit-blue-600
    &.disabled
      border-left: 0.8rem solid $ink-lighter
  &.left
    left: 17.9rem
    &.error,
    &.success,
    &.warning,
    &.info,
    &.disabled
      padding: 1.6rem 2.4rem 1.6rem 3.2rem
      border-radius: 0.8rem 0.8rem 0 0
    &.error
      border-right: 0.8rem solid $red
    &.success
      border-right: 0.8rem solid $green
    &.warning
      border-right: 0.8rem solid $orange
    &.info
      border-right: 0.8rem solid $rombit-blue-600
    &.disabled
      border-right: 0.8rem solid $ink-lighter

  &.middle
    top: 13rem
  &.bottom
    bottom: 5.6rem
  &.top
    top: 7.2rem

  &.primary
    background-color: $ink
    padding: 1.1rem 2.4rem 0.9rem
    color: $white
    .close-button
      svg
        path
          fill: $white
  &.secondary
    background-color: $white
    padding: 1.1rem 2.4rem 0.9rem
    color: $ink-light
    .close-button
      svg
        path
          fill: $ink

@keyframes slide-in
  0%
    right: -100%
  100%
    right: 0

@keyframes slide-out
  0%
    right: 0
  100%
    right: -100%
