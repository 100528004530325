@import 'src/sass/_variables'

.list-map-items
  overflow: hidden scroll
  .ui-tree
    margin-top: 1.6rem

    &:first-child
      margin-top: 2.4rem

    .list-item
      cursor: pointer
      .tree-children
        margin-left: 0
        .list-item
          padding-left: 0
      > .list-row
        .item-info
          pointer-events: none
          cursor: pointer
          > div:first-child
            line-height: 0
          .icons-chevron-right,
          .icons-chevron-down
            width: 1.6rem
            height: 1.6rem
          > .item-name
            max-width: 8.4rem
          .icons-arrow
            margin-right: 0.4rem
        .item-codename
          width: 5.4rem
          pointer-events: none
        .item-icon
          display: flex
          align-items: center
          margin-right: 0.8rem
    // To keep pointer events on first element to collapse the list
    .tree-children
      > .list-item
        > .list-row
          pointer-events: auto
          margin-left: 1.6rem
          .item-checkbox:empty
            margin-right: 0.3rem

  // Bullet menu
  .menu-zone-type
    .ui-button
      width: 100%
    .dropdown-content
      left: 0
      margin-top: 0.8rem
      > div
        height: 5.6rem
        .menu-row
          align-items: center
          [class^=icons-]
            width: 2.4rem
            height: 2.4rem

  .list-map-items-search
    position: relative
    display: flex
    align-items: center
    justify-content: flex-start
    margin: 1.6rem 0

    svg
      path
        width: 1.6rem
        height: 1.6rem

    .icons-search
      position: absolute
      z-index: 1
      left: 0.8rem
      width: 1.6rem
      height: 1.6rem
      path
        fill: $ink
    .close-icon
      position: absolute
      right: 0.8rem
      display: flex
      svg
        width: 1.6rem
        height: 1.6rem
      &:hover
        cursor: pointer
        path
          fill: $ink

    input
      width: 100%
      height: 3rem
      // filter specific
      padding-right: 3.2rem
      padding-left: 3rem
      border: 0.1rem solid $sky-dark
      border-radius: 0.4rem
      font-size: 1.2rem
      &::placeholder
        opacity: 1
      // Firefox
        color: $input-placeholder-color
      &:focus
        border: 0.2rem solid $rombit-blue-600
