@import 'src/sass/_variables'

.user-detail
  .ui-row
    margin-bottom: 2.4rem
    .ui-label
      font-size: 1.4rem
      line-height: 2rem
      span:first-child
        font-weight: $fw-bold
