@import 'src/sass/_variables'

.settings-shifts-and-attendance
  .container
    .header
      display: flex
      width: 100%
      .ui-switch
        flex-shrink: 0
    .panels
      width: 100%
      margin-bottom: 2.4rem
      .ui-panel
        .panel-texts
          > div
            .subtitle
              margin-right: 0.8rem
              font-weight: $fw-regular
              &:nth-child(1)
                width: 7.2rem
              &:nth-child(2)
                width: 13.6rem
                text-align: left
              &:nth-child(3)
                width: 20rem
                text-align: left
        .switch-container
          margin-top: 0
    .box
      .ui-alert
        margin-bottom: 2.4rem
        > div
          > p
            margin-bottom: 0
    .selects-wrapper
      margin-bottom: 0.8rem
      .select-container
        display: flex
        align-items: flex-start
        flex-flow: row nowrap
        margin-bottom: 1.6rem
        .select-clock-method
          width: 38rem
          margin-right: 0.8rem
          > label
            +common-label
        .select-clock-range
          > label
            display: flex
            align-items: center
            +common-label
            svg
              width: 1.6rem
              height: 1.6rem
            > div
              margin-left: 0.4rem
              line-height: 0
              &:hover
                cursor: pointer
      .trigger-helper
        margin-top: -1.2rem
        margin-bottom: 1.6rem
        .helper-text
          font-size: 1.2rem
          font-weight: $fw-regular
          text-align: left
          line-height: 1.4rem
          color: $ink-lighter
        .ui-link
          display: inline
          margin-left: 0.2rem
          border: none
          font-size: 1.2rem
          font-weight: 700
          text-align: left
          line-height: 1.4rem
          color: $rombit-blue-600
          &:hover
            cursor: pointer
            text-decoration: underline
    .box-shift
      .ui-title.medium + p
        margin-bottom: 0

    .separator
      width: 100%
      height: 0.1rem
      background-color: $sky-dark
      margin: 3.2rem 0
    .ui-modal
      .content
        align-items: flex-start
        margin: 0
        .title
          padding: 0
          margin: 0
        .shift-modal
          width: 100%
          color: $ink
          div
            > span,
            > label
              display: block
              margin-bottom: 0.4rem
              line-height: 2rem
              &:empty
                display: none
          > div
            display: flex
            align-items: flex-start
            flex-direction: column
            margin: 1.6rem 0
            > div
              width: 100%

          > .schedule
            align-items: flex-start
            justify-content: space-between
            flex-direction: row
            > div
              display: flex
              align-items: flex-start
              flex-direction: column
              width: inherit
            > div:first-child
              label
                text-align: left
              .ui-select-single
                width: 16.8rem

            // duration combo
            > div:nth-child(3)
              margin-right: 0
              .ui-input-combo
                margin-bottom: 0
                .ui-input
                  width: 4.8rem
                .ui-select-single
                  top: 0
                  width: 9.6rem
                  min-width: 9.6rem

            .ui-time-picker span:empty
                display: unset

          // overtime
          > div:nth-child(4)
            padding-top: 1.6rem
            margin-top: 1.6rem
            margin-bottom: 4.8rem
            border-top: 0.1rem solid $sky // divider
            > h3
              font-size: 1.4rem
              font-weight: 700
              color: $ink-lighter
            //input
            > div
              align-items: flex-start
              flex-direction: column
              text-align: left
              > div
                width: 100%
    .ui-alert
      width: 100%
