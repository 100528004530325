@import 'src/sass/_variables'

.ui-time-picker
  &.with-label
    display: flex
    flex-direction: column
  &.small
    .flatpickr-input
      width: 6.5rem
      height: 3.2rem
  &.medium
    .flatpickr-input
      height: 3.6rem
  label
    font-size: 1.4rem
    line-height: 3rem
    color: $input-label-color
  .flatpickr-input
    width: 9rem
    padding-left: 1.2rem
    border: 0.1rem solid $input-border-color
    border-radius: 0.4rem
    font-size: 1.4rem
    &:hover
      border-color: $input-border-color-hover
    &.active,
    &:active,
    &:focus
      border: 0.1rem solid $rombit-blue-600
      box-shadow: 0 0 0 0.1rem $rombit-blue-600 inset
