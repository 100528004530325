@import 'src/sass/_variables'

.licenses-toolbar
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  >div:first-child
    display: flex
    flex-direction: column
  .back-row
    display: flex
    align-items: center
    justify-content: flex-start
    flex-direction: row
    width: fit-content
    cursor: pointer
    margin: 0 0 0.4rem
    .icons-arrow-left
      width: 1.6rem
      height: 1.6rem
      margin-right: 0.8rem
      path
        fill: $ink
    span
      font-size: 1.2rem
      line-height: 1.6rem
      color: $ink-light

  .resume-header
    display: flex
    align-items: center
    justify-content: space-around
    width: 40rem
    height: 4rem
    background-color: $white
    margin: 0 auto
    border-radius: 0.4rem
    box-shadow: 0 0.4rem 1.6rem $sky-dark
    &:empty
      background-color: transparent
      box-shadow: unset
    > div
      padding: 0 1.2rem
      font-size: 1.4rem
      font-weight: $fw-regular
      line-height: 2rem
      &:first-child
        border-right: 0.1rem solid $ink-lightest
      &:nth-child(2)
        color: $ink-lighter
