@import 'src/sass/_variables'

.ui-table-toolbar
  position: relative
  display: flex
  align-items: center
  justify-content: flex-end
  .ui-search-input
    min-width: 28rem
    max-width: 28rem
    height: 4rem
    margin-right: 0.8rem
    &:only-child
      margin-right: 0
    div:only-child
      margin-right: 0
    .icons-close
      svg
        position: relative
        top: 0.2rem
    .icons-search
      path
        fill: $ink-lighter
    .ui-input
      input
        height: 4rem
      .tip
        min-height: 0
    &.initial-size
      min-width: 4rem
      max-width: 4rem
      &:hover
        cursor: pointer
      .ui-input
        input
          width: 4rem
          padding-right: 0
          padding-left: 0
          &:hover
            cursor: pointer

        input::placeholder
          /* stylelint-disable-next-line declaration-no-important */
          color: $white !important
    &.expanded
      .search-icon-wrapper
        position: relative
        right: -2.4rem
  // Filters and Export button
  .ui-button.white
    > div
      display: flex
      align-items: center
      font-weight: $fw-regular
    &:nth-of-type(2)
      display: flex
      align-items: center
      margin-right: 0.8rem
      &:last-child
        margin-right: 0
    &:nth-of-type(3)
      display: flex
      align-items: center
      justify-content: space-around
    &:hover
      background-color: $white
    &.white
      width: 4rem
      height: 4rem
      > div
        svg
          margin-right: 0
      &.disabled
        background-color: $white
        border-color: $sky
        svg
          path
            fill: $sky
  .download-example
    width: 8.8rem
    .icons-download
      width: 2.4rem
      height: 2.4rem
      margin-right: 0.4rem
      path
        fill: $rombit-blue-600
  .menu-csv
    margin-right: 0.8rem
    .bulk-option
      padding: 0
      .menu-row
        width: 100%
    .component
      display: flex
      align-items: center
      justify-content: center
      width: 4rem
      height: 4rem
      background-color: $white
      border: 0.1rem solid $sky-dark
      border-radius: 0.4rem
      svg
        path
          fill: $ink-lighter
      &:hover
        border-color: $ink-lightest
    &.show
      .component
        border-color: $ink-lightest
  .ui-input.upload
    display: flex
    align-items: center
    justify-content: center
    .input-container
      width: 100%
    label
      display: flex
      justify-content: flex-start
      width: 100%
      background-color: transparent
      color: $ink
      input
        margin-left: 0.4rem
      &:hover
        cursor: pointer
      span
        display: block
        white-space: nowrap
        font-size: 1.4rem
        font-weight: $fw-regular
    .file-value
      margin-top: 0

  .btn-export
    margin-right: 0.8rem
  .btn-filters
    position: relative

  //  Filters Qty
  .filters-qty
    position: absolute
    top: -0.8rem
    right: -0.8rem
    display: flex
    align-items: center
    justify-content: center
    width: 1.6rem
    height: 1.6rem
    background-color: $rombit-blue-600
    border-radius: 50%
    font-size: 1.1rem
    font-weight: $fw-bold
    text-align: center
    line-height: 1.4rem
    color: $white
