@import 'src/sass/_variables'

// Certificates table
.certificates-table
  height: 100%
  margin: 1rem 0 2.4rem
  .certificates-cards-container
    height: calc(100vh - 19rem)
    background-color: $white
    overflow: hidden auto
    padding: 3.6rem
    .certificates-cards
      display: grid
      grid-template-columns: repeat(4, 1fr)
      grid-auto-columns: 1fr // same as flex-shrink: 1
      grid-auto-rows: auto
      grid-gap: 1.6rem
      align-items: stretch
      padding: 0.4rem
      .ui-card
        flex-shrink: 0
        width: 100% // to reset values
        min-width: initial // to reset values
        height: initial
        cursor: pointer
        margin-right: 0
      .card-content
        display: flex
        > div:first-child
          margin-right: 0.4rem
  .table-header-content
    display: flex
    align-items: center
    justify-content: space-between
    > div:first-child
      font-size: 1.6rem
      font-weight: $fw-regular
      line-height: 2rem
      color: $ink-lighter
    > div:nth-child(2)
      min-width: 20rem

  .ui-table
    .group-name
      display: flex
      align-items: center
      width: 100%
