@import 'src/sass/_variables'

.rules
  .rules-create
    display: flex
    margin-bottom: 3.2rem
    .container
      display: flex
      flex: 3
      width: 100%
      > div
        display: flex
        align-items: center
        flex-direction: column
        width: 100%
        max-height: calc(100vh - 4.8rem)
        padding: 4.8rem
        overflow: hidden scroll
        .rule-header
          display: flex
          justify-content: flex-start
          width: 82rem
          margin-top: -2.4rem
          margin-bottom: 0.8rem
          .btn-view-all
            padding-left: 0.8rem
          +browser-only-max-zoom-width(768px)
            width: 40rem
          +browser-only-max-zoom-width(480px)
            width: 30rem
      .ui-accordion
        h2
          font-size: 1.6rem
          color: $ink-light
        svg
          path
            fill: $ink-lighter
      .wrapper
        background-color: $sky-light
        padding: 1.6rem
        border: 0.2rem solid $input-border-color
        border-radius: 0.4rem
        .recurrent-action
          display: flex
          justify-content: space-between
          font-size: 1.2rem
          .recurrent-action-title
            margin-bottom: 0.6rem
            font-size: 1.4rem
            font-weight: $fw-bold
            color: $ink-light
          .recurrent-action-description
            color: $ink-light
          .ui-switch
            margin: 1.6rem
        .recurrent-action-content
          margin-top: 1.6rem
          &.disabled
            label
              color: $ink-lightest
          .ui-row
            padding-left: 5.4rem
            label
              width: 12rem
              margin: 0 1rem
          .action-check
            padding-left: 18.4rem
            margin: 0
            label
              width: auto
              margin-left: 1rem
          .ui-input-combo
            margin: 0
            .row
              .ui-input
                flex-direction: row
                margin: 0
                input
                  max-width: 6rem
              > div:nth-child(2)
                .ui-select-single
                  top: 0
          .ui-input-number
            .ui-input
              margin: 0

    .select-rule-priority
      .priority-option,
      .priority-value
        display: flex
        > div
          display: flex
          align-items: center
          > div
            width: 2.4rem
            margin-right: 0.4rem
          > span
            font-size: 1.4rem
            line-height: 2.4rem
            color: $ink
    .details
      .ui-input-combo
        .ui-select-single
          top: 2rem
      .ui-row.checkbox
        .icons-information
          width: 1.6rem
          height: 1.6rem
      .select-label
        margin-bottom: 0.4rem
        label
          +common-label
    .details,
    .rules-triggers,
    .trigger-actions-fields
      [class^="select-rule-"]
        margin-bottom: 1.6rem
        > label
          +common-label
        + .tip
          display: block
          width: 100%
          min-height: 1.8rem
          margin-top: -1.6rem
          font-size: 1.2rem
          font-style: italic
          line-height: 1.8rem
          color: $ink-lightest

      .mandatory
        color: $red
    .button
      display: flex
      justify-content: center
      margin: 1.6rem 0 2rem
    .no-delay
      div
        transition-delay: 0s
    .trigger-actions-header
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 1.6rem
      .trigger-actions-filters
        display: flex
        align-items: center
        justify-content: flex-end
        .ui-select-single
          width: 22rem
          .category-select-label
            display: flex
            align-items: center
            line-height: 0
            > span:nth-child(2)
              margin-left: 0.8rem
              font-size: 1.4rem
              line-height: 1.6rem
              color: $input-font-color
            [class^="icons-"]
              width: 1.6rem
              height: 1.6rem
              path
                fill: $ink-lightest

        .ui-search-input
          width: 26rem
          margin-right: 0
          margin-left: 1.6rem
          .ui-input
            margin: 0
            input
              height: 3.8rem
    .trigger-actions-icons
      .icons-box-container
        [class^="icons-"]
          width: 2rem
          height: 2rem
    .trigger-actions-fields
      .description
        font-size: 1.6rem
        line-height: 2rem
        color: $ink
      .notification-options
        display: flex
        margin-top: 5.6rem
        margin-bottom: 1.6rem
        > div
          display: flex
          align-items: center
          flex-direction: column
          flex-basis: 50%
          &:only-child
            flex-basis: 100%
          > div
            font-size: 1.4rem
            line-height: 2rem
            color: $ink
        .graphs-toast
          .moving-parts
            animation-name: slideInOut
            animation-duration: 3s
            animation-fill-mode: forwards
            animation-iteration-count: infinite

        .graphs-modal
          .moving-parts
            animation-name: slideInOutVertical
            animation-duration: 3s
            animation-fill-mode: forwards
            animation-iteration-count: infinite
            transform-origin: center
      .notification-banner
        display: flex
        justify-content: flex-start
        align-self: flex-start
        padding: 1.6rem
        border: 0.1rem solid $sky
        border-radius: 0.8rem
        svg
          flex-shrink: 0
          margin-right: 0.8rem
          path
            fill: $ink-lightest
        p
          font-size: 1.4rem
          line-height: 2rem
          color: $ink
    .rules-suggest
      margin-top: 0
      font-size: 1.2rem
      line-height: 1.4rem
      color: $ink
      a
        border-bottom: unset
        font-weight: $fw-bold
        color: $rombit-blue-600

    .custom-actions
      margin-top: 1.6rem
      margin-bottom: 2.4rem
      font-size: 1.4rem
      color: $ink-light
      .custom-actions-action
        display: flex
        justify-content: center
        .custom-actions-expand
          display: flex
          align-items: center
          justify-content: center
          flex-direction: row
          cursor: pointer
          font-size: 1.4rem
          font-weight: $fw-bold
          color: $ink-light
          &.expanded
            > svg
              transform: rotate(180deg)
      .custom-actions-header
        margin-top: 2.4rem
      .custom-actions-container
        display: flex
        justify-content: space-between
        flex-flow: row wrap
        background: $sky
        padding: 2.4rem
        margin-top: 3.2rem
        gap: 1.6rem
        border-radius: 0.4rem
        > div
          width: 15.6rem
          padding: 1.8rem 1.2rem
          &.action
            display: flex
            align-items: center
            background: $white
            border-radius: 0.4rem
            > svg
              width: 1.6rem
              height: 1.6rem
              margin-right: 0.4rem
              > path
                fill: $ink-lighter
      .custom-actions-request
        display: flex
        justify-content: center
        margin-top: 2.4rem
        .ui-button > div
          display: flex
          align-items: center
          .icons-check
            margin-left: 0.4rem
            > path
              fill: $white

    .icons-box-container
      display: flex
      justify-content: flex-start
      flex-wrap: wrap
      margin-top: 3.2rem
      > div
        display: flex
        align-items: center
        flex-direction: column
        width: 15rem
        height: 15rem
        cursor: pointer
        margin-right: 4rem
        margin-bottom: 3.2rem
        border: 0.1rem solid $sky-dark
        border-radius: 0.4rem
        &:nth-child(4n)
          margin-right: 0
        &[disabled]
          background-color: $sky-lighter
          pointer-events: none
        &:hover,
        &.selected
          background-color: $rombit-blue-200
          border-color: $rombit-blue-600
          .text
            span
              color: $rombit-blue-600

        .ui-icon-box
          svg
            width: 2.4rem
            height: 2.4rem

        > div:first-child
          display: flex
          align-items: center
          justify-content: center
          width: 4.8rem
          height: 4.8rem
          margin-top: 3.2rem
        .text
          display: flex
          justify-content: center
          width: 100%
          span
            vertical-align: middle
            padding: 0rem 1.7rem
            margin-top: 1.3rem
            font-size: 1.4rem
            text-align: center
            color: $ink-light

@keyframes slideInOut
  0%
    transform: translateX(100%)
    visibility: visible
  35%
    transform: translateX(0)
  65%
    transform: translateX(0)
  100%
    transform: translateX(100%)
    visibility: visible

@keyframes slideInOutVertical
  0%
    transform: scale(0%)
    visibility: visible
  25%
    transform: scale(100%)
  65%
    transform: scale(100%)
  90%
    transform: scale(0%)
    visibility: visible
  100%
    transform: scale(0%)
    visibility: visible
