@import 'src/sass/_variables'

.update-password
  width: 100%
  .wrap
    position: relative
    z-index: 2
    h2
      padding: 1.6rem 0 2.6rem
      text-align: center
      color: $ink-light
    form
      width: 100%
      color: $ink-light
      .row
        flex-direction: column
        &:first-child
          margin-bottom: 1.6rem
      .password-form
        .ui-input.column:first-child
          margin-bottom: 1.6rem
      .button
        display: flex
        justify-content: center
        margin: 2.4rem 0 0
