@import 'src/sass/_variables'

.ui-checkbox
  .control
    position: relative
    display: block
    width: 1.6rem
    height: 1.6rem
    cursor: pointer
    margin-right: 0.8rem
  .control input
    position: absolute
    z-index: -1
    top: 0
    opacity: 0
    width: 1.6rem
    height: 1.6rem
  .control_indicator
    position: absolute
    top: 0
    left: 0
    width: 1.6rem
    height: 1.6rem
    background: $white
    border: 0.1rem solid $sky-dark
    border-radius: 0.3rem
  .control:hover input ~ .control_indicator
    background: $white
    border: 0.1rem solid $rombit-blue-600
  .control input:focus ~ .control_indicator
    background: $white
  .control input:checked ~ .control_indicator
    background: $white
    border: 0.1rem solid $rombit-blue-600
  .control:hover input:not([disabled]):checked ~ .control_indicator,
  .control input:checked:focus ~ .control_indicator
    background: $white
  .control input:disabled ~ .control_indicator
    opacity: 1
    background: $rombit-blue-200
    cursor: not-allowed
    pointer-events: none
  .control_indicator:after
    content: ""
    position: absolute
    box-sizing: unset
    display: none
  .control input:checked ~ .control_indicator:after
    display: block
  .control .control_indicator:after
    top: 0
    left: 0
    transform: translate(90%, 10%) rotate(45deg)
    width: 0.3rem
    height: 0.8rem
    border: solid $rombit-blue-600
    border-width: 0 0.2rem 0.2rem 0
  .control .control_indicator.intermediate:after
    top: 0.1rem
    left: 0.3rem
    border-width: 0.6rem 0 0 0rem
    transform: rotate(90deg)
    width: 0.2rem
    height: 0.6rem
  .control input:disabled ~ .control_indicator:after
    border-color: $ink-lighter
