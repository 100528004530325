@import src/sass/_variables

.settings-departments
  .container
    .heading
      display: flex
      align-items: center
      flex-direction: column
      align-self: flex-start
    p
      align-self: flex-start
      padding: 0
      font-size: 1.4rem
      text-align: left
      line-height: 2rem
    .process-bar-container
      width: 100%
      margin-top: 1.8rem
      .ui-progress-bar
        margin-bottom: 2.4rem
    .box
      .header
        display: flex
        justify-content: space-between
        margin-bottom: 2.4rem

  .ui-modal
    .modal
      overflow: visible
