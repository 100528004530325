@import 'src/sass/_variables'

.ui-notification-bar
  position: relative
  z-index: 4 // needs this value at least to hide also sidebar menu and map filters
  display: flex
  align-items: center
  justify-content: center
  transition: all 0.5s
  width: 100%
  height: 3rem
  margin-top: 0
  text-align: center
  color: $white
  &.error
    background-color: $red-darker
    .close,
    .icons-evacuation
      path
        fill: $white
    span
      cursor: pointer
  &.warning
    background-color: $orange
  &.evacuation-event
    background-color: $red-lighter
    color: $red
  &.info
    background-color: $rombit-blue-500
  &.pilot
    background-color: $purple
  > div
    display: flex
    align-items: center
    justify-content: center
    font-size: 1.4rem
    line-height: 2rem
    a
      margin-left: 0.4rem
      font-weight: $fw-bold
      text-decoration: underline
      color: $white
      &:hover
        cursor: pointer
    svg
      margin-right: 0.8rem
  .close
    position: absolute
    right: 1.6rem
    cursor: pointer
    line-height: 0
    svg
      width: 1.6rem
      height: 1.6rem
      path
        fill: $white
  &.block-screen
    .overlay-screen
      position: relative
      z-index: 6 // needs this value at least to hide also sidebar menu and map filters
      top: 0
      left: 0
      bottom: 0
      right: 0
      display: block
      opacity: 60%
      transition: all 1.5s
      height: 100vh
      background-color: $ink
      margin-top: 0.8rem
