@import 'src/sass/_variables'

.ui-switch
  display: flex
  align-items: center
  user-select: none
  input
    display: none

  > .switch
    position: relative
    display: flex
    flex-shrink: 0
    width: 5rem
    height: 2.4rem
    overflow: hidden
    cursor: pointer
    border-radius: 5rem
    &.disabled
      cursor: not-allowed
    .track
      display: flex
      flex-shrink: 0
      transition: margin 0.3s ease-in 0s
      width: 200%
      margin-left: -100%
      .on,
      .off
        box-sizing: border-box
        display: flex
        float: left
        width: 50%
        height: 2.4rem
        padding: 0
      .on
        background-color: $green
        padding-left: 0.6rem
      .off
        background-color: $ink-lightest
        padding-right: 0.6rem
        text-align: right
    .thumb
      position: absolute
      top: 0.1rem
      bottom: 0
      right: 2.6rem
      display: block
      transition: all 0.3s ease-in 0s
      width: 2.2rem
      height: 2.2rem
      background: $sky
      margin-right: 0.1rem
      border-radius: 5rem

  label
    display: block
    font-family: $source-sans-pro
    font-size: 1.4rem
    font-weight: $fw-regular
    line-height: 1.4rem
    color: $ink-light

  input:checked + .switch .track
    flex-shrink: 0
    margin-left: 0
  input:checked + .switch .thumb
    right: 0
    background: $white

  // Small size
  &.small
    display: flex
    > .switch
      width: 3rem
      height: 1.4rem
      .track .on,
      .track .off
        height: 1.4rem
      .thumb
        right: 1.6rem
        width: 1.2rem
        height: 1.2rem
    input:checked + .switch .thumb
      right: 0

  // Label position
  &.label-end
    .switch
      margin-right: 0.8rem
  &.label-start
    flex-direction: row-reverse
    .switch
      margin-left: 0.8rem
  &.no-label
    .switch
      margin-left: 0
