.routes-backoffice
  padding: 1rem 2rem 2rem
  .ui-side-panel
    top: 0
  .ui-list
    .list-columns
      top: -0.4rem
      display: grid
      .header
        display: flex
        align-items: center
        font-size: 1.4rem
        span
          width: 2.4rem
          height: 2.4rem
