$white:               #FFFFFF
$sky-lighter:         #F9FAFB
$sky-light:           #F4F6F8
$sky:                 #DFE3E8
$sky-dark:            #C4CDD5
$ink-lightest:        #919EAB
$ink-lighter:         #637381
$ink-light:           #454F5B
$ink:                 #212B36
$black:               #000000

$yellow-lighter:      #FCF1CD
$yellow:              #EEC200
$yellow-darker:       #8A6116

$orange-lighter:      #FCEBDB
$orange:              #F49342
$orange-darker:       #C05717

$red-lighter:         #FBEAE5
$red-light:           #FA9A88
$red:                 #DE3618
$red-darker:          #BF1D08

$lime-lighter:        #F0F5D9
$lime:                #B3C95A

$green-lighter:       #E3F1DF
$green-light:         #BBE5B3
$green:               #50B83C
$green-darker:        #108043

$teal-lighter:        #E0F5F5
$teal-light:          #E8EAEF
$teal:                #47C1BF
$teal-darker:         #00848E

$indigo-lighter:      #F4F5FA
$indigo-light:        #B3BCF5
$indigo:              #5C6AC4

$purple-lighter:      #F6F0FD
$purple:              #9C6ADE
$purple-dark:         #50248F
$magenta:             #CE5CBB

// needed for API
$blue:                #006FBB
$blue-dark:           #084E8A
$orange-text:         #594430
$fuchsia:             #DA6ADE

$r-black:             #1F1F1F
$r-orange:            #FA4616
$r-medium-grey:       #D9D9D9


//  ROMBIT
$rombit-blue-100:  #F0FAFF
$rombit-blue-200:  #DBF3FF
$rombit-blue-300:  #B3E3FF
$rombit-blue-500:  #57A8E6
$rombit-blue-600:  #308AD9
$rombit-blue-700:  #1E68B3
$rombit-blue-800:  #114A8C
$rombit-blue-900:  #041C40
