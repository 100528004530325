@import 'src/sass/_variables'

.create-zone,
.create-poi
  display: flex
  justify-content: flex-start
  flex-direction: column
  height: 100%
  .header
    display: flex
    justify-content: space-between
    .button
      &:hover
        cursor: pointer
      svg
        width: 1rem

  // error for name input
  > div:nth-child(2)
    .tip.error
      position: relative

  // error for code input
  > .row
    .ui-input.column
      div
        .tip.error
          white-space: nowrap

  .subtitle
    margin: 2.4rem 0 1.2rem
    font-size: 1.6rem
    font-weight: 700
    line-height: 2rem
    color: $ink-light
  .ui-input
    display: flex
    margin-bottom: 1.6rem
    > div
      input
        border-radius: 0.4rem

  .select-group,
  .certificates-row,
  .select-type
    margin-bottom: 1.6rem

  .certificates-row
    label
      display: inline
      height: 0
    .select-certificates
      label
        display: none
    .ui-select-multi
      .select-multi
        &__value-container
          &--is-multi
            > div:last-child
              height: 2.8rem
              padding-bottom: 0.4rem
              margin-top: -0.5rem

  .certificates-row,
  .select-type
    .ui-label-info-tooltip
      display: flex
      cursor: pointer
      > div
        +common-label

  .select-hidden
    display: flex
    margin: 2.4rem 0
    .ui-label-info-tooltip
      display: flex
      cursor: pointer
      padding-left: 0
      font-size: 1.4rem
      font-weight: $fw-regular
      text-align: left
      line-height: 2rem

  .buttons
    display: flex
    align-items: flex-end
    justify-content: space-between
    flex: 1
    flex-direction: row-reverse
    padding-bottom: 0.8rem
    > .ui-button.back
      padding-left: 0
    > .ui-button
      width: initial
      padding: 0 1.4rem

  .input-combo
    display: flex
    flex-direction: row

    .select-item-color
      margin-top: 3rem
      .ui-select-color
        .select
          margin-right: 0
          .color.sketch
            border: 0.1rem solid $sky-dark
        .circle-picker
          position: fixed
          left: 16rem
          margin-left: 0.8rem
        .sketch-picker
          position: fixed
          margin-left: 0.8rem

  .icons-question
    path
      fill: $sky-dark

  .ui-modal
    .modal
      .content
        .description
          font-size: 1.4rem
          font-weight: $fw-regular
