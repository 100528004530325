@import 'src/sass/_variables'

=case-detail-label
  margin-bottom: 0.8rem
  font-size: 1.2rem
  font-weight: $fw-regular
  line-height: 2rem
  color: $ink-light

.investigation-case-detail
  .detail-title
    display: flex
    padding: 1.6rem 2.4rem 0.8rem
  > section
    display: flex
    padding: 0.8rem 2.4rem
    &:empty
      padding: 0

  .second-row
    .detail-row
      > div
        > div
          > div
            display: flex
            flex-direction: column
      header
        p
          +case-detail-label
      .items-pills-with-id
        display: flex
        align-items: center
        flex-direction: row
        .item-with-id
          display: flex
          align-items: center
          flex-direction: row
          margin-right: 1.6rem
          .id-text
            height: 1.6rem
            margin-left: 0.8rem
            font-size: 1.2rem
            line-height: 1.6rem
            +ellipsis(16rem)
    .pills-block
      margin-right: 2.4rem
      &:last-child
        margin-right: 0
      &:nth-child(1)
        width: 30%
      &:nth-child(2)
        width: 30%
        .ui-pill
          max-width: 21.6rem
          [class^="icons"]
            flex-shrink: 0
      &:nth-child(3)
        width: 40%
    &.two-items
      > div:only-child
        width: 100%
      // > div

  .divider
    height: 0.1rem
    background-color: $sky-dark
    margin: 0 2.4rem

  .select-label
    +select-pill($bg: $ink-lighter, $input-w: 19.2rem, $menu-w: 20.8rem)
    label
      +case-detail-label

  .buttons
    button
      margin-right: 1.6rem
      &:last-child
        margin-right: 0

  .due-date
    margin-bottom: 2.4rem
    margin-left: 2.4rem
    .due-date-box
      margin-bottom: 0.4rem
      .box
        label
          font-size: 1.2rem

  .pills-block
    margin-right: 3.2rem
    &:last-child
      margin-right: 0
    .block-label
      +case-detail-label
    .block-content
      min-height: 2.4rem
    .select-label label
      +case-detail-label
      display: block
      margin-bottom: 0.8rem
