@import 'src/sass/_variables'

.tmv2-tag-list
  display: flex
  flex-wrap: wrap
  overflow: hidden
  max-height: 9.6rem
  margin: 0.4rem 0
  color: $ink-light

  &.no-selection
    display: none

  .tag
    display: flex
    align-items: center
    background-color: $sky
    padding: 0.4rem 0.8rem
    margin-top: 0.8rem
    margin-right: 0.4rem
    border-radius: 0.3rem
    line-height: 0

    .icon
      svg
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.4rem

    .close
      cursor: pointer
      margin-left: 0.4rem
      svg
        width: 1.6rem
        height: 1.6rem
        margin: 0
      path
        fill: $ink-lighter
      &:hover path
        fill: $ink-light
      &:active path
        fill: $ink
