@import 'src/sass/_variables'

.phone-action,
.email-action,
.multiple-receivers-action
  margin-bottom: 0.4rem

.webhook-action
  position: relative
  .template-message
    .ui-editor
      min-height: 18rem
      background-color: $sky-lighter
      border: none
      font-family: $source-sans-pro
      font-size: 1.6rem
      font-weight: $fw-regular
      line-height: 2rem
      color: $ink-lightest
  .custom-message
    textarea
      min-height: 10rem
      background-color: $white
      resize: none
      padding: 0.8rem
      border: 0.2rem solid $sky-dark
      border-radius: 0.8rem
      font-family: $source-sans-pro
      font-size: 1.4rem
      font-weight: $fw-regular
      &:focus
        border-color: $rombit-blue-600
  .link
    margin-top: 0
    a
      border-bottom: unset
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 1.6rem
      color: $rombit-blue-600
  .disclaimer
    margin-top: 0

.rules-triggers,
.trigger-actions-fields
  .action-description
    margin-bottom: 2rem
    font-size: 1.4rem
    line-height: 1.8rem
    color: $ink
  .ui-accordion
    position: inherit
    width: 100%
    > div:first-child
      background-color: transparent
      padding-left: 0
    > div:nth-child(2)
      height: 0
      background-color: transparent
      overflow: hidden
      &.partial-expanded
        height: auto
      &.full-expanded
        height: auto
        +browser-max-zoom-heigth(680px)
          height: auto

    .ui-input-combo .ui-input input.error
      border-right: 0.1rem solid $red

  .multiple-receivers-action
    .email-action,
    .ui-phone-input
      margin-bottom: 1.6rem
    .receiver-tag-title
      margin: 0.8rem 0
      font-size: 1.4rem
      line-height: 2rem
      color: $ink

    .receiver-tag-wrapper
      width: 69.6rem

.receiver-tag
  position: relative
  display: inline-block
  width: auto
  height: 2.4rem
  background-color: $sky
  padding: 0.4rem 2.4rem 0.4rem 0.8rem
  margin: 0 0.8rem 0.8rem 0
  border-radius: 0.4rem
  font-size: 1.4rem
  line-height: 1.6rem
  color: $ink-light
  .flag
    display: inline-block
    transform: translateY(0.1rem)
    margin-right: 0.8rem
  .remove
    position: absolute
    top: 0.4rem
    right: 0.4rem
    line-height: 0
    svg
      width: 1.6rem
      height: 1.6rem
      path
        fill: $ink-light
    &:hover
      svg
        path
          fill: $ink
  .react-tel-input
    font-size: 1.4rem
    line-height: 1.6rem
    color: $ink-lighter
