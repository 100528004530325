=dropdown-content
  display: none
  position: absolute
  z-index: 4
  min-width: 18.5rem
  background-color: $white
  border-radius: 0.8rem
  box-shadow: 0 0.1rem 0.4rem $ink-lightest
  font-size: 1.2rem
  cursor: default
  padding: 0.4rem 0
  &.bottom
    right: 0

  &.right
    bottom: -50%
    margin-left: 5rem

  &.show
    display: block
    margin-top: 0.8rem

  > div
    display: flex
    align-items: center
    height: 3.2rem
    padding: 0 1.6rem
    cursor: pointer
    &:hover
      background-color: $sky-light
    &:active
      background-color: $sky

    &:first-child
      border-top-left-radius: 0.3rem
      border-top-right-radius: 0.3rem
    &:last-child
      border-bottom-left-radius: 0.3rem
      border-bottom-right-radius: 0.3rem
    // Icon
    .menu-row
      display: flex
      align-items: center
      justify-content: flex-start
      max-width: 25rem
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-size: 1.4rem
      text-decoration: none
      color: $ink
      [class^="icons-"]
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem
        display: flex
        justify-content: center
        align-items: center
        &:empty
          display: none
      span
        user-select: none

// Initials for Company/Place menu
=initials
  display: flex
  align-items: center
  justify-content: center
  background-color: $sky
  width: 3.2rem
  height: 3.2rem
  border-radius: 0.2rem
  border: 0.1rem solid $white
  font-size: 1.2rem
  font-weight: 700
  line-height: 1.6rem
  text-transform: uppercase
  color: $ink

// Badge for Event Detail
=ui-priority-colors($bg-color, $t-color, $f-color)
  background-color: $bg-color
  color: $t-color
  [class^="icons-arrows-"]
    path:first-child
      fill: $f-color

=inputSize($size)
  width: $size
  height: $size
  img
    width: $size
    height: $size

=base-card
  background-color: $white
  border: 0.2rem solid $sky
  border-radius: 0.8rem

=ellipsis($max-width: 100%)
  max-width: $max-width
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

=scrollable-container
  height: calc(100vh - 5rem)
  padding: 3.2rem 4.8rem 0 0
  margin: 0 0 0 3.2rem
  overflow: hidden auto

// Find way to validate mixin params
=browser-max-zoom-heigth($max-heigth)
  @media screen and (max-height: $max-heigth)
    @content

=browser-min-zoom-heigth($min-heigth)
  @media screen and (min-height: $min-heigth)
    @content

=browser-only-max-zoom-width($max-width)
  @media screen and (max-width: $max-width)
    @content

=browser-max-zoom-width($max-width)
  @media screen and (max-width: $max-width)
    @content

=browser-min-zoom-width($min-width)
  @media screen and (min-width: $min-width)
    @content

=dashboard-card
  background-color: $white
  border: 0.1rem solid $sky-dark
  border-radius: 0.8rem
  transition: all ease-in 0.2s 0s

=event-card($color, $bg)
  .box-icon
    background-color: $bg
    [class^="icons"]
      path
        fill: $color
  span:last-child
    color: $color
  &:hover
    background-color: $bg
    border: 0.1rem solid $color

=common-label($color: $ink-light)
  margin-bottom: 0
  font-size: 1.4rem
  line-height: 3rem
  color: $color

=field-record-label
  display: block
  font-size: 1.4rem
  font-weight: $fw-semibold
  text-align: left
  line-height: 3.2rem
  color: $ink-light

=btn-add
  display: flex
  align-items: center
  height: 3.6rem
  cursor: pointer
  padding: 0 1.6rem
  border: 0.1rem solid $sky-dark
  border-radius: 0.4rem
  font-size: 1.2rem
  color: $rombit-blue-600
  .icons-plus
    width: 1.6rem
    height: 1.6rem
    margin-right: 0.8rem
    path
      fill: $rombit-blue-600
  &:hover
    border-color: $rombit-blue-600

=item-label
  display: flex
  align-items: center
  justify-content: space-between
  > div.wrapped-text
    flex-grow: 1
    flex-shrink: 0
    padding-right: 0.4rem
  > div:nth-child(2)
    display: flex
    align-items: center
    span:first-child
      margin-right: 0.8rem
      &:empty
        display: none
    span:nth-child(2)
      margin-left: 0.4rem
      font-weight: $fw-regular
      text-align: right
      color: $ink-lightest
      white-space: nowrap
=item-label-sizes($name, $id, $subtype: 7.6rem)
  > div.wrapped-text
    width: $name
  > div:nth-child(2)
    span:first-child
      width: $id
    span:nth-child(2)
      width: $subtype

=circle-filled-green
  path:first-child
    fill: $green
  path:nth-child(2)
    fill: $white

=circle-filled-red
  path:first-child
    fill: $red
  path:nth-child(2)
    fill: $white

=select-pill($bg: $ink, $input-w: 14rem, $menu-w: 16.8rem)
  .select-single__control
    width: $input-w
    height: 2.4rem
    min-height: 2.4rem
    background-color: $bg
    border: none
    border-radius: 1.6rem
    .select-single__value-container
      padding: 0 1.2rem
    .select-single__single-value
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 1.6rem
      color: $white
    .select-single__indicator
      height: 2rem
      min-height: 2rem
      padding: 0 0.8rem
      margin-top: 0.4rem
      svg
        width: 1.6rem
        height: 1.6rem
        fill: $white
    .select-single__placeholder
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 1.6rem
      color: $white
  .select-single__menu
    width: $menu-w
