@import 'src/sass/_variables'

.routes-devices-trackers
  .ui-side-panel
    .update
      .device
        .ui-row
          .ui-label
            span:first-child
              font-weight: $fw-bold
  // Devices table
  .devices-table
    .ui-table
      .device-load-header,
      .device-firmware-header
        display: flex
        justify-content: space-between
        line-height: 1.6rem
        > div
          display: flex
          line-height: 2.4rem
          > div
            margin: auto 0
          path
            fill: $sky-dark
        .device-load-subtitle
          font-size: 1rem
          color: $sky
        .icons-charging
          width: 1.6rem
          height: 1.6rem
          margin: auto 0.8rem auto 0
      .icons-close
        path
          fill: $red
      .device-profile
        display: flex
        align-items: center
        width: 100%
        [class^="icons-"]
          display: flex
          align-items: center
          justify-content: center
          // Remove once all icons are ok
          width: 1.6rem
          height: 1.6rem
          margin-right: 0.8rem
          border-radius: 50%
          line-height: 0

      // Column charge
      .device-charge
        display: flex
        align-items: center
        justify-content: space-between
        flex-wrap: nowrap
        svg
          path
            fill: $ink-lightest
        > div:first-child
          line-height: 0
        > span
          flex-shrink: 0
          width: 4.1rem
          height: 1.8rem
          white-space: nowrap
        .icons-charging
          width: 1.6rem
          height: 1.6rem
          margin: auto 0.8rem auto 0
        .not-charging
          width: 0
          &.other-charging
            width: 2.4rem
        // Charge colors
        .very-low
          color: $red-darker
        .low
          color: $orange-darker
        .mid
          color: $orange
        .high
          color: $green
        .charge-good,
        .complete
          color: $green-darker

      // Column firmware
      .device-firmware
        display: flex
        align-items: center
        justify-content: space-between
        flex-wrap: nowrap
        width: 6rem
        .icons-close
          path
            fill: $red
        .icons-check
          path
            fill: $green

      // Column position
      .device-position
        display: flex
        align-items: center
        justify-content: center

        .ui-button,
        .no-position-container
          display: flex
          align-items: center
          justify-content: center
          width: 3.6rem
          height: 3.6rem

          svg.icons-my-location
            width: 2rem
            height: 2rem
            margin: 0
            &.active
              path
                fill: $green

      // Column activity
      .device-activity
        display: flex
        align-items: center
        flex-flow: row nowrap
        .active,
        .inactive,
        .reporting
          flex-shrink: 0
          margin-right: 0.4rem
          line-height: 0
        .active
          .icons-dot
            path
              fill: $green
        .reporting
          .icons-dot
            path
              fill: $rombit-blue-700
        .inactive
          .icons-dot
            path
              fill: $ink-lightest
        span
          white-space: nowrap
    .btn-table-go-to
      span
        +ellipsis(11.2rem)
