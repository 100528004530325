@import 'src/sass/_variables'

.ui-panel
  display: flex
  align-items: center
  justify-content: space-between
  height: 3.6rem
  background-color: $white
  padding: 0.8rem 1.2rem
  margin-bottom: 0.8rem

  border-radius: 0.8rem
  &.border-default
    border: 0.1rem solid $sky-dark
  &.border-error
    border: 0.1rem solid $red
  .panel-texts
    display: flex
    align-items: center
    width: 100%
    > div
      flex: 1
      font-size: 1.4rem
      font-weight: $fw-semibold
      text-align: right
      line-height: 1.6rem
      color: $ink-lightest
      &.panel-title
        +ellipsis(15.2rem)
        text-align: left
        color: $ink
      &:nth-child(2)
        display: flex
        align-items: center
        justify-content: flex-end
        max-width: 100%
        > div
          margin-left: 1rem
        > div:first-child
          margin: 0
  .panel-options
    min-height: 1.6rem
    margin-left: 1.6rem
    [class^="icons-"]
      width: 1.6rem
      height: 1.6rem
    .icons-circle-filled-check
      path:first-child
        fill: $green
      path:nth-child(2)
        fill: $white
    .icons-circle-filled-cross
      path:first-child
        fill: $red
      path:nth-child(2)
        fill: $white
