@import 'src/sass/_variables'

.spotter-home-box
  +dashboard-card
  .box-content
    display: flex
    flex-direction: column
  .donut-chart
    position: relative
    margin-top: 0.8rem
    margin-bottom: 1.6rem
    .recharts-wrapper
      z-index: 1
      margin: 1.6rem auto
      .recharts-pie-sector
        stroke: $white
        stroke-width: 0.4rem
        &:hover
          path
            stroke-width: 0.1rem
    .chart-detail
      position: absolute
      top: 0
      left: 0
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      width: 100%
      height: 100%
      .amount
        font-size: 2.4rem
        font-weight: $fw-bold
        text-align: center
        line-height: 2.8rem
        color: $ink-lightest
        &.isOn
          color: $green
        &.isOff
          color: $ink-lightest
  footer
    display: flex
    align-items: center
    flex-flow: row nowrap
    width: 100%
    padding-inline: 0.8rem
    .tag-detail
      display: flex
      align-items: center
      margin-bottom: 0.8rem
      &:hover
        cursor: pointer
      > div
        margin-left: 0.4rem
        > p
          font-size: 1.4rem
          line-height: 1.6rem
          white-space: normal
          color: $ink
          &:last-child
            color: $ink-lighter
        > svg
            path
              stroke-width: 0.1rem
              stroke: $sky-dark

      &.spotter-on
        svg
          path
            stroke: $sky-dark
            fill: $green
        &.active
          p:first-child
            color: $green
          svg
            path
              stroke: $ink-lightest

      &.spotter-off
        svg
          path
            stroke: $sky-dark
            fill: $sky
        &.active
          p:first-child
            color: $ink-lightest
          svg
            path
              stroke: $ink-lightest

  .empty-state
    padding: 2.4rem 1.6rem
    div
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      height: 16.2rem
      background-color: $sky-lighter
      padding: 2.4rem 1.6rem
      border-radius: 0.8rem
    p
      font-size: 1.2rem
      font-weight: $fw-regular
      text-align: center
      line-height: 1.6rem
      color: $ink
      &:first-of-type
        margin-top: 1.6rem
        font-weight: $fw-bold
