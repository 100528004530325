@import src/sass/_variables

.modal-content
  display: flex
  flex-direction: column
  width: 100%
  margin-top: 3rem
  > div
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    width: 100%
    padding: 0.4rem 0
    label
      color: $ink-lighter
    a
      display: flex
      align-items: center
      height: 2.4rem
      margin-right: 0rem
    > div
      &:last-child
        width: 100%
    .ui-select-single
      width: 9.5rem
  .select-items
    .select
      display: flex
      align-items: flex-start
      justify-content: flex-start
      flex-direction: column
  .divider
    width: 100%
    height: 0.1rem
    background-color: $sky-dark
    padding: 0
    margin: 1.6rem 0
  .subjects
    align-items: flex-start
    flex-direction: column
    label
      margin-bottom: 0.4rem
      font-weight: 600
    .subject-input
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      border: 0.1rem solid $sky-dark
      border-radius: 0.6rem
      .ui-input
        input
          min-width: 30rem
          border: none
          border-top-left-radius: 0.4rem
          border-bottom-left-radius: 0.4rem
          color: $ink-light
          &:focus
            box-shadow: none
          &::placeholder
            color: $rombit-blue-600
    .subjects-list
      .subject
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0.6rem 1rem
        margin: 1.2rem 0
        border: 0.1rem solid $sky-dark
        border-radius: 0.4rem
        color: $ink-lighter
        a
          &:hover
            cursor: pointer
            .icons-close
              path
                fill: $ink
        .icons-close
          width: 1.6rem
          height: 1.6rem
