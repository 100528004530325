@import 'src/sass/_variables'

.routes-worker-licenses
  .manage-licenses-table
    margin: 0
    margin-top: 1rem
    .ui-table
      .contractor-name
        display: block
        font-size: 1.2rem
        line-height: 1.6rem
      .rdt_TableCell:nth-child(4)
        > div
          display: flex
          align-items: center
          width: auto
          .icons-close
            path
              fill: $red
      .license-state
        margin-left: 0.9rem
        font-size: 1.4rem
        line-height: 1.6rem
    .ui-toolbar
      display: flex
      justify-content: space-between
      .button-container
        display: flex
        align-items: center
      .ui-button
        > div
          font-size: 1.4rem
      [class^="icons-"]
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem
      > div
        .ui-button.secondary
          display: inline
          height: inherit
          padding: 0
          > div
            font-weight: $fw-regular
      .icons-circle-filled-information
        path:first-child
          fill: $rombit-blue-700
      >.ui-button:first-child
        padding-left: 0
      .helpers-container
        display: flex
        align-items: center
        >p
          padding: 0 1rem
          border-right: 0.1rem solid $ink-lightest
        >:nth-child(3)
          border-right: none
  .license-modal
    .license-types
      margin-top: 1.6rem
      margin-bottom: 0.8rem
    .new-exp-date
      margin-top: 0.8rem
      label
        margin-bottom: 0
    .description
      width: 100%
      margin-top: 1.6rem
      text-align: left

    .current-exp-date
      width: 100%
      text-align: left
    .custom-expire-date
      align-items: flex-start
    .license-box
      display: flex
      flex-direction: column
      padding: 1.6rem 2.4rem
      border: 0.1rem solid transparent
      border-radius: 0.4rem
      &:hover
        cursor: pointer
      &.active
        color: red
      .license-icon
        display: flex
        align-items: center
        justify-content: center
        margin-bottom: 0.8rem

      &.full
        background-color: $teal-lighter
        color: $teal-darker
        &.active,
        &:hover
          border: 0.1rem solid $teal-darker
        .license-icon
          [class^="icons"]
            path
              fill: $teal-darker
          .icons-worker-app
            path
              fill: $teal-darker
        .icons-plus
          flex-shrink: 0
          width: 1.6rem
          height: 1.6rem
          margin: 0 0.4rem

      &.virtual-ppe
        background-color: $orange-lighter
        color: $orange-darker
        &.active,
        &:hover
          border: 0.1rem solid $orange-darker
        .license-icon
          [class^="icons"]
            path
              fill: $orange-darker

      &.worker-app
        background-color: $rombit-blue-100
        color: $rombit-blue-600
        &.active,
        &:hover
          border: 0.1rem solid $rombit-blue-600
        .license-icon
          [class^="icons"]
            path
              fill: $rombit-blue-600

      &.disabled
        background-color: $sky
        color: $ink-lighter
        &.active,
        &:hover
          border: 0.1rem solid $ink-lighter
        .license-icon
          [class^="icons"]
            path
              fill: $rombit-blue-600
