@import src/sass/_variables

.footer-actions
  display: flex
  flex-direction: column
  margin-top: 2rem
  > p
    width: inherit
    padding: 0
    margin-bottom: 1.6rem
    font-size: 2rem
    font-weight: $fw-regular
    line-height: 2.4rem
    color: $ink-light
  .ui-button
    margin: auto
  .button-container
    margin-top: 3.2rem
    > a
      cursor: pointer
      font-size: 1.6rem
      font-weight: $fw-bold
      line-height: 2rem
      color: $rombit-blue-600
