@import 'src/sass/_variables'

.routes-worker-licenses
  .container
    margin: 2rem 4.4rem 4.8rem 3.2rem
    .ui-module-header
      margin-bottom: 2.4rem
      .ui-table-toolbar
        margin-left: auto
      .btn-manage-license
        margin-left: 0.8rem
