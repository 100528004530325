@import 'src/sass/_variables'

.ui-select-color
  cursor: pointer
  &.show
    .select
      padding: 0 0.4rem 0 0.3rem
      border: 0.2rem solid $rombit-blue-600
  .select
    box-sizing: border-box
    display: flex
    align-items: center
    justify-content: space-around
    width: 6.4rem
    height: 3.6rem
    background: $white
    cursor: pointer
    padding: 0 0.4rem
    margin: 0 0.8rem
    border: 0.1rem solid $sky-dark
    border-radius: 0.4rem
    .color
      width: 100%
      height: 100%
    .circle,
    .sketch
      width: 2rem
      height: 2rem
      margin: 0.2rem
      border-radius: 50%
  .popover
    .caret
      position: relative
      z-index: 99
      background: $white
      &:after,
      &:before
        content: " "
        position: absolute
        left: 50%
        bottom: 100%
        width: 0
        height: 0
        border: solid transparent
        pointer-events: none
      &:after
        margin-left: -0.8rem
        border-color: rgba(255, 255, 255, 0)
        border-bottom-color: $white
        border-width: 0.8rem
      &:before
        margin-left: -0.9rem
        border-width: 0.9rem
        border-color: rgba(153, 153, 153, 0)
        border-bottom-color: #D2D2D2
    .sketch-picker
      position: absolute
      z-index: 2 // so the picker is in front of everything including the cover
      width: 22rem
      margin-top: 0.4rem
      margin-left: -0.5rem
    .circle-picker
      position: absolute
      z-index: 999
      right: 9.5rem
      max-width: 17.8rem
      background: $white
      padding: 1rem
      padding-right: 0
      padding-bottom: 0
      margin-top: 0.4rem
      margin-right: 0
      border-radius: 0.4rem
      box-shadow: 0 0.1rem 0.4rem $ink-lightest
    .circle
      border-radius: 50%
  .cover
    position: fixed
    z-index: 1 // so the "click" outside of the picker closes it
    top: 0
    left: 0
    bottom: 0
    right: 0
  .icons-chevron-down
    display: flex
    align-items: center
