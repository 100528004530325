@import 'src/sass/_variables'

.routes-contractors
  > button:first-child
    position: absolute
    top: 12rem
    right: 5rem
    display: flex
    align-items: center
  .container
    .contractors-management-header
      display: flex
      justify-content: space-between
      .ui-module-header
        .btn-export
          margin-right: 0
        .btn-create-contractor
          margin-left: 0.8rem

  .header-container
    .back-row
      display: flex
      align-items: center
      justify-content: flex-start
      flex-direction: row
      width: fit-content
      cursor: pointer
      margin: 0 0 0.4rem
      .icons-arrow-left
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem
        path
          fill: $ink
      span
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-light
    div:last-child
      font-size: 2rem
      font-weight: $fw-bold
      line-height: 2.4rem
      color: $ink
