@import 'src/sass/_variables'

.docking-toolbar
  display: flex
  flex-direction: column
  margin: 0 auto 1.6rem

  .back-row
    display: flex
    align-items: center
    justify-content: flex-start
    flex-direction: row
    width: fit-content
    cursor: pointer
    margin: 0 0 0.4rem
    .icons-arrow-left
      width: 1.6rem
      height: 1.6rem
      margin-right: 0.8rem
      path
        fill: $ink
    span
      font-size: 1.4rem
      line-height: 2rem
      color: $ink-light

  .area-select
    display: flex
    align-items: center
    justify-content: flex-start
    min-width: 33rem
    margin-left: auto
    white-space: nowrap
    font-size: 1.4rem
    > label
      margin-right: 0.8rem

  .heading
    display: flex
    align-items: center
    justify-content: flex-start
    .ui-title
      width: fit-content
      h2
        +ellipsis(32rem)
    .status-wrapper
      min-width: 15.2rem
    .ui-status
      margin-left: 1.6rem

      &.theme-red
        background-color: $red-lighter
        span
          color: $red-darker
        svg
          path
            fill: $red-darker

      &.theme-green
        background-color: $green-lighter
        span
          color: $green-darker
        svg
          path
            fill: $green-darker

      &.theme-sky
        background-color: $sky
        span
          color: $ink-light
        svg.icons-close
          path
            fill: $ink-light

      span
        font-size: 1.2rem
        font-weight: $fw-bold
        line-height: 1.2rem

    .installed-switch
      margin-left: auto
