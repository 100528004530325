@import 'src/sass/_variables'

.rule-summary
  overflow: hidden scroll
  flex: 1
  height: calc(100vh - 5rem) // 5rem for the space between actions and button.
  background-color: $white
  padding: 2.4rem 5.4rem
  +browser-max-zoom-width(1366px)
    padding: 2.4rem 3.6rem 2.4rem
  .ui-title
    margin-bottom: 3.4rem

  .action .side
    path
      fill: $green
  .create-rule-button
    display: flex
    justify-content: center
    margin-top: 4rem
