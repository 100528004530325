@import 'src/sass/_variables'

.cases-accidents
  padding: 2.4rem

  .ui-row
    .case-select
      .select-single__control
        width: 100%
    &.severity
      position: relative
      > .case-select
        position: relative
        width: 45%
        padding-right: 2.4rem
        .select-single__control
          width: 100%
        .select-single__menu
          width: 100%

      > .accident-dates
        position: absolute
        width: 55%
        margin: 0 0 0.4rem 45% // should match .case-select width
        label
          display: flex
          align-items: center
          width: fit-content
          margin-bottom: 0.4rem
          font-size: 1.4rem
          font-weight: $fw-regular
          line-height: 2rem
          color: $ink-light
        .dates-and-lost
          display: flex
          .days-lost
            display: flex
            align-items: center
            align-self: center
            height: 2.4rem
            background-color: $green-lighter
            padding: 0rem 1.2rem
            margin-left: 0.8rem
            border-radius: 1.6rem
            font-size: 1.2rem
            line-height: 1.4rem
            color: $green
            &.has-days
              background-color: $orange-lighter
              color: $orange
          .ui-date-range-picker
            .DateRangePicker
              .DateRangePickerInput
                .DateInput
                  width: 10.4rem


  // TODO: Duplicated code (triplicated by now)
  .case-select
    &.progress
      .select-single__control
        background-color: $orange
    &.resolved
      .select-single__control
        background-color: $green
    &.open
      .select-single__control
        background-color: $rombit-blue-700
    label
      display: block
      margin-bottom: 0.4rem
      font-size: 1.4rem
      font-weight: $fw-regular
      line-height: 2rem
      color: $ink-light
      &:empty
        margin-bottom: 0

    .select-single__control
      width: 16rem
      height: 2.4rem
      min-height: 2.4rem
      background-color: $sky
      border: none
      border-radius: 1.6rem
      .select-single__value-container
        padding: 0 1.2rem
      .select-single__single-value
        font-size: 1.2rem
        font-weight: $fw-regular
        line-height: 1.6rem
      .select-single__indicator
        height: 2rem
        min-height: 2rem
        padding: 0 0.8rem
        margin-top: 0.4rem
        svg
          width: 1.6rem
          height: 1.6rem
      .select-single__placeholder
        font-size: 1.2rem
        font-weight: $fw-regular
        line-height: 1.6rem
    .status-value
      .color
        display: none
      .select-single__single-value
        margin-left: 0

  .custom-message
    label
      display: block
      margin-bottom: 0.4rem
      font-size: 1.4rem
      font-weight: $fw-regular
      line-height: 2rem
      color: $ink-light
    textarea
      min-height: 4rem
      background-color: $white
      resize: none
      padding: 0.8rem
      border: 0.2rem solid $sky-dark
      border-radius: 0.8rem
      font-family: $source-sans-pro
      font-size: 1.4rem
      font-weight: $fw-regular
      &:focus
        border-color: $rombit-blue-600
