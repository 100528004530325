@import 'src/sass/_variables'

.alerts-blank-state
  width: 100%
  height: calc(100% - 5rem)
  background-color: $sky
  padding: 3.2rem
  .blank-state
    width: 100%
    height: calc(100vh - 14.4rem)
    background-color: $white
    margin-top: 1rem
    margin-right: 0
    .message-content
      display: flex
      align-items: center
      flex-direction: column-reverse
      max-width: 65rem
      .title
        max-width: 65rem
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
    .icons-notification
      svg
        width: 4rem
        height: 4rem
        fill: $ink-light
