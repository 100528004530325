@import 'src/sass/_variables'

.ui-modal
  &.more-modal
    .modal
      overflow: visible
  .overlay
    position: fixed
    z-index: 6
    top: 0
    left: 0
    bottom: 0
    right: 0
    opacity: 0.35
    background-color: $ink
  .modal
    position: fixed
    z-index: 7
    top: 50%
    left: 50%
    display: flex
    flex-direction: column
    transform: translate(-50%, -50%)
    width: 48rem
    background: white
    padding: 2rem
    margin: 0 auto
    border: 0.1rem solid $sky
    border-radius: 0.4rem
    outline: none
    box-shadow: 0 0.2rem 5rem 0 rgba(33, 43, 54, 0.5)
    overflow: visible
    .head
      .close
        top: 2rem
        right: 2rem
        cursor: pointer
        margin-left: auto
        > .icons-close
          display: flex
          justify-content: flex-end
          width: 1.6rem
          height: 1.6rem
          margin-left: auto
    .content
      display: flex
      align-items: center
      flex-direction: column
      min-height: 7rem
      margin: 2rem 0
      font-size: 1.4rem
      text-align: center
      line-height: 2rem
      color: $ink-light
      .icon
        display: flex
        align-items: center
        justify-content: center
        align-self: center
        padding: 2rem
        margin-bottom: 2rem
        border-radius: 50%
        svg
          width: 4.8rem
          height: 4.8rem
        > div
          display: flex
        &:empty
          display: none

        &.alert
          background-color: $red-lighter
          svg
            path
              fill: $red
        &.countdown
          background-color: $red-lighter
        &.notification
          background-color: $rombit-blue-100
          svg
            path
              fill: $rombit-blue-600
        &.save
          background-color: $rombit-blue-100
          svg
            path
              fill: $rombit-blue-600
        &.success
          background-color: $green-lighter
          svg
            path
              fill: $green
          > .actions
            justify-content: center
          // optical compensation
          .icons-send
            position: relative
            left: 0.4rem

        &.ambulance,
        &.manual-case,
        &.reports
          background-color: $rombit-blue-100
          svg
            path
              fill: $rombit-blue-600
          > .actions
            justify-content: center
          // optical compensation
          .icons-send
            position: relative
            left: 0.4rem

        &.undefined
          height: 0
          padding: 0
          margin: 0
        &.send,
        &.schedule
          background-color: $rombit-blue-200
          svg
            path
              fill: $rombit-blue-600
          // optical compensation
          .icons-send
            position: relative
            left: 0.4rem
        &.evacuation,
        &.new-field-record
          background-color: $red
          svg
            path
              fill: $white
      .title
        margin-bottom: 1.2rem
        font-size: 1.6rem
        font-weight: $fw-bold
        &.evacuation,
        &.countdown,
        &.new-field-record
          margin-bottom: 1.6rem
          font-weight: $fw-semibold
          color: $red

    .actions
      display: flex
      align-items: center
      justify-content: flex-end
      &.new-field-record
        margin-top: 0.8rem
      > .ui-button:first-child
        margin-right: 1.6rem
      > .ui-button:only-child
        margin-right: 0
    .actions.success
      justify-content: center
