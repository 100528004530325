@import 'src/sass/_variables'

.ui-collapsable-container
  display: flex
  align-items: flex-start
  flex-direction: column
  width: 100%
  > div
    &:first-child
      margin-bottom: 1.6rem
      font-size: 1.4rem
      font-weight: 700
      line-height: 2.0rem
