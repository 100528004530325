@import 'src/sass/_variables'

.new-company
  display: flex
  justify-content: center
  flex-direction: column
  width: 100%
  margin-top: 4.5rem
  margin-bottom: 2rem
  .ui-row
    margin-bottom: 1.4rem
    &.language
      width: 100%
      margin-bottom: 2rem
    label
      margin: 0.8rem 0
      &:empty
        display: none
    .ui-input
      width: 100%
  .button
    display: flex
    justify-content: center
    margin-top: 0
    margin-bottom: 5rem
  h2
    margin-top: 2rem
    font-size: 1.5rem
