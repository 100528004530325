@import 'src/sass/_variables'

.ui-row
  display: flex
  flex-direction: column
  width: 100%
  margin-bottom: 2.4rem

  // keep and eye here
  > .ui-input.row
    display: flex
    flex-direction: column

  // Get rid of that one once you've gotten rid of the other one
  > div
    > label
      line-height: 3rem
      &:empty
        display: none

  label
    display: flex
    align-items: center
    margin-bottom: 0.8rem
    font-size: 1.4rem
    line-height: 2rem
    color: $ink
    &:empty
      display: none
    .mandatory
      color: $red

  &.row
    align-items: center
    flex-direction: row
  &.column
    flex-direction: column
  &.flex-start
    justify-content: flex-start
  &.flex-end
    align-items: flex-end
  &.space-between
    justify-content: space-between
  &.center
    justify-content: center

  .separator
    width: 100%
    height: 0.1rem
    background-color: $sky-dark

  .disclaimer
    background-color: $sky
    padding: 1.6rem
    margin-top: 1.8rem
    border-radius: 0.4rem
    p
      font-size: 1.2rem
      font-weight: $fw-regular
      color: $ink-light

  &.checkbox
    align-items: center
    flex-direction: row
    margin: 0 0 2.4rem
    .ui-checkbox
      height: 1.6rem
      label.control
        line-height: 1
    > label
      margin-bottom: 0
      > div
        line-height: 0

  &.timestamp
    display: flex
    align-items: center
    flex-direction: row
    margin-bottom: 1.6rem
    > .ui-checkbox
      .control
        margin-bottom: 0
    > label
        margin-bottom: 0
        margin-left: 0.8rem
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 1.6rem
