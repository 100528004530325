@import 'src/sass/_variables'

.ui-alert
  +base-card
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  padding: 0.8rem 1.6rem
  margin: 0.4rem auto
  > div
    display: flex
    align-items: center
    font-size: 1.4rem
    &:last-child
      &:hover
        cursor: pointer
        svg
          path
            fill: $ink
  [class^="icons-"]
    flex-shrink: 0
  &.info
    background-color: $rombit-blue-100
    border: none
    border-left: 0.8rem solid $rombit-blue-700
    &:hover
      .icons-circle-filled-information
        path
          fill: $rombit-blue-700
    .icons-circle-filled-information
      width: 2.4rem
      height: 2.4rem
      margin-right: 1.6rem
      > path
        fill: $rombit-blue-700
    > div
      p
        color: $ink-light
