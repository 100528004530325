@import 'src/sass/_variables'

.routes-profile
  height: 100%
  max-height: 100%
  overflow: hidden auto
  .container
    margin: 1rem 1.6rem
    .ui-tabs
      width: fit-content
  .update
    +base-card
    width: 33rem
    background-color: $white
    margin: 0.8rem auto 0
    border: 0.2rem solid $sky
    border-radius: 0.8rem
  .ui-top-notification
    .notification-text
      font-weight: 700
      line-height: 2rem
