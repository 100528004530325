@import 'src/sass/_variables'

.info-tooltip
  display: flex
  justify-content: center
  font-size: 1.4rem
  align-content: center
  > div
    position: absolute
    z-index: 4
    top: 6rem
    display: flex
    align-items: center
    justify-content: center
    width: auto
    height: 4rem
    background-color: $ink
    padding: 0 2.4rem
    border-radius: 0.4rem
    > span
      margin-left: 0.8rem
      color: $white
    .icons-circle-filled-information
      path
        fill: $white
