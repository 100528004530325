@import 'src/sass/_variables'

.backoffice-create-role
  margin: 2rem 4rem
  .ui-title
    padding: 0
    > h2
      font-size: 1.8rem
  .list-header
    margin: 1rem 0
    font-size: 1.6rem
    font-weight: $fw-bold
  .permissions-container
    max-height: 45vh
    padding: 1rem
    margin-bottom: 1.4rem
    border: 0.1rem solid $sky-dark
    border-radius: 0.4rem
    overflow: hidden scroll
    .permissions
      display: flex
      justify-content: space-between
      flex: 1 1 auto
      flex-direction: column
      width: 100%
      > li
        width: calc(100% - 2.4rem)
        padding: 1.6rem
        margin: 0.4rem
        border-radius: 0.8rem
        &:hover
          background-color: $sky-light
        > ul
          > li
            .list-item
              font-size: 1.4rem
    .list-item
      display: block
      margin-bottom: 0.8rem
      margin-left: 0.4rem
      font-size: 1.6rem
      font-weight: 700
    .sub-item
      display: flex
      margin-bottom: 0.4rem
      font-size: 1.4rem
    ul
      margin-left: 1rem
      > li
        display: flex
        flex-direction: column
        margin-left: 1rem
        order: 0
        label
          display: flex
          cursor: pointer
          .ui-checkbox
            padding-right: 0.6rem
    .sub-permit
      ul
        display: flex
      > li
        padding: 0.2rem
        margin: 0.4rem
    .col
      flex-direction: column
  .button
    margin-top: 2rem
