@import 'src/sass/_variables'

.user-details
  width: 35rem
  padding: 2rem 3rem
  .title
    margin-bottom: 3rem
    font-size: 1.8rem
  .ui-label
    margin-bottom: 1rem
    font-size: 1.6rem
    font-weight: $fw-bold
  ul
    margin-left: 3rem
