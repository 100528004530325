@import 'src/sass/_variables'

.map-view
  position: relative
  width: 100%
  height: 100vh

  .map-topbar
    position: relative
    z-index: 6
    display: flex
    align-items: center
    background-color: $white
    border-bottom: none
    // Default map view
    .header
      width: 100%
      height: $topbar-height
      &.time-machine
        .ui-filters-bar
          .select-region
            margin-left: 0.8rem
          .apply-button
            min-width: 7.2rem
            margin-left: 0.8rem
      .ui-tabs
        display: flex
        align-items: center
        justify-content: space-between
        height: $topbar-height
        border-bottom: none
        > div:first-child
          height: $topbar-height
          > div > span
            display: flex
            align-items: center
        > div:last-child
          margin-right: 2.4rem

    .edit-button
      min-width: 7.2rem
      margin-left: 0.8rem
    // Edit view
    .title-back-wrapper
      display: flex
      justify-content: space-between
      width: 100%
      height: $topbar-height
      margin-right: 2.4rem
      .topbar-back
        height: $topbar-height
        background-color: $white
        > div
          line-height: 0
        &:hover
          .icons-arrow-left
            path
              fill: $ink
    .ui-user-settings
      margin-right: 2.4rem
      margin-left: auto
    .menu-solutions
      margin-right: 0.8rem
