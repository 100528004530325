@import src/sass/_variables

.settings-alerts
  .container
    .ui-resume-bar
      justify-content: space-around
      width: 80%
      margin-top: 1.6rem
      .values-box
        min-width: 14rem
        .resume-section
          .quantity
            margin-left: 1.6rem
      .resume-separator
        &:last-child
          display: none
      .whatsapp-section
        > div
          > div
            background-color: $rombit-blue-100
        svg
          path
            fill: $rombit-blue-700
      .sms-section
        > div
          > div
            background-color: $orange-lighter
        svg
          path
            fill: $orange
      .email-section
        > div
          > div
            background-color: $teal-lighter
        svg
          path
            fill: $teal
      .phoneCall-section
        > div
          > div
            background-color: $purple-lighter
        svg
          path
            fill: $purple

      [class$="-section"]:last-child
        border-right: none
