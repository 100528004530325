@import 'src/sass/_variables'

.dashboard.with-bar
  .devices-filters,
  .infrastructure-filters,
  .spotter-filters,
  .docking-station-filters,
  .pairing-point-filters,
  .dot-filters

    &.ui-side-panel.right
      top: 0

      & > div:first-child
        height: 100%
        overflow: hidden

.devices-filters-wrapper
  .devices-filters-backdrop
    position: absolute
    z-index: 6 // Has to be over the Evacuation Button and side bar
    top: 0
    left: 0
    display: block
    width: 100vw
    height: 100vh
    background-color: transparentize($ink, 0.6)

  .devices-filters,
  .infrastructure-filters,
  .spotter-filters,
  .docking-station-filters,
  .pairing-point-filters,
  .dot-filters
    position: absolute
    z-index: 7 // Has to be over the Backdrop
    top: 0
    height: 100vh

    &.ui-side-panel
      &:before
        display: none

      & > div:first-child
        height: 100%
        overflow: hidden

        .close.right
          z-index: 2
          top: 2.2rem
          left: 2.4rem
          right: auto
          width: 2rem
          height: 2rem

          svg
            width: 2rem
            height: 2rem

        .filters
          height: 100%
          overflow: auto
          background-color: $white

          .header
            position: sticky
            z-index: 1
            top: 0
            display: flex
            align-items: center
            justify-content: space-between
            flex-direction: row
            height: 6.4rem // Match Topbar height
            background-color: $white
            padding: 1.6rem
            margin-bottom: 1.6rem
            border-bottom: 0.1rem solid $sky

            .title
              height: 3.2rem
              margin-left: calc(0.8rem + 2rem + 1.6rem)

          .switch-box
            border-top: none

          .tags
            display: flex
            align-items: flex-start
            justify-content: flex-start
            flex-direction: row
            flex-wrap: wrap

            .showing-clear
              display: flex
              align-items: center
              justify-content: flex-start
              width: 100%

              button
                background: none
                cursor: pointer
                margin-left: 0.8rem
                border: none
                font-weight: $fw-regular
                line-height: 1.2rem
                color: $rombit-blue-600

                &.ui-button
                  padding: 0
                  font-size: 1.2rem

                  &:disabled
                    opacity: 0.6
                    cursor: not-allowed
                    color: $rombit-blue-600
