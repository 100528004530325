@import 'src/sass/_variables'

.tags-chart
  +dashboard-card
  display: flex
  justify-content: space-between
  flex-direction: column

  .all-categories
    display: flex
    justify-content: space-between
    padding: 1.6rem 2.4rem
    .donut-chart
      position: relative
      height: 14.8rem
      .recharts-wrapper
        z-index: 1
        margin-top: 0.8rem
        .recharts-pie-sector
          stroke: $white
          stroke-width: 0.4rem
          &:hover
            path
              stroke-width: 0.1rem
      .chart-detail
        position: absolute
        top: 0.4rem
        left: 0
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        width: 100%
        height: 100%
        .icons-tag
          width: 3.2rem
          height: 3.2rem
        .inactive-text
          font-size: 1.2rem
          text-align: center
          line-height: 1.6rem
          color: $ink-light
        .amount
          font-size: 2.4rem
          font-weight: $fw-bold
          text-align: center
          line-height: 2.8rem
          &.charging
            color: $rombit-blue-600
          &.ready
            color: $green
          &.taken
            color: $purple
          &.inactive
            color: $ink-lightest
        .inactive
          display: flex
          align-items: center
          justify-content: center
          flex-direction: column
          width: 13.6rem
          height: 13.6rem
          border: 1.6rem solid $ink-lightest
          border-radius: 50%
          .icons-tag
            path
              fill: $ink-lightest

    .chart-info
      display: flex
      align-items: flex-start
      justify-content: center
      flex-direction: column
      .tag-detail
        display: flex
        align-items: center
        margin-bottom: 0.8rem
        > div
          margin-left: 0.4rem
          > p
            font-size: 1.4rem
            line-height: 1.6rem
            &:first-child
              line-height: 1.4rem
              white-space: normal
              color: $ink
            &:last-child
              color: $ink-lighter
          > svg
              path
                stroke-width: 0.1rem
                stroke: $sky-dark
        &:hover
          cursor: pointer
          p
            &:first-child
              text-decoration: underline
        &.charging
          &:hover,
          &.active
            p:first-child
              color: $rombit-blue-600
            > svg
                path
                  stroke: $rombit-blue-600
          > svg
              path
                stroke: $sky-dark
                fill: $rombit-blue-600

        &.ready
          &:hover,
          &.active
            p:first-child
              color: $green
            > svg
                path
                  stroke: $green
          > svg
              path
                fill: $green

        &.taken
          &:hover,
          &.active
            p:first-child
              color: $purple
            > svg
                path
                  stroke: $purple
          > svg
              path
                fill: $purple

        &.inactive
          &:hover,
          &.active
            p:first-child
              color: $ink-lightest
            > svg
                path
                  stroke: $ink-lightest
          > svg
              path
                fill: $sky-dark

  footer
    display: flex
    align-items: center
    justify-content: space-between
    border-top: 0.1rem solid $sky-light
    > div
      flex-grow: 1
      transition: background-color 200ms ease-in 0s
      padding: 1.2rem 0
      &:hover
        background-color: $sky-lighter
        cursor: pointer
      &.disabled
        color: $sky-dark
        svg
          path
            fill: $sky-dark
      &:first-child
        border-right: 0.1rem solid $sky-light
      > span
          display: block
          margin-bottom: 0.2rem
          font-size: 1.2rem
          font-weight: $fw-regular
          text-align: center
          line-height: 1.4rem
      > div
        display: flex
        align-items: center
        justify-content: center
        svg
          width: 1.6rem
          height: 1.6rem
          margin-right: 0.4rem
          path
            fill: $ink
        span
          font-size: 1.6rem
          font-weight: $fw-bold
          line-height: 2rem
