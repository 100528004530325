@import 'src/sass/_variables'

.ui-input-combo
  max-width: 100%
  margin: 0 0 1.6rem
  &.single-select-option
    .ui-select-single
      .select-single__indicators
        display: none
  label
    font-size: 1.4rem
    line-height: 2rem
  .row
    display: flex
    align-items: flex-start
    > .ui-input
      margin: 0
      input
        border-right: none
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    .tip
      position: absolute
      width: auto
      &.error
        white-space: nowrap
    .ui-select-single
      top: 3rem
      flex: 1
      min-width: 12rem
      > div:first-child
        border-top-left-radius: 0
        border-bottom-left-radius: 0
        // min-height: 3.6rem
  &:hover
    .ui-select-single
      > div
        border-color: $input-border-color-hover
