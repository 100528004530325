@import 'src/sass/_variables'

.ui-select-status
  width: 100%
  .status-value
    margin-left: 0.4rem
    div:nth-child(2)
      margin-left: 2rem
  .status-option,
  .status-value
    display: flex
    > div
      display: flex
      align-items: center
    .color
      width: 1rem
      height: 1rem
      border-radius: 100%
      &.progress
        background-color: $orange
      &.resolved
        background-color: $green
      &.open
        background-color: $rombit-blue-700
  .select-single__menu-list
    .status-option
      .color
        margin-right: 1rem
