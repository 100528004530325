@import 'src/sass/_variables'

.ui-docking-slot
  position: relative
  display: flex
  align-items: center
  justify-content: center
  width: 6.4rem
  height: 3.2rem
  background-color: $sky
  border: 0.2rem solid $ink-lightest
  border-radius: 0 0 0.8rem 0.8rem
  +browser-max-zoom-heigth(680px)
    width: 5.2rem
    height: 2.4rem
  .slot-position
    position: absolute
    left: -1.9rem
    bottom: 0
    font-size: 1rem
    font-weight: $fw-bold
    color: $ink-lightest
  &.empty
    >.ui-map-tooltip
      width: 13rem
  &.unknown
    background-color: $red-light
    border-color: $red
  &.charging,
  &.ready
    background-color: $rombit-blue-100
    border: 0.2rem solid $rombit-blue-700
  &:hover
    box-shadow: 0 0 0 0.4rem $rombit-blue-300
    .slot-position
      color: $rombit-blue-500
  &.ready
    background-color: $green-lighter
    border-color: $green
    .icons-check
      path
        fill: $green
    &:hover
      box-shadow: 0 0 0 0.4rem $green-light
      .slot-position
        color: $green-light
  .ui-map-tooltip
    position: absolute
    z-index: 1
    bottom: 4rem
    .content
      > p
        margin: 0.4rem 0
        color: $white
