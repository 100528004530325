@import 'src/sass/_variables'

.ui-tooltip
  position: absolute
  z-index: 12
  max-width: 22rem
  background-color: $ink
  padding: 0.8rem
  border-radius: 0.4rem
  font-size: 1.2rem
  font-weight: $fw-regular
  text-align: center
  line-height: 1.6rem
  color: $white
  &:empty
    display: none
  &.top
    transform: translate(0, -100%)
    margin-top: -1rem
    &.center
      transform: translate(-33%, -100%)
    &.center-bigger
      transform: translate(60%, -100%)
  &.bottom
    transform: translate(0, 100%)
    margin-top: 1rem
    &.center
      transform: translate(-33%, 100%)
    &.center-bigger
      transform: translate(60%, 100%)
  &.right
    transform: translate(4rem, 0)
  &.left
    transform: translate(-18rem, 0)
  &.singleline
    white-space: nowrap

  // CUSTOM
  &.evacuation-tooltip
    transform: translate(-19.2rem, 0)
    width: 18.4rem
    margin-top: 0.4rem

  &.panel-export
    transform: translate(-33%, 50%)
    width: 8rem
    margin-top: 1.6rem

  &.docking-home-tooltip
    margin-top: 0
    margin-left: 2.4rem

  &.installed-tooltip
    width: 24rem
    margin-top: 0rem
    margin-left: -4.8rem

  &.rule-frecuency-tooltip
    width: 24rem
    max-width: 24rem
    margin-top: -0.8rem
    margin-left: -2.4rem
