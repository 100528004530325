@import variables

html,
body
  height: 100vh
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important
  font-family: $source-sans-pro
  font-size: 62.5%
  overflow: hidden

*
  box-sizing: border-box
  padding: 0
  margin: 0

#root
  *
    &:focus
      outline: none
  &.with-outline
    *
      &:focus
        z-index: 1
        outline: $rombit-blue-600 auto 0.4rem

.loading
  overflow: hidden
  height: 100%
  &:before,
  &:after
    content: ""
    position: absolute
    z-index: 9999
    top: 0
    left: 0
    display: block
    visibility: visible
    width: 100%
    height: 100%
  &:before
    background: rgba(255, 255, 255, 0.8) no-repeat 50% 50%
  &:after
    background: url("../images/loading-rombit.svg") no-repeat 50% 50%
    animation: loadingAnimation 3s 0ms ease-in-out forwards infinite

@keyframes loadingAnimation
  0%
    transform: scale(1)
  25%
    transform: scale(0.5)
  50%
    transform: scale(1)
  75%
    transform: scale(0.5)
  100%
    transform: scale(1)

.bold
  font-weight: $fw-bold
.orange
  color: $orange

.word-break
  word-break: break-word
  max-width: 95%
  white-space: pre-line

.text-capitalized
  text-transform: capitalize

.wrapped-text
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

*::-webkit-scrollbar
  width: 0.6rem
  height: 0.6rem
  margin-left: 1rem

*::-webkit-scrollbar-track
  background: $sky
  border-radius: 0.2rem

*::-webkit-scrollbar-thumb
  background: $ink-lightest
  border-radius: 0.2rem

*::-webkit-scrollbar-thumb:hover
  background: $ink-lightest

// For FF placeholders
input::placeholder
  opacity: 1
  /* stylelint-disable-next-line declaration-no-important */
  color: $input-placeholder-color !important


// Z-INDEX TRACKING
// ================
//
// ui-side-panel:               3
// user-menu:                   4
// select live map:             5
// ui-sidebar:                  5
// ui-statistics-map-controls: 10
// ui-tooltip:                 12
// ui-modal overlay:            6
// ui-modal modal:              7
