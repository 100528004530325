@import 'src/sass/_variables'

.cards
  .ui-row
    margin-bottom: 0
  .content
    .card-id-input
      position: relative
      margin-bottom: 0
      .ui-input
        height: 5.2rem
        span
          position: absolute
          top: 3.8rem
          left: 0.4rem
          display: flex
          margin: 0
          font-size: 1.2rem
          text-align: left
          line-height: 1.2rem
      .btn-delete
        position: absolute
        z-index: 2
        top: 1rem
        right: 0.8rem
        line-height: 0
        .icons-trash
          width: 1.6rem
          height: 1.6rem
        &:hover
          cursor: pointer
          svg
            path:first-child
              fill: $red
        .icons-close
          width: 1.6rem
          height: 1.6rem
