@import 'src/sass/_variables'

.routes-settings
  .ui-toast.warning
    .toast-text
      p
        font-weight: $fw-bold
  .container-members
    flex-grow: 1
    width: calc(100% - 30.4rem)
    margin: 2rem 4.4rem 4.8rem 3.2rem
    .ui-module-header
      .ui-table-toolbar
        margin-left: auto
      .btn-add-new-user
        margin-left: 0.8rem
    .settings-filters
      .ui-label-info-tooltip
        margin-bottom: 0.8rem
    .settings-table
      .ui-table
        .user
          display: inline-flex
          width: 100%
          max-width: 100%
          img
            display: inline-block
            flex-shrink: 0
            object-fit: cover
            width: 3.4rem
            height: 3.4rem
            margin: 0 1.6rem 0 0
            border: 0.1rem solid $rombit-blue-500
            border-radius: 50%
          .data
            display: inline-grid
            margin-top: 0.3rem
            .name
              color: $ink
            .email
              max-width: 21rem
              font-size: 1.2rem
              line-height: 1.6rem
              color: $ink-light
            .name,
            .email
              width: 100%
