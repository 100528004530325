@import src/sass/_variables

.container-actions
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  width: 100%
  margin-top: 3rem
  > div
    display: flex
    align-items: center
    justify-content: right
    width: 100%
    padding: 0.4rem 0
    span
      width: 20rem
      padding-left: 0.8rem
      text-align: left
    > div
      &:last-child
        width: 8rem
    .ui-select-single
      width: 9.5rem
