@import 'src/sass/_variables'

.routes-devices-docking
  // Devices table
  .docking-devices-table
    .ui-table
      .rdt_TableCell:nth-child(6)
        > div
          display: flex
          align-items: center
          width: auto

          .ui-status
            padding: 0.4rem 1rem
            svg
              width: 1.6rem
              height: 1.6rem
            span
              white-space: nowrap
              font-size: 1.2rem
              font-weight: $fw-bold
              line-height: 1.2rem

            &.theme-red
              background-color: $red-lighter
              span
                color: $red-darker
              svg
                path
                  fill: $red-darker

            &.theme-green
              background-color: $green-lighter
              span
                color: $green-darker
              svg
                path
                  fill: $green-darker

            &.theme-sky
              background-color: $sky
              span
                color: $ink-light
              svg.icons-close
                path
                  fill: $ink-light

      .docking-state
        margin-left: 0.8rem
        font-size: 1.4rem
        line-height: 1.6rem

      // Column charge
      .docking-button
        position: relative
        right: 1.6rem
      .ui-label-tooltip
        .ellipsis
          .wrapped-text
            +ellipsis(16rem)
