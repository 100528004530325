@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@600&family=Source+Sans+3:wght@200..900&display=swap')

// FONT FAMILY
$base-font-family: HelveticaNeue, Helvetica, sans-serif
$source-sans-pro: "Source Sans 3", sans-serif
$outfit: "Outfit", sans-serif

// FONT WEIGHT
$fw-regular: 400
$fw-medium: 500
$fw-semibold: 600
$fw-bold: 700
