@import 'src/sass/_variables'

.ui-date-range-picker
  .time-picker-wrapper
    position: relative
    .inner-time-picker
      display: flex
      align-items: center
      justify-content: flex-start
      padding: 0.8rem 1.2rem
      border-top: 0.1rem solid $sky-dark
      &:nth-child(2)
        border-top: none
      .icons-clock
        flex-shrink: 0
        width: 2.4rem
        height: 2.4rem
        margin-right: 0.8rem
        path
          fill: $ink
      > p
        width: 7.2rem
        margin-right: 0.8rem
      > p,
      > span
        font-size: 1.4rem
        font-weight: $fw-bold
        line-height: 2rem
        color: $ink
        white-space: nowrap
      .ui-input-number
        width: 3.4rem
        margin-right: 0.8rem
        margin-left: 0.8rem
        input
          width: 3.4rem
          padding-right: 0.8rem
          padding-left: 0.8rem
          border-right: 0.1rem solid $input-border-color
          border-left: 0.1rem solid $input-border-color
          border-radius: 0.4rem
    .ui-button
      position: absolute
      top: 3.8rem
      right: 1.2rem
    &.flat
      display: flex
      justify-content: flex-end
      margin-right: 2.4rem
      .ui-button
        position: initial
.custom-label
  padding-bottom: 0.8rem
  margin-left: 1.6rem
  font-size: 1.2rem
  font-weight: $fw-regular
  color: $ink-light
