@import 'src/sass/_variables'

.item-following-box
  position: absolute
  left: 50%
  display: flex
  align-items: center
  justify-content: space-between
  transform: translateX(-50%)
  min-width: 33rem
  height: 5rem
  background-color: $white
  padding: 1.6rem 2.4rem
  border-radius: 5rem
  box-shadow: 0 0.4rem 1.6rem $sky-dark
  font-size: 1.4rem
  &.none
    bottom: 3.2rem
  &.partial-expanded
    bottom: 24.2rem
  &.full-expanded
    bottom: 53.2rem

  div
    display: flex
    align-items: center
  img
    width: 2rem
    height: 2rem
  [class^="icons-"]
    transform: scale(0.7)
  span
    margin: 0 0.5rem
  button
    background: transparent
    margin-left: 1rem
    border: none
    font-weight: $fw-bold
    color: $rombit-blue-600
    &:hover
      cursor: pointer
