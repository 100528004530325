.bounceIn
  animation-name: bounceIn
  animation-duration: 0.75s
  animation-fill-mode: both

@keyframes bounceIn
  0%,
  20%,
  40%,
  60%,
  80%,
  100%
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

  0%
    opacity: 0
    transform: scale3d(0.3, 0.3, 0.3)

  20%
    transform: scale3d(1.1, 1.1, 1.1)

  40%
    transform: scale3d(0.9, 0.9, 0.9)

  60%
    opacity: 1
    transform: scale3d(1.03, 1.03, 1.03)

  80%
    transform: scale3d(0.97, 0.97, 0.97)

  100%
    opacity: 1
    transform: scale3d(1, 1, 1)
