@import 'src/sass/_variables'

.ui-status
  display: flex
  align-items: center
  justify-content: center
  width: fit-content
  padding: 0.4rem 1.2rem
  border-radius: 2rem

  &.theme-blue
    background: $rombit-blue-700

  &.theme-orange
    background: $orange

  &.theme-yellow
    background: $yellow

  &.theme-red
    background-color: $red
    [class^="icons-"]
      path
        fill: $white

  &.theme-green
    background-color: $green
    [class^="icons-"]
      > path
        fill: $white

  &.theme-ink
    background-color: $ink-lightest
    span
      color: $white
    [class^="icons-"]
      > path
        fill: $white

  &.theme-sky
    background-color: $sky
    span
      color: $ink-light
    [class^="icons-"]
      > path
        fill: $ink-light

  &.theme-green-light
    background-color: $green-light
    > span
      color: $green-darker

  &.theme-orange-light
    background-color: #FFD9AA
    > span
      color: #D86900

  [class^="icons-"]
    flex-shrink: 0
    width: 1.6rem
    height: 1.6rem
    margin-right: 0.4rem

  span
    display: block
    font-size: 1.2rem
    text-transform: lowercase
    line-height: 1.6rem
    color: $white
    &:first-letter
      text-transform: capitalize
  &.single-line
    span
      white-space: nowrap
