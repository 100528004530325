@import 'src/sass/_variables'

.routes-backoffice-settings
  margin-top: 1rem
  .wrapper
    flex: 1
  .buttons
    display: flex
    justify-content: right
  .ui-list
    max-height: calc(100vh - 15rem)
    margin-top: 1rem
    margin-right: -4rem
    .field
      &.name
        &:hover
          text-decoration: none
      cursor: default
  .content-page
    display: flex
    height: calc(100vh - 14rem)
  .container
    margin: 1rem 1.6rem
    .settings-header
      display: flex
      justify-content: space-between
  .ellipsis.long-key
    max-width: 24rem
  .callback-url
    align-items: center
    .ui-input
      flex: 1
  .copy-clipboard
    width: 6rem
    cursor: pointer
    padding-right: 0
    margin-top: 3.2rem
    font-size: 1rem
    color: $ink-lighter
