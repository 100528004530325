@import 'src/sass/_variables'

.filter-checkbox-tile
  width: 100%
  padding: 0.2rem 0
  font-size: 1.2rem
  line-height: 1.6rem
  color: $ink-light

  &.parent
    // Apply to direct child tile so not to apply to non-parent children
    & > .tile
      .title
        font-weight: $fw-bold

  .tile
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding: 0.2rem 0

    .leading
      display: flex
      align-items: center
      justify-content: flex-start
      width: 100%
      padding: 0.2rem 0

      .chevron
        width: 1.6rem
        height: 1.6rem

        svg
          width: 1.6rem
          height: 1.6rem

      .title
        display: flex
        align-items: center
        justify-content: flex-start
        cursor: pointer
        margin-right: 0.8rem

        .icon
          width: 1.6rem
          height: 1.6rem
          margin-right: 0.4rem

          svg
            width: 1.6rem
            height: 1.6rem

        .color-box
          width: 1.2rem
          height: 1.2rem
          margin-right: 0.4rem
          border-radius: 0.2rem

      .count-indicator
        display: flex
        align-items: center
        background: $sky
        padding: 0 0.8rem
        border-radius: 1.2rem
        font-size: 1.2rem
        color: $ink-lightest

    .trailing
      display: flex
      align-items: center
      justify-content: center

  .children
    display: none
    flex-direction: column
    padding-left: 2.4rem

  &.open
    // Apply to direct child tile so not to apply to non-parent children
    & > .children
      display: flex
